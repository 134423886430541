
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Row, Col, Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react"
import { getTasks } from "../../redux/tasks/action"
import Loader from "../loader/loader";
import InfiniteScroll from "react-infinite-scroll-component";
import Promotion from "../promotion/promotion";
import Placeholder from "../../assets/images/placeholder1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
// import { io } from 'socket.io-client';
import { getEnabledPromotion } from "../../redux/promotions/action";
import Popup from "../popup/popup"

let base = process.env.REACT_APP_SERVER_URL + '/media/'
export var socket = null;

function LevelsAchievements(props) {
    const levels = useSelector(state => state?.tasks?.task?.task)
    const total = useSelector(state => state?.tasks?.task?.total)
    let promotions = useSelector(state => state?.promotions?.promotion);
    const loaderA = useSelector(state => state.tasks?.loaderActive)
    const [numberOfItems, setNumberOfItems] = useState(10);
    const dispatch = useDispatch()
    const [toggle, settoggle] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [slicee, setSlice] = useState(0);
    const [show, setShow] = useState(false);

    useEffect(() => {
        // socket = io("http://localhost:5000", { transports: ['websocket'] });
        // // socketObj = socket;
        // socket.on("connect", () => {
        //     console.log(socket.id);
        // });
        dispatch(getEnabledPromotion("acheivements"));
        const query = { page: 0, per_page: total }
        dispatch(getTasks(query))
    }, [total])

    useEffect(() => {
        const query = { page: 0, per_page: 4 }
        dispatch(getTasks(query))
    }, [])

    function loadMoreItems() {
        setNumberOfItems(numberOfItems + numberOfItems)
    }
    const slice = levels?.slice(0, numberOfItems);
    // function handleSlice(){
    //     setSlice(slice)
    // }
    // useEffect(() => {
    //     if(slice?.length == undefined)
    //     handleSlice(slice)
    // },[levels])
    return (
        <>
            <Popup data={promotions} />
            <div className="page-title">
                <h2>Levels & Achievements</h2>
            </div>

            <div className="banner-title">
                <h1><span>BADGES &</span> Avatars</h1>
                <p>How To Achieve Badges & Avatars On Different Levels</p>
            </div>
            <div className="buyers-rewards">
                <h3>BUYERS REWARDS</h3>
                <div className='mt-2'>
                    {loaderA ? <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            {/* <span class="sr-only">Loading...</span> */}
                        </div>
                    </div> : null}
                </div>

                {/* {loaderA ? <Loader /> : null} */}
                {/* <div
                    id="scrollableDiv"
                    style={{
                        height: 300,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                ></div> */}
                <InfiniteScroll
                    dataLength={slice?.length == undefined ? 10 : slice?.length}
                    next={loadMoreItems}

                    hasMore={true}

                    endMessage={
                        // <p style={{ textAlign: 'center' }}>
                        <b></b>
                        // </p>
                    }
                // loader={<h4>Loading...</h4>}
                >
                    {slice?.length > 0 ? slice.map((level, key) => {
                        return (<>
                            <div className='item'>

                                <Row >
                                    <Col xl={3} md={4}>
                                        <div className="avatar-badge">
                                            {level?.avatar[0]?.avatar[0]?.image !== undefined ?
                                                <div className="avatar">
                                                    <div className="image">
                                                        <img src={base + level?.avatar[0]?.avatar[0]?.image} alt="Avatar" className="img-fluid figure-img " />
                                                    </div>
                                                    <span>Avatar</span>
                                                </div> :
                                                <div className="avatar">
                                                    <div className="image">
                                                        <img src={Placeholder} alt="Avatar" className="img-fluid figure-img " />
                                                    </div>
                                                    <span>Avatar</span>
                                                </div>
                                            }
                                            {level?.badge[0]?.picture !== undefined ? <div className="badge p-0">
                                                <div className="image">
                                                    <img src={base + level?.badge[0]?.picture} alt="Badge" className="img-fluid figure-img" />
                                                </div>
                                                <span>Badge</span>
                                            </div> :
                                                <div className="avatar">
                                                    <div className="image">
                                                        <img src={Placeholder} alt="Avatar" className="img-fluid figure-img " />
                                                    </div>
                                                    <span>Badge</span>
                                                </div>
                                            }
                                        </div>

                                        {/* {level?.avatar[0]?.avatar[0]?.image !== undefined ? <div className='level-of-achievements d-flex'>
                                            <div><img src={base + level?.avatar[0]?.avatar[0]?.image} alt="Avatar" className="img-fluid figure-img " /></div>
                                            <span>Avatar</span>
                                        </div> : ""} 
                                        {level?.badge[0]?.picture !== undefined ? <div className='level-of-achievements d-flex '>
                                            <div> <img src={base + level?.badge[0]?.picture} alt="Badge" className="img-fluid figure-img" /></div>
                                            <span>Badge</span>
                                        </div> : ""}*/}
                                    </Col>

                                    <Col xl={9} md={8}>
                                        <div className='overview'>
                                            <h3 className={`more mb-3 d-flex align-items-center ${show && toggle === key ? "active" : ""}`} onClick={() => { settoggle(key); setShow(!show) }}>
                                                <span className='me-3'>Overview</span>
                                                {/* <span className='ms-2 icon'>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span> */}
                                            </h3>
                                            <p>{level?.name ? level?.name : ""}</p>
                                            <div className='you-will-get'>
                                                <h5 className='mb-2'>You 'll get to</h5>
                                                <div className="others" dangerouslySetInnerHTML={{ __html: level?.description }}></div>
                                            </div>
                                            <div className='more-items'>
                                                {/* {toggle === key ? */}
                                                {show && toggle === key && level?.avatar[0]?.musicAvatar[0]?.image !== undefined ? <div className={`others ${toggle ? 'show' : 'hide'}`}>
                                                    <p className=''>Niche Avatars:</p>
                                                    <ul>
                                                        <li>
                                                            <div className="image">
                                                                <img src={base + level.avatar[0]?.musicAvatar[0]?.image} alt="" className="img-fluid" />
                                                            </div>
                                                            <span>Music Collector</span>
                                                        </li>
                                                        <li>
                                                            <div className="image">
                                                                <img src={base + level.avatar[0]?.ebookAvatar[0]?.image} alt="" className="img-fluid" />
                                                            </div>
                                                            <span>EBook Collector</span>
                                                        </li>
                                                        <li>
                                                            <div className="image">
                                                                <img src={base + level?.avatar[0]?.artAvatar[0]?.image} alt="" className="img-fluid" />
                                                            </div>
                                                            <span>Art Collector</span>
                                                        </li>
                                                        <li>
                                                            <div className="image">
                                                                <img src={base + level?.avatar[0]?.artAvatar[0]?.image} alt="" className="img-fluid" />
                                                            </div>
                                                            <span>Art Collector</span>
                                                        </li>
                                                    </ul>
                                                </div> : ""}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </>)
                    }) : ""}
                </InfiniteScroll>
                {/* accordion start */}
                {/* {slice?.length > 0 ? slice.map((level, key) => {
                    return (<>
                <Accordion className='level-achievement-accordion '>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className=''>
                        <div className="achievement-content">
                                <div className="avatar-imagess text-center ">
                                <figure class="figure">
                                <img src={base + level?.avatar[0]?.avatar[0]?.image} alt="Avatar" className="img-fluid figure-img " />
                                <figcaption class="figure-caption text-light">Avatar</figcaption>
                                </figure>
                                <figure class="figure">
                                <img src={base + level?.badge[0]?.picture} alt="Badge" className="img-fluid figure-img" />
                                <figcaption class="figure-caption text-light">Badge</figcaption>
                                </figure>     
                                </div>
                                <div className='overview ms-5'>
                                    <h3 className="more" onClick={() => settoggle(key)}>Overview</h3>
                                    <div className="others" dangerouslySetInnerHTML={{ __html: level?.description }}></div>
                                </div>
                            </div>
    
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='overview'>
                                 
                                <div className={`others ${toggle ? 'show' : 'hide'}`}>
                                                <p className='text-center'>Niche Avatars:</p>
                                                <ul>
                                                    <li>
                                                        <div className="image">
                                                            <img src={base + level.avatar[0]?.musicAvatar[0]?.image} alt="" className="img-fluid" />
                                                        </div>
                                                        <span>Music Collector</span>
                                                    </li>
                                                    <li>
                                                        <div className="image">
                                                            <img src={base + level.avatar[0]?.ebookAvatar[0]?.image} alt="" className="img-fluid" />
                                                        </div>
                                                        <span>EBook Collector</span>
                                                    </li>
                                                    <li>
                                                        <div className="image">
                                                            <img src={base + level?.avatar[0]?.artAvatar[0]?.image} alt="" className="img-fluid" />
                                                        </div>
                                                        <span>Art Collector</span>
                                                    </li>
                                                    <li>
                                                        <div className="image">
                                                            <img src={base + level.avatar[0]?.gamesAvatar[0]?.image} alt="" className="img-fluid" />
                                                        </div>
                                                        <span>Game Collector</span>
                                                    </li>
                                                </ul>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </>)
                   }) : ""} */}
                {/* accordion end */}
                {/* {numberOfItems > 9 ?

                    levels?.length > 1 ? <>{isFetching && <p>Fetching items...</p>}
                        {!isFetching && <div className="load-more">
                            <Button variant="primary" onClick={() => { loadMoreItems() }}>Load More</Button>
                        </div>}</> : "" : ""} */}
            </div>
            <Promotion type="Levels & Achievements" />
        </>
    );
}
export default LevelsAchievements;