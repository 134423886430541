import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from "react"
import { getNotifications } from "../../redux/user/action"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { getUserReqeusts } from '../../redux/requests/action';

function Activities() {
    const requests = useSelector(state => state.requests.requests)

    const dispatch = useDispatch();
    let walletAddress = localStorage.getItem("WalletAddress")

    useEffect(() => {
        if (!walletAddress) {
            walletAddress = localStorage.getItem("WalletAddress")
            dispatch(getNotifications({ walletAddress }))
        }
    }, [walletAddress])
    useEffect(async () => {
        if (walletAddress) {
            dispatch(getUserReqeusts(walletAddress, ""))
        }

    }, [])

    const showRequests = () => {
        if(requests){
            if(requests.length > 0){
                return requests.slice(0,4).map((request, key) => {
                    let {points, icon, iconClass} = ""
                    if(request.approved === "true"){
                       points = <strong className='points achieved-points'>+{request.task.points}</strong>
                       icon = <FontAwesomeIcon icon={faCheck} />
                       iconClass = "achieved-icon"
                    }else if(request.approved === ""){
                        icon = <FontAwesomeIcon icon={faClockRotateLeft} />
                        iconClass = "pending-icon"
                    }else{
                        icon = <FontAwesomeIcon icon={faXmark} />
                        iconClass = "rejected-icon"
                    }
                    return (
                        <li className="" key={key} >
                            <div className='px-3 py-1 d-flex'>
                                <span className={`icon ${iconClass}`}>{icon}</span>
                                
                                <p dangerouslySetInnerHTML={{ __html: request.contestData?.[0]?.description }} ></p>
                                {points}
                            </div>
                        </li>
                    )
                })
            }else{
                return "No requests found!"
            }
        }else{
            return ""
        }
    }
    return (
        <div className="activities">
            <div className="section heading">
                <h4>Activities</h4>
                {requests && requests?.length > 0 ? <Link to={`/activities?route=Activities`} className="view-all">VIEW ALL</Link>
                    : ""}
            </div>
            <ul>
               {showRequests()}
            </ul>
        </div>
    );
}

export default Activities;

