import { GET_NOTES, SET_LOADER } from "../actionTypes";

const initialState = {
    notes: {},
    success: false,
    loaderActive: true
}

const notesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTES:
            return {
                ...state,
                notes: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload
            }
        default:
            return state;
    }
}
export default notesReducer;