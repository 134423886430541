import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ENV } from "../../config/config"
import { updateProfile, updateGamificationProfile, editUserStatus ,getUserStatus } from '../../redux/user/action';
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { getQueryParam } from '../../config/helper'
// import Dummy from '../../../public/images/placeholder.png' ;

let base = process.env.REACT_APP_MEDIA_URL

function EditClickModal() {
    let location = useLocation();
    // let address = getQueryParam(location, 'address')
    const address = ENV.getUserKey("WalletAddress")
    // const dummyAddress = "0x5241b6b1082080C7f103d3C5CA5EAe55eCEA3293"
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [active, setActive] = useState(false);
    const [image, setImage] = useState("")
    const handleClose = () => {
        setShow(false);
        setenabled(false)
        setErrors("")
    }
    const handleShow = () => {
        setShow(true);
        setErrors("")
    }
    const [platform, setPlatform] = useState({
        gamification: false,
        nftMarketplace: false,
        physicalGoods: false
    })
    const [enabled, setenabled] = useState(false);
    const myAvatars = useSelector(state => state.userData?.profile?.avatars)
    const [errors, setErrors] = useState("")

    const errorFailed = () => {
        setErrors("")
    }
    const handlePlatform = (image) => {
        setenabled(true);
        setImage(image)
        setErrors("")
    }

    const handleChange = (event) => {
        setPlatform({ ...platform, [event.target.name]: event.target.checked });
    }

    const handleSubmit = async () => {
        if (myAvatars.length < 1) {
            setErrors("you haven't earn avatars yet");
        } else if (platform.nftMarketplace === false && platform.gamification === false && platform.physicalGoods === false) {
            setErrors("please select platform first ");
        } else {
            setErrors("");
            const formData = new FormData();
            formData.append("profileImage", base + image);
            formData.append("address", address);
            
            if (platform.gamification) {
              let editUserStatusData = {
                address: address,
                picture: image
              };
              dispatch(editUserStatus({ formData: editUserStatusData }));
              toast.success("Avatar Added Successfully on Gamification");
            }
            
            if (platform.nftMarketplace) {
              dispatch(updateProfile(formData));
              toast.success("Avatar Added Successfully on NFT Marketplace");
            }
            
            if (platform.physicalGoods) {
              dispatch(updateProfile(formData));
              toast.success("Avatar Added Successfully on physical goods");
            }
            
            setenabled(false);
            handleClose();
            
            setPlatform({
              gamification: false,
              nftMarketplace: false,
              physicalGoods: false
            });
            
        }
    }
    const handleHighlight = (key) => {
        setActive(key);
      };

    return (
        <>
            <button className=" btn-gradient-blue" onClick={handleShow}>
                Edit
            </button>
            <Modal id="edit-avatr-modal" show={show} onHide={handleClose} animation={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="text-center">
                    <Modal.Title className="title modal-title h4">Select Avatar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <ul className="d-flex justify-content-space-between align-item-center badges-images">
                            {myAvatars?.length > 0 ? myAvatars?.map((singleBadge, key) => {
                                return (
                                    <li key={key}>
                                        <div onClick={() =>handleHighlight(key)} className={`${active===key ? "edit-avatar-style active" : "edit-avatar-style"}`}> <img src={singleBadge.name ? base + singleBadge?.name : "/images/placeholder.png"} alt="image" onClick={() => handlePlatform(singleBadge?.name)} className="img-fluid single-badge-img" /> </div>
                                    </li>
                                )
                            }) : "No Avatar Earned Yet!"}

                        </ul>


                    </div>
                    <div className='row'>
                        {enabled ? <div className="content custom-modal-content text-center">
                            <p>Where do you want to upload the picture</p>

                            <Form.Group className="mb-3 d-flex align-item-center justify-content-center " controlId="exampleForm.ControlInput1">
                                {/* <Form.Label>Platform</Form.Label> */}
                                <Form.Check
                                    name="gamification"
                                    label="Gamification"
                                    // setCheckflag={!Checkflag}
                                    onChange={(e) => handleChange(e)}
                                    onClick={errorFailed}
                                    value="NFT MarketPlace"
                                />
                                <Form.Check
                                    name="nftMarketplace"
                                    label="NFT"
                                    // setCheckflag={!Checkflag}
                                    onChange={(e) => handleChange(e)}
                                    onClick={errorFailed}
                                    value="NFT"

                                />
                                <Form.Check
                                    name="physicalGoods"
                                    label="Physical Goods"
                                    // setCheckflag={!Checkflag}
                                    onChange={(e) => handleChange(e)}
                                    onClick={errorFailed}
                                    value="Gamification"

                                />
                            </Form.Group>
                        </div> : ""}
                        {errors ? (<div style={{ color: "#FE6E00" }} className="alert alert-danger"> {errors} </div>) : ("")}

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" disabled={myAvatars?.length > 0?false:true} onClick={handleSubmit}>
                        Update
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditClickModal;
