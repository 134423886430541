import {React, useState, useEffect} from "react";
import {Navbar, Nav, Container} from "react-bootstrap"
import { Link,useHistory } from 'react-router-dom'
import Logo from "../../../assets/images/logo.svg"
import Bars from "../../../assets/images/bars.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from "react-redux";
import { ENV } from "../../../config/config"
import { faArrowRightToBracket, faDownload, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import "./themeHeader.css"


function ThemeHeader() {
    let history=useHistory()
    const [searchShow, setSearchShow] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [seachLoader, setSeachLoader] = useState(false);
    const [searchBtnClick, setBtnClick] = useState(false);
    const [loginText, setLoginText] = useState('Login');
    let walletAddress = useSelector(state => state?.walletProvider?.currentUser) || ENV.getUserKey("WalletAddress")
    
    const handleChange = (event) => {
        setSearchInput(event.target.value)
    }

    const handleResetPost = async () => {
    }

    const handleSearch = () => {
        setBtnClick(true)
        if (searchShow == true && searchInput.length > 0) {
            // handleSearchPost()
        }
        else {
            setSearchShow(!searchShow);
        }
    }
    
  return (
    <div className="theme-header landing-wrap py-3">
        <Navbar collapseOnSelect expand="lg" variant="dark" className="justify-content-between align-items-center header-navbar">
            <strong>
                <Link to="/">
                    <div class="site-logo d-flex align-items-center">
                        <div class="image me-lg-3 me-2">
                            <img src={Logo} alt="brand-logo" class="img-fluid"/>
                        </div>
                        <div class="name text-end">
                            <h5 className="mb-0">Myntist</h5>
                            <span>Gamification</span>
                        </div>
                    </div>
                </Link>
            </strong>
            <div className="site-navlinks d-flex">
                {/* <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav  className={searchShow ? "d-lg-none" : " transition me-auto"}>
                        <Link to="/">Marketplace</Link>
                        <Link to="/">Collections</Link>
                        <Link to="/">Live Auctions</Link>
                        <Link to="/">Ranking</Link>
                    </Nav>
                </Navbar.Collapse> */}
                <div className="d-flex">
                    {/*<div className='search-login-btn me-2'>
                        <div name='searchbtn' className='d-flex align-items-center'>
                            <input type="text" id="site-search" name='searchbtn' placeholder='Search...' className={searchShow ? "input-width transition btn-align" : "transition input-no-width btn-align"} value={searchInput} onChange={handleChange} />
                            <button name='searchbtn' className={searchShow ? "d-block refresh-search" : "d-none search-btn-block"} onClick={handleResetPost}><FontAwesomeIcon icon={faRefresh} name="searchbtn" /></button>
                        </div>
                        <button name='searchbtn' className={searchShow ? "search-icon main-btn-style btn-align active" : "main-btn-style btn-align search-icon"} disabled={seachLoader} onClick={() => handleSearch()}>
                            <FontAwesomeIcon icon={faSearch} name="searchbtn" />
                        </button>
                    </div>*/}
                </div> 
                {walletAddress?
                <button onClick={()=>{history.push(walletAddress?`/dashboard?address=${walletAddress}`:'')}} className="btn-style-1 me-0">
                {/* <span className="d-lg-none d-block icon"><FontAwesomeIcon icon={faArrowRightToBracket} name="searchbtn" /></span> */}
                <span className="">{walletAddress?`Dashboard`:'Login'}</span>
                </button>
                :
                <Link to={'/login'} className="btn-style-1 me-0">
                    {/* <span className="d-lg-none d-block icon"><FontAwesomeIcon icon={faArrowRightToBracket} name="searchbtn" /></span> */}
                    <span className="">{walletAddress?`Dashboard`:'Login'}</span>
                </Link>}
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" className='ms-2'>
                    <img loading='lazy' src={Bars} alt="toggle" className='img-fluid' />
                </Navbar.Toggle> */}
            </div>
        </Navbar>
    </div>
  );
}

export default ThemeHeader;



{/* <header class="treasurebox-header">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="main-logo" href="/">
                <span class="d-flex align-items-center navbar-brand">
                <div class="image">
                    <img height="50" width="50" loading="lazy" src="" alt="" class="img-fluid"/>
                </div>
                MYNTIST
                </span>
            </a>
            <div class="d-flex order-3">
                <div class="search-login-btn">
                    <div name="searchbtn" class="d-flex align-items-center">
                        <input type="text" id="site-search" name="searchbtn" placeholder="Search..." class="transition input-no-width" value=""/>
                        <button type="button" name="searchbtn" class="d-none search-btn-block btn btn-primary">ok</button>
                    </div>
                    <button type="button" name="searchbtn" class="main-btn-style search-icon btn btn-primary">ok</button>
                </div>
                <a class="main-btn-style ms-2 nav-btn" href="/format">
                    <span class="d-lg-block d-none">Add Post</span>
                    <span class="d-lg-none d-block"></span>
                </a>
                <div class="profile-notifications-main d-lg-block">
                    <div class="profile-notifications position-relative">
                        <button type="button" class="ms-2 btn btn-primary">
                            <span class="notifi-digit">8</span>
                        </button>
                        <div class="position-relative notify-box">
                            <div class="d-none">
                                <h5 class="text-center">Notifications</h5>
                                <ul>
                                    <li class="unread-notification">
                                        <a href="/post-view/64267b80d84b2957fde69f73">
                                        <p>
                                            Congratulations @<strong>aa</strong>, Viewed your
                                            post, you earned 1 point
                                        </p>
                                        </a>
                                    </li>
                                    <li class="unread-notification">
                                        <a href="/post-view/64267b0fd84b2957fde69d1d">
                                        <p>
                                            Congratulations @<strong>sheraz</strong>, Viewed
                                            your post, you earned 1 point
                                        </p>
                                        </a>
                                    </li>
                                    <li class="unread-notification">
                                        <a href="/post-view/64267b80d84b2957fde69f73">
                                        <p>
                                            Congratulations @<strong>sheraz</strong>, Viewed
                                            your post, you earned 1 point
                                        </p>
                                        </a>
                                    </li>
                                    <li class="unread-notification">
                                        <a href="/post-view/64267b80d84b2957fde69f73">
                                        <p>
                                            Congratulations @<strong>usamammmm</strong>,
                                            Viewed your post, you earned 1 point
                                        </p>
                                        </a>
                                    </li>
                                </ul>
                                <a class="text-center see-all-activities" href="/notifications-all">
                                    <p>See all Notifications</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="profile-notifications d-lg-block d-none">
                    <a class="nav-link" href="/settings/profile">
                        <button type="button" class="me-1 ms-2 btn btn-primary">prof</button>
                    </a>
                </div>
                <button aria-controls="basic-navbar-nav" type="button" aria-label="Toggle navigation" class="ms-2 navbar-toggler collapsed">
                    menu
                </button>
            </div>
            <div class=" justify-content-end navbar-collapse collapse" id="basic-navbar-nav">
                <div class=" transition ms-auto align-items-end navbar-nav">
                    <a class="nav-link me-2 is-active" href="/">
                        Treasurebox
                    </a>
                    <a class="nav-link me-2" href="/board">
                        LEADERBOARD
                    </a>
                    <a class="nav-link me-2" href="/your-wall">
                        Your Wall
                    </a>
                </div>
            </div>
        </nav>
    </div>
</header> */}