import { getDefaultWallets, wallet, connectorsForWallets } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, Chain } from 'wagmi';
// import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';


const binanceChainTestnet = {
  id: 97,
  name: 'BSC Chain Testnet',
  network: 'bsc-test',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Coin',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  },
  blockExplorers: {
    default: {
      name: 'Binance Smart Chain Scan',
      url: 'https://testnet.bscscan.com',
    },
  },
  testnet: true,
};

export const { chains, provider } = configureChains(
//   [chain.mainnet, chain.polygon, binanceChainTestnet],
[binanceChainTestnet],
  [
    // alchemyProvider({
    //   apiKey: '_gg7wSSi0KMBsdKnGVfHDueq6xMB9EkC',
    // }),
    publicProvider(),
  ]
);

 export const getClient = (type) => {
    if(type === 1){
      return connectorsForWallets([
        {wallets: wallet.metaMask({chains})}
      ])
    } else {
      connectorsForWallets([
        {
        groupName: 'Recommended',
        wallets: [
          wallet.metaMask({chains}),
          wallet.coinbase({chains}),
          wallet.walletConnect({chains}),
      
        ]
      }, 
      // {
      //   groupName: 'Others',
      //   wallets: [
      //     wallet.trust({chains}),
      //     wallet.rainbow({chains}),
      //   ]
      // }
      ])
    }
 }


const connectors = connectorsForWallets([
    {
    groupName: 'Recommended',
    wallets: [
      wallet.metaMask({chains}),
      wallet.coinbase({chains}),
      wallet.walletConnect({chains}),
  
    ]
  }, 
  // {
  //   groupName: 'Others',
  //   wallets: [
  //     wallet.trust({chains}),
  //     wallet.rainbow({chains}),
  //   ]
  // }
  ])

// const { connectors } = getDefaultWallets({
//   appName: 'NFTLaunchpad',
//   chains,
// });

export const wagmiClient = createClient({
  autoConnect: false,
  connectors,
  provider,
});

