
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getNfts } from '../../redux/user/action';
import { useEffect, useState } from 'react';
import { ENV } from "../../config/config"
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { countDownRenderer, getQueryParam } from '../../config/helper'
import { SkeletonLayout } from "react-skeleton-loader-pulse";
import ReactTooltip from 'react-tooltip'

function MyNFTs(props) {
    let location = useLocation()
    const nftsRes = useSelector(state => state.userData.nfts)
    const loaderA = useSelector(state => state.userData.nftLoader)
    let [numberOfSlides, setNumberOfSlides] = useState();
    let [responciveSlides, setresponciveSlides] = useState();
    const dispatch = useDispatch();
    let walletAddress = localStorage.getItem("WalletAddress")
    let wallet = getQueryParam(location, 'address')
    useEffect(() => {
        if (wallet) {
            dispatch(getNfts(wallet))
        }
        else {
            dispatch(getNfts(walletAddress))
        }
    }, [wallet, walletAddress])
    useEffect(() => {
        if (nftsRes) {
            if (nftsRes?.nfts?.length >= 4) {
                setNumberOfSlides(4);
                setresponciveSlides(3);
            } else {
                setNumberOfSlides(nftsRes?.nfts?.length);
                if (nftsRes?.nfts?.length >= 2) {
                    setresponciveSlides(2);
                } else {
                    setresponciveSlides(1);
                }
            }
        }
    }, [nftsRes])
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        slidesToShow: numberOfSlides,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: responciveSlides,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    function renderNFTs(nftsRes) {
        if (nftsRes?.nfts && nftsRes?.nfts.length > 0) {
            if (nftsRes?.nfts && nftsRes?.nfts.length > 4){
                return(
                    <div className="nft-list">
                        <Slider {...settings} className={`nft-slider ${nftsRes?.nfts.length === 3 ? "set-slider" : ""}`}>
                            {nftsRes.nfts && nftsRes.nfts.map((nft, key) => {
                                return (
                                    <div className='item' key={key}>
                                        <div className={nft?.nftType?.name === "NFTC" ? "time-img-wrap nftc" : nft?.nftType?.name === "NFTD" ? "time-img-wrap nftd" : nft?.nftType?.name === "NFTCD" ? "time-img-wrap nftcd" : "time-img-wrap"}>
                                            {nft?.nftType?.name === "NFTC" ? <div className='nft-type-wrap'>C</div>
                                                :
                                                nft?.nftType?.name === "NFTD" ? <div className='nft-type-wrap'>D</div>
                                                    :
                                                    nft?.nftType?.name === "NFTCD" ? <div className='nft-type-wrap'>CD</div>
                                                        : ""
                                            }
                                            <div className="nft-image">
                                                <div className='img mb-2'>
                                                    <a to="https://hex-nft.arhamsoft.org/explore-all" className="view-all d-block">
                                                        <Link to={{ pathname: `${nft.baseUrl}${window.btoa(nft._id)}` }} target="_blank" className='nft-img-link'>
                                                            <img src={nft.imageLocal} alt="" className="img-fluid w-100" />
                                                        </Link>
                                                        {nft.status === "On Sale" ? <span className="sale">On Sale</span> : ""}
                                                    </a>
                                                </div>
                                            </div>
                                            {nft.auctionEndDate ?
                                                <div className='time-wrap'>
                                                    <Countdown date={Date.parse(nft.auctionEndDate) + 10000}
                                                        renderer={countDownRenderer} />
                                                </div>
                                                : ""}
                                        </div>
                                        <div className="name"><Link to={{ pathname: `${nft.baseUrl}${window.btoa(nft._id)}` }} target="_blank">{nft.name}</Link></div>
                                        {nft.currentBid ? <div className="price">
                                            <label>Current Bid</label> <div className="curreny-image d-flex align-items-center justify-content-start">
                                                {nft.currentBid.currency === 'ETH' ? <img src="/images/ethereum.svg" alt="ethereum" className="img-fluid me-2" /> :
                                                    nft.currentBid.currency === 'WBNB' ? <img src="/images/binance-coin-logo.webp" alt="ethereum" className="img-fluid me-2" /> :
                                                        nft.currentBid.currency === 'GRAV' ? <img src="/images/graviton-coin" alt="ethereum" className="img-fluid me-2" /> : ''}
                                                <span>{nft.currentBid.amount} {nft.currentBid.currency}</span>
                                            </div >
                                        </div >
                                            : ""
                                        }
                                    </div >
                                )
                            })}
                        </Slider >
                    </div >
                )
            }
            else{
                return(
                    <div className='nft-list not-slider d-flex flex-wrap'>
                        {nftsRes.nfts && nftsRes.nfts.map((nft, key) => {
                            return (
                                <div className='item' key={key}>
                                    <div className={nft?.nftType?.name === "NFTC" ? "time-img-wrap nftc" : nft?.nftType?.name === "NFTD" ? "time-img-wrap nftd" : nft?.nftType?.name === "NFTCD" ? "time-img-wrap nftcd" : "time-img-wrap"}>
                                        {nft?.nftType?.name === "NFTC" ? <div className='nft-type-wrap'>C</div>
                                            :
                                            nft?.nftType?.name === "NFTD" ? <div className='nft-type-wrap'>D</div>
                                                :
                                                nft?.nftType?.name === "NFTCD" ? <div className='nft-type-wrap'>CD</div>
                                                    : ""
                                        }
                                        <div className="nft-image">
                                            <div className='img mb-2'>
                                                <a to="https://hex-nft.arhamsoft.org/explore-all" className="view-all d-block">
                                                    <Link to={{ pathname: `${nft.baseUrl}${window.btoa(nft._id)}` }} target="_blank" className='nft-img-link'>
                                                        <img src={nft.imageLocal} alt="" className="img-fluid w-100" />
                                                    </Link>
                                                    {nft.status === "On Sale" ? <span className="sale">On Sale</span> : ""}
                                                </a>
                                            </div>
                                        </div>
                                        {nft.auctionEndDate ?
                                            <div className='time-wrap'>
                                                <Countdown date={Date.parse(nft.auctionEndDate) + 10000}
                                                    renderer={countDownRenderer} />
                                            </div>
                                            : ""}
                                    </div>
                                    <div className="name"><Link to={{ pathname: `${nft.baseUrl}${window.btoa(nft._id)}` }} target="_blank">{nft.name}</Link></div>
                                    {nft.currentBid ? <div className="price">
                                        <label>Current Bid</label> <div className="curreny-image d-flex align-items-center justify-content-start">
                                            {nft.currentBid.currency === 'ETH' ? <img src="/images/ethereum.svg" alt="ethereum" className="img-fluid me-2" /> :
                                                nft.currentBid.currency === 'WBNB' ? <img src="/images/binance-coin-logo.webp" alt="ethereum" className="img-fluid me-2" /> :
                                                    nft.currentBid.currency === 'GRAV' ? <img src="/images/graviton-coin" alt="ethereum" className="img-fluid me-2" /> : ''}
                                            <span>{nft.currentBid.amount} {nft.currentBid.currency}</span>
                                        </div >
                                    </div >
                                        : ""
                                    }
                                </div >
                            )
                        })}
                    </div>
                )
            }
        }
        else {
            <div className='not-found'>
                <p>Let's create NFT and earn reward.</p>
                <a href={process.env.REACT_APP_NFT_PLATFORM_URL} target="_blank" className='main-btn-style'>Create NFT</a>
            </div>  
        }
    }

    return (
        <div className="nft-sec">
            <div className="section-dark-bg text-center skelton-justify my-nft-sec">
                {loaderA ?
                    <SkeletonLayout
                        align="center"
                        color="#000000"
                        direction="row"
                        items={[
                            {
                                height: 200,
                                marginBottom: 0,
                                marginTop: 30,
                                marginRight: 30,
                                marginLeft: 30,
                                width: 200,
                                borderRadius: "3px",

                            }, {
                                height: 200,
                                marginBottom: 0,
                                marginTop: 30,
                                width: 200,
                                marginRight: 30,
                                marginLeft: 30,
                                borderRadius: "3px",

                            }, {
                                height: 200,
                                marginBottom: 0,
                                marginTop: 30,
                                width: 200,
                                marginRight: 30,
                                marginLeft: 30,
                                borderRadius: "3px",

                            },
                        ]}
                    />
                    : <>
                        <div className="section heading">
                            <h5 className='ps-2'>My NFTs</h5>
                            {nftsRes?.nfts && nftsRes?.nfts.length > 0 ? <a href={nftsRes?.viewAll} target="_blank" className="view-all">VIEW ALL</a>
                                : ""}
                        </div>

                        {renderNFTs(nftsRes)}
                    </>
                }
            </div >
        </div >
    );
}

export default MyNFTs;
