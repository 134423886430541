import { POST_QUIZ } from "../actionTypes";

const initialState = {
    quiz:[]
}
const quiz = (state = initialState, action) => {
    switch (action.type) {
        case POST_QUIZ:
         return {
            ...state,
            quiz: action.payload
         }
        default:
            return state;
    }
}
export default quiz;