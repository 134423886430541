import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from "react"
import MetaMaskOnboarding from "@metamask/onboarding";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { Modal, Button } from 'react-bootstrap'
import { RegisterUser, postEvent } from "../redux/user/action"
import { useHistory, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import { getCurrentUser } from "../redux/user/action";
import FullPageLoader from '../components/loader/loader';
import GamificationLoader from '../components/gamificationLoader/GamificationLoader';

function ConnectModel(props) {
    let location = useLocation();
    let loaderA = useSelector(state => state.userData.loaderActive);

    let searchParams = new URLSearchParams(location.search)
    // let { code } = queryString.parse(window.location.search)
    let { code } = useParams();
    let { refAddress } = queryString.parse(window.location.search)
    const user = useSelector((state) => state.userData?.profile);
    let WalletAddress = localStorage.getItem("WalletAddress")

    const success = useSelector((state) => state.userData.success);

    let history = useHistory();
    const dispatch = useDispatch();
    const onboarding = useRef();
    const [show, setShow] = useState(Boolean(props.show))
    // let WalletAddress = localStorage.getItem("WalletAddress")
    let userId = localStorage.getItem("userId")
    let address = searchParams.get('address')
    // useEffect(() => {
    //     if (window?.ethereum?.selectedAddress && WalletAddress) {

    //         history.push(`dashboard?address=${window?.ethereum?.selectedAddress}`)
    //     }
    // }, [])
    const Register = async (address) => {
        const data = {
            address: address,
            // name: "",
            // image: "",
            inviteeCode: code ? code : ""
        }
        let res = await dispatch(RegisterUser(data))
        let eventName = '';
        if (res) {
            if (res?.referrerInfo?.refsCount) {
                let refsCount = res?.referrerInfo?.refsCount
                if (refsCount >= 100 && refsCount < 500) {
                    eventName = '100-500 people';
                }
                else if (refsCount >= 500 && refsCount < 1000) {
                    eventName = '500-1000 people';
                }
                else if (refsCount >= 1000 && refsCount < 10000) {
                    eventName = '1000-10000 people';
                }
                else if (refsCount >= 10000 && refsCount < 50000) {
                    eventName = '10000-50000 people';
                }
                else if (refsCount >= 50000 && refsCount < 100000) {
                    eventName = '50000-100000 people';
                }
                else if (refsCount >= 100000 && refsCount < 1000000) {
                    eventName = '100000-1000000 people';
                }
                let eventData = {
                    eventName,
                    userId: res?.referrerInfo?.address
                }
                let eventRes = dispatch(postEvent(eventData))
            }

        }



    }
    const redirectToMetamaskWeb = () => {
        return (
            <div className='redirectMetamask'>
                <p>To install Metamask click on this <a target="_blank" href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en'> Link </a></p>
            </div>
        )

    }

    const connectToMetamask = async () => {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = await provider.getSigner();
            if (provider) {
                try {
                    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
                        await provider.send("eth_requestAccounts", []);
                        let signerAddress = await signer.getAddress();
                        const network = await provider.getNetwork();
                        if (signerAddress) {
                            if (network) {
                                if (signerAddress) {
                                    setShow(false)
                                    localStorage.setItem('WalletAddress', signerAddress)
                                    await Register(signerAddress)
                                    if (props.flag === true) {
                                        history.push(`/uploadContest`)
                                    }
                                    else if (props.flag === undefined) {
                                        if (props.urlToNavigate) {
                                            history.push(`${props.urlToNavigate}`)
                                        } else {
                                            history.push(`/dashboard?address=${signerAddress}`)
                                        }
                                    } else if (!props.flag && props.history_url) {
                                        history.push(`${props.history_url}`)
                                    }
                                    onboarding.current?.stopOnboarding();
                                }
                                else {
                                    onboarding.current.startOnboarding();
                                }
                            }
                        }
                    }
                }
                catch (error) {
                    if (error.code === 4001) {
                        toast.error("Error connecting");
                    } else if (error.code === -32002) {
                        toast.error(error.message);
                    } else {
                        toast.error(error.message);
                    }
                }
            }
        } else {
            toast.error('Please install Metamask First ')
            toast.info(redirectToMetamaskWeb, {
                autoClose: 20000
            });

        }
    }
    return (
        <div className="activities">
            {loaderA ? <GamificationLoader /> : null}
            <div className="">
                <div className="heading">

                    <Modal centered className="custom-modal connect-wallet" show={show} >
                        <Modal.Header >
                            <Modal.Title>Connect your Wallet</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Please select the wallet you want to connect to.</p>
                            <div className="image">
                                <img src="/images/metamask.png" alt="Metamask" className="img-fluid" />
                            </div>
                            <h4>MetaMask</h4>
                            <Button className="connect-btn btn btn-gradient-blue" onClick={connectToMetamask}>
                                Connect
                            </Button>
                        </Modal.Body>

                    </Modal>

                </div>

            </div>
        </div>
    );
}

export default ConnectModel;

