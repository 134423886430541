import React, { useState, useEffect } from 'react'
import { Form, Button, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { postVideo, getContest, postEvent } from '../../redux/uploadContest/action';
import { getAccount } from '../../config/helper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ENV } from '../../config/config';
import truncateEthAddress from 'truncate-eth-address'
import { getTasks } from '../../redux/tasks/action'
import { toast } from 'react-toastify';
import Select from 'react-select';
import FullPageLoader from '../loader/loader';
import connectToMetamask from '../dashboard/sidebar/leftSidebar/leftSidebar'
import Connect from '../../connectWallet/connect';
import { useHistory, useLocation } from 'react-router-dom';
import { getQueryParam } from '../../config/helper';
import Promotion from '../promotion/promotion';
import { getEnabledPromotion } from "../../redux/promotions/action";
import Popup from "../popup/popup"
import GamificationLoader from '../gamificationLoader/GamificationLoader';
const UploadContest = (props) => {
  const customId = "custom-id-yes";

  let location = useLocation()
  let contestId = getQueryParam(location, 'contestId')
  let promotions = useSelector(state => state?.promotions?.promotion);

  let history = useHistory();
  const dispatch = useDispatch();
  const tasks = useSelector(state => state?.tasks?.task)
  const contestData = useSelector(state => state.contestVideoData.contest);
  let loaderA = useSelector(state => state.contestVideoData.loaderActive);
  const walletAddr = localStorage.getItem("WalletAddress")
  const [userID, setuserID] = useState(JSON.parse(localStorage.getItem("userInfo")))
  const [show, setShow] = useState(false)
  const [errors, setErrors] = useState('');
  const [contestCard, setcontestCard] = useState("")
  const [flag, setflag] = useState(true)
  const [querryContent, setquerryContent] = useState("")
  const [copied, setCopied] = useState(false)
  const [singleVideo, setSingleVideo] = useState({
    userId: '',
    socialLinks: [''], // Array to store multiple URLs
    walletAddress: ''
  });
  const [allContest, setAllContest] = useState([])
  const [platform, setPlatform] = useState({
    value: "", label: "Select Platform"
  });
  const [contest, setContest] = useState({
    value: "", label: "Select Contest"
  });
  const [platformList, setPlatformList] = useState([{
    value: 'Myntist',
    label: 'Myntist'
  }, {
    value: 'Gamification',
    label: 'Gamification'
  }, {
    value: 'Physical goods',
    label: 'Physical goods'
  }, {
    value: 'NFT Marketplace',
    label: 'NFT Marketplace'
  },{
    value: 'Treasurebox',
    label: 'Treasurebox'
  }]);

  useEffect(() => {
    if (copied) {
      toast.success("Wallet address copied to clipboard ", { toastId: customId })
      setCopied(false)
    }
  }, [copied])

  useEffect(() => {
    dispatch(getContest())
    dispatch(getTasks());
    dispatch(getEnabledPromotion("contest"));

  }, [])
  // useEffect(() => {
  //   // getAccount()
  //   //  let add  =  window.ethereum.selectedAddress
  //   window.onload = event => {
  //     const account = getAccount();
  //     console.log(window.ethereum.selectedAddress ? `You're connected ` : 'Metamask is not connected');
  //   };
  // }, [window.ethereum])

  useEffect(() => {
    getUpdatedContest()

  }, [contestData])

  const [contestList, setContestList] = useState({
    value: '',
    label: ''
  });

  const getUpdatedContest = async () => {

    let contestOption = [];
    for (let i = 0; i < contestData.length; i++) {
      contestOption.push({ value: contestData[i]._id, label: contestData[i].name, platform: contestData[i].platform });
    }
    // setContestList(contestOption);
    setAllContest(contestOption);
    setContest({
      value: "", label: "Select Contest"
    })

  }
  const handleContest = () => {
    let array = contestData.filter((content) => {

      if (contestId) {
        return (
          content._id.includes(contestId)
        )
      }
    })
    if (array[0]?.name) {
      setContest({ value: array[0]?.name, label: array[0]?.name })
      setPlatform({ value: array[0]?.platform, label: array[0]?.platform })
    }

  }

  useEffect(() => {
    if (platform.value) {
      let platformContest = allContest.filter((contest) => contest.platform === platform.value);
      setContestList(platformContest);
    }
  }, [platform])

  useEffect(() => {
    handleContest()
  }, [contestData])

  const handleContestChange = (e) => {
    if (!walletAddr) {
      setShow(true)
    } else {
      setShow(false)
      setContest({ value: e.value, label: e.label })
    }
  }
  const handlePlatformChange = (e) => {
    if (!walletAddr) {
      setShow(true)
    } else {
      setShow(false)
      setPlatform({ value: e.value, label: e.label })
      if (contest && platform.value !== e.value) {
        setContest({ value: "", label: "Select Contest" })
      }
    }
  }
  const handleChange = async (event) => {
    if (!walletAddr) {
      setShow(true)
    } else {
      setShow(false)
      const value = event.target.value;
      setSingleVideo({ ...singleVideo, [event.target.name]: value })
    }
  }
  const handleUrlChange = (event, index) => {
    if (!walletAddr) {
      setShow(true)
    } else {
      setShow(false)
    const { name, value } = event.target;
  
    if (name === 'socialLink') {
      const updatedSocialLinks = [...singleVideo.socialLinks];
      updatedSocialLinks[index] = value;
  
      setSingleVideo({ ...singleVideo, socialLinks: updatedSocialLinks });
    } else {
      setSingleVideo({ ...singleVideo, [name]: value });
    }
  }
  };
  
  const handleDashboardLink = () => {
    let walletAddress = ENV.getUserKey("WalletAddress")
    if (walletAddress) {
      history.push(`/dashboard?address=${walletAddress}`)
    }
    else {
      history.push('/')
    }
  }
  const filterData = () => {
    let array = contestData.filter((content) => {
      if (contest.label !== "Select Options....") {
        return (
          content.name.includes(contest.label)
        )
      }
    })
    setcontestCard(array)
  }

  useEffect(() => {

    if (contestCard[0]?._id) {
      history.push(`/uploadContest?route=Contest&&contestId=${contestCard[0]?._id}`)
    }

  }, [contestCard])

  useEffect(() => {
    if (contest.label !== "Select Options....") {
      filterData()
    }
  }, [contest])

  const handleSubmit = async (e) => {
    e.preventDefault();
    let regexQuery = /^((?:ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-]+)*(?:\/(?:[\w-]+\.)*(?:[\w-]+))?(?:\?(?:[\w-]+=[\w-]+)(?:&(?:[\w-]+=[\w-]+))*)?(?:#[\w-]+)?$/;
    const invalidUrls = singleVideo.socialLinks.filter(link => {
      return !regexQuery.test(link);
    });
    const hasEmptyUrls = singleVideo.socialLinks.some(link => link === '');
    if (platform.value == "Select Options...." || platform.value == "") {
      setErrors("Platform should not be empty")
    }
    else if (contest.value == "Select Options...." || contest.value == "") {
      setErrors("Contest should not be empty")
    }
    else if (hasEmptyUrls) {
      setErrors("URLs should not be empty")
    }
    else if (invalidUrls.length > 0) {
        setErrors("URL should be in valid format");
    }
    else if (!walletAddr) {
      setErrors("Wallet Address should not be empty")
    }
    else {
      try {
        const data = {
          platform: platform.value,
          socialLink: singleVideo.socialLinks,
          walletAddress: walletAddr,
          contest: contest.value
        }
        dispatch(postVideo(data));
        setSingleVideo({
          platform: "",
          userId: '',
          socialLinks: [''],
          walletAddress: ''

        })
        setErrors("")
        setContest({ value: "Select Options....", label: "Select Options...." })
        setPlatform({ value: "Select Options....", label: "Select Options...." })
      }
      catch (err) {
        console.log("Error", err)
        toast.error("Something went wrong!");
      }
    }
  }
  const addURLField = () => {
    setSingleVideo({ ...singleVideo, socialLinks: [...singleVideo.socialLinks, ''] });
  };
  const removeURLField = (index) => {
    const updatedSocialLinks = [...singleVideo.socialLinks];
    updatedSocialLinks.splice(index, 1);
  
    setSingleVideo({ ...singleVideo, socialLinks: updatedSocialLinks });
  };

  return (
    <>
      <Popup data={promotions} />

      {/* {loaderA ? <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
        </div>
      </div> : null} */}
      {!show ? "" : <Connect flag={flag} />}
      <div className="page-title d-flex justify-content-between">
        {/* <h2 className='py-0'>Upload Contest</h2> */}
        {/* {walletAddr &&
          <Button variant="btn btn-gradient-blue" onClick={(e) => handleDashboardLink(e)}>
            Dashboard
          </Button>
        } */}
      </div>
      <div className='form-modal contest'>
        {/* {loaderA ? <FullPageLoader /> : null} */}
        <Form>
          {/* <Form.Group controlId="formFile" className="mb-4">
<Form.Label>Platform</Form.Label>
<Form.Control type="text" placeholder='Enter platform name' name='platform' value={singleVideo.platform} onChange={handleChange} />
</Form.Group> */}
          <div className='row'>
            <div className="col-xl-8">
              <div className="form-bg-wrapper mb-xl-0 mb-4">
                {loaderA ? <GamificationLoader /> : null}
                <div className='row'>
                  <div className='col-md-6 react-select-dd'>
                    <Form.Group controlId="formBasicName" className="mb-4">
                      <Form.Label className='mb-3'>Platform </Form.Label>
                      <Select
                        defaultValue={{ value: "", label: "Select Options...." }}
                        onChange={(e) => handlePlatformChange(e)}
                        value={platform}
                        options={platformList}
                        classNamePrefix="custom-select"
                      // menuIsOpen={true}
                      />
                      {/* <select name="platform" className='text-color' value={singleVideo.platform}
onChange={(e) => handleChange(e)}>
<option value="">Select Your Platform</option>
<option value="Myntist">Myntist</option>
<option value="Gamification">Gamification</option>
<option value="physical goods">physical goods</option>
<option value="NFT Marketplace">NFT Marketplace</option>
</select> */}
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group controlId="formBasicName" className="mb-4" disabled={!platform.value}>
                      <Form.Label className='mb-3'>Contest </Form.Label>
                      <Select
                        onChange={(e) => handleContestChange(e)}
                        value={contest}
                        defaultValue={{ value: "", label: "Select Options...." }}
                        isDisabled={!platform.value}
                        options={contestList}
                        classNamePrefix="custom-select"
                      />
                    </Form.Group>
                  </div>
                  {/* <div className='col-md-6'>
<Form.Group className="mb-3" controlId="formBasicName" className="mb-4">
<Form.Label>User ID </Form.Label>
<Form.Control type="text" placeholder="Enter Valid User ID" disabled name="userId" value={userID?.id} onChange={handleChange} />
</Form.Group>
</div> */}

                  <div className='col-md-12'>
                    <Form.Group controlId="formBasicDescription" className="mb-4">
                      <Form.Label>Wallet Address</Form.Label>
                      <Form.Control className='platform-wallet-address' type="text" name="walletAddress" disabled placeholder='Enter Wallet Address' value={walletAddr} onChange={handleChange} />
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
					<div className='contest-urls'>
						{singleVideo?.socialLinks?.map((link, index) => (
							<Form.Group controlId={`formBasicDescription${index}`} className="mb-4 d-flex align-items-center" key={index}>
								<Form.Label className='mb-0'>URL {index + 1}</Form.Label>
								<div className="input-group flex-1">
									<Form.Control
										className='m-0 ms-2'
										type="text"
										name="socialLink"
										placeholder="https://www.example.com"
										value={link}
										onChange={(event) => handleUrlChange(event, index)}
									/>
								</div>
								<div className={` ${index === singleVideo.socialLinks.length - 1 ? "d-inline-flex ms-2" : " m-0"}`}>
									{index === singleVideo.socialLinks.length - 1 && (
										<div className="input-group-append">
											<button className="btn plus contest-mini-btn" type="button" onClick={addURLField}>
												+
											</button>
										</div>
									)}
									{index !== 0 && (
										<div className="input-group-append">
											<button className="btn minus ms-2 contest-mini-btn" type="button" onClick={() => removeURLField(index)}>
												-
											</button>
										</div>
									)}
								</div>
							</Form.Group>
						))}
					</div>
                  </div>

                  <div className='btn-actions'>
                    <Button className='main-btn-style contest-btn' onClick={(e) => handleSubmit(e)}>
                      Save Changes
                    </Button>
                    {errors ? (<div style={{ color: "#FE6E00" }} className="alert alert-danger mt-3"> {errors} </div>) : ("")}

                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">

              {contestCard[0] && contestCard[0]?._id ?

                <div className="contest-card">
                  <div className="card-top">
                    Featured
                  </div>
                  <h4>{contestCard[0]?.name}</h4>
                  {/* <div className="points">
                    <label>Points :</label> {contestCard[0]?.points}
                  </div> */}
                  <p> {<span dangerouslySetInnerHTML={{ __html: contestCard[0]?.description }}></span>}</p>
                  {/* <div className="card-bottom"><p>Wallet Address:</p> <p>{walletAddr}</p></div> */}
                  <div className="card-bottom"><strong className='mb-2 d-inline-block'>Wallet Address:</strong>{walletAddr ?
                    <div className='wallet-address-copy d-flex align-items-center justify-content-between'>
                      <p>{walletAddr}</p>
                      <CopyToClipboard text={walletAddr}
                        onCopy={() => setCopied(true)}>
                        <div className="copy-icon"
                        // style={{
                        //     position: "absolute",
                        //     right: "20px",
                        //     cursor: "pointer",
                        // }}
                        >
                          <i className="fa fa-copy"></i>
                        </div>
                        {/* <span>Copy </span> */}
                      </CopyToClipboard>
                    </div>
                    :
                    ""}
                  </div>
                </div> :
                <div className="contest-card">
                  <div className="card-top">
                    Steps
                  </div>
                  <ul className='contest-model'>
                    <li>Select the platform first.</li>
                    <li>Select the Contest type against which you want upload the Contest.</li>
                    <li>No need to update the wallet address because it is automatically picked from your metamask.</li>
                    <li>Enter a valid URL of contest.</li>
                  </ul>
                  <p>{contestCard[0]?.description}</p>
                  <div className="card-bottom"><strong className='mb-2 d-inline-block'>Wallet Address:</strong>{walletAddr ?
                    <div className='wallet-address-copy d-flex align-items-center justify-content-between'>
                      <p>{walletAddr}</p>
                      <CopyToClipboard text={walletAddr}
                        onCopy={() => setCopied(true)}>
                        <div className="copy-icon"
                        // style={{
                        //     position: "absolute",
                        //     right: "20px",
                        //     cursor: "pointer",
                        // }}
                        >
                          <i className="fa fa-copy"></i>
                        </div>
                        {/* <span>Copy </span> */}
                      </CopyToClipboard>
                    </div>
                    :
                    ""}
                  </div>
                </div>}
            </div>

          </div>

        </Form>

      </div>
      <Promotion type='Contest Videos' />
    </>
  )
}

export default UploadContest
