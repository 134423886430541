import React,{useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";


const Login = ({ Children, ...rest }, props) => {
	let isLogin = false;
    const authUser = () => { 
		isLogin = Boolean(localStorage.getItem("WalletAddress") !== null  && localStorage.getItem("token"))
        return isLogin
    }

    return (
        <Route
			{...rest}
			render={({ location }) =>
			authUser() ? (
				( <Children  {...props}/>)
			) : (
				<Redirect
				to={{
					pathname: "/login",
					state: { from: location }
				}}
				/>
			)
			}
        />
		)
}

export default Login;