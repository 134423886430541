import { INITIAL_CHAT, READ_NOTIFICATION, GET_CHAT_NOTIFICATION, CHAT_LOADER, POST_CHAT, GET_CHAT, GET_SINGLE_CHAT, REMOVE_SINGLE_CHAT } from "../actionTypes";


const initialState = {
    chat: [],
    singleChat: [],
    notification: [],
    getChat: false,
    isRead: false,
}

const chatDetails = (state = initialState, action) => {

    switch (action.type) {
        case POST_CHAT:
            return {
                ...state,
                contest: action.payload
            }
        case GET_CHAT:
            return {
                ...state,
                chat: action.payload
            }

        case GET_SINGLE_CHAT:
            return {
                ...state,
                singleChat: action.payload,
                getChat: true
            }
        case INITIAL_CHAT:
            return {
                ...state,
                getChat: false,
                singleChat: [],
            }
        case REMOVE_SINGLE_CHAT:
            return {
                ...state,
                singleChat: action.payload,

            }
        case CHAT_LOADER:
            return {
                ...state,
                chatLoader: action.payload,

            }
        case GET_CHAT_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            }
        case READ_NOTIFICATION:
            return {
                ...state,
                isRead: true
            }

        default:
            return state;
    }
}

export default chatDetails;