
import About from "../about/about"
import Badges from "../badges/badgesEarned"
import UserStatus from "../../userStatus/status"
function SideBar() {
    return (

        <div className="left-sidebar">
            <UserStatus />
            <Badges />
            <About />
        </div>

    );
}

export default SideBar;

