import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { postEvent, postFollowersEvent, postShared } from "../../redux/user/action";
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { getQueryParam } from "../../config/helper"
import { useLocation,useHistory } from 'react-router-dom';


// import "./styles.css";

function FacebookShare(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState(props.imageUrl);
  const [media_type, setMediaType] = useState(props.media_type);
  const [postCaption, setPostCaption] = useState(props.postCaption);
  let comingSoon = getQueryParam(location, 'comingSoon')
  const [postId, setPostId] = useState(props.postId);
  // const [instaError, setInstaError] = useState('');
  const [isSharingFb, setIsSharingFb] = useState(false);
  const [shareFacebookPost, setShareFacebookPost] = useState(false);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
  const [pagePopup, setPagePopup] = useState(false);
  const [pages, setPages] = useState({ value: '', label: 'Select page' });
  const [selectedPage, setSelectedPage] = useState({ value: '', label: 'Select page' });
  const [facebookPagelist, setFbPageList] = useState(false);
  let instaError = '';
  let facebookPages = ''
  let pageSelector = false;
  useEffect(() => {
    if (props.imageUrl) {
      // let transform = transformation(props.imageUrl, "facebook")
      // if (props.imageUrl?.split("upload/")[0].includes('image')) {
      //   setImageUrl(props.imageUrl?.split("upload/")[0] + `upload/${transform}/` + props.imageUrl?.split("upload/")[1])
      // }
      // if (props.imageUrl?.split("upload/")[0].includes('video')) {
      setImageUrl(props?.imageUrl)
      // setImageUrl(props.imageUrl?.split("upload/")[0] + `upload/${transform}/` + props.imageUrl?.split("upload/")[1].split(/\.(?=[^\.]+$)/)[0] + ".jpeg")
      // }

    }

  }, [props.imageUrl])
  const handleClose = () => {
    setPagePopup(false);
    setIsSharingFb(false);
  }

  const handleTagChange = (event) => {
    setSelectedPage({ value: event.value, label: event.label })
    if (event.value.length > 0) {
    }
    else {
      toast.error("Please Select Page")
    }
  }

  const handleFacebookPageList = () => {
    if (selectedPage.value.length > 0) {
      setFbPageList(!facebookPagelist)
      setPagePopup(false)
      pageSelector = false
    }
    else {
      if (pageSelector == false) {
        toast.error('Please select at least one Facebook page!')
      }

    }

  }

  const handleErrorMessage = (message) => {
    if (instaError.length == 0) {
      instaError = message
    }
  }

  useEffect(() => {
    window?.FB?.getLoginStatus((response) => {
      setFacebookUserAccessToken(response.authResponse?.accessToken);
    });
  }, []);

  const logInToFB = () => {
    window.FB?.login(
      (response) => {
        setFacebookUserAccessToken(response.authResponse?.accessToken);
      },
      {
        scope: "publish_video,public_profile,email,user_friends,pages_manage_engagement,pages_read_engagement,pages_read_user_content,pages_manage_posts",
      }
    );
  };

  const getFacebookAccount = () => {
    return new Promise((resolve) => {
      window.FB.api(
        "/me",
        { access_token: facebookUserAccessToken },
        (response) => {
          if (response?.error) {
            if (response.error.error_user_msg) {
              handleErrorMessage(response.error.error_user_msg)
            }
            else {
              handleErrorMessage('Facebook Error!')
            }
            resolve(response);
          }
          else {
            resolve(response);
          }
        }
      );
    });
  };
  const getFacebookPages = () => {
    return new Promise((resolve) => {
      window.FB.api(
        "/me/accounts",
        { access_token: facebookUserAccessToken },
        (response) => {
          if (response?.error) {
            if (response.error.error_user_msg) {
              handleErrorMessage(response.error.error_user_msg)
            }
            else {
              handleErrorMessage('Facebook Error!')
            }
            resolve(response);
          }
          else {
            if (response.data.length > 0) {
              let tagsOptions = []
              if (response.data) {
                for (let i = 0; i < response?.data?.length; i++) {
                  tagsOptions.push({ value: response?.data[i].id, label: response?.data[i].name })
                }
                setPages(tagsOptions)
              }
              if (response.data.length > 1) {
                setSelectedPage({ value: '', label: 'Select page' })
                setPagePopup(true)
              }
              else {
                resolve(response);
                pageSelector = true
                handleFacebookPageList()
              }
              resolve('');
            }

          }
        }
      );
    });
  };
  const getPagesAccess = (access) => {
    if (access !== 0) {
      return new Promise((resolve) => {
        window.FB.api(
          `${access}?fields=access_token`,
          { access_token: facebookUserAccessToken },
          (response) => {
            if (response?.error) {
              if (response.error.error_user_msg) {
                handleErrorMessage(response.error.error_user_msg)
              }
              else {
                handleErrorMessage('Facebook Error!')
              }
              resolve(response);
            }
            else {
              resolve(response);
              // setInstaError(true)
            }
          }
        );
      });
    }
    else {
      handleErrorMessage('Facebook Page not found!')
    }

  };
  const postOnFacebookPage = (pageId, access) => {
    if (access !== '' || pageId !== 0) {
      if (media_type == 'video' || media_type == 'gif') {
        return new Promise((resolve) => {
          window.FB.api(
            `/${pageId}/videos?file_url=${encodeURIComponent(imageUrl)}&title=${postCaption}&access_token=${access}`,
            "POST",
            // { access_token: facebookUserAccessToken},
            (response) => {
              if (response?.error) {
                if (response.error.error_user_msg) {
                  handleErrorMessage(response.error.error_user_msg)
                }
                else {
                  handleErrorMessage('Facebook Error!')
                }
                resolve(response);
              }
              else {
                resolve(response);
                // setInstaError(true)
              }
            }
          );
        });
      } else {
        return new Promise((resolve) => {
          window.FB.api(
            `/${pageId}/photos?url=${encodeURIComponent(imageUrl)}&message=${postCaption}&access_token=${access}`,
            "POST",
            // { access_token: facebookUserAccessToken},
            (response) => {
              if (response?.error) {
                if (response.error.error_user_msg) {
                  handleErrorMessage(response.error.error_user_msg)
                }
                else {
                  handleErrorMessage('Facebook Error!')
                }
                resolve(response);
              }
              else {
                resolve(response);
                // setInstaError(true)
              }
            }
          );
        });
      }
    }
    else {
      handleErrorMessage('Facebook page or Access token Error!')
    }
  };
  const getFacebookFriends = (accountId) => {
    return new Promise((resolve) => {
      window.FB.api(
        `/${accountId}/friends`,
        { access_token: facebookUserAccessToken },
        (response) => {
          if (response?.error) {
            if (response.error.error_user_msg) {
              handleErrorMessage(response.error.error_user_msg)
            }
            else {
              handleErrorMessage('Facebook Error!')
            }
            resolve(response);
          }
          else {
            resolve(response);
            // setInstaError(true)
          }
        }
      );
    });
  };

  const shareInstagramPost = async () => {
    setSelectedPage({ value: '', label: 'Select page' })
    setShareFacebookPost(true)
    logInToFB()
  };

  useEffect(async () => {
    if (facebookUserAccessToken && shareFacebookPost) {
      setIsSharingFb(true);
      const facebookAccount = await getFacebookAccount();
      const facebookFriends = await getFacebookFriends(facebookAccount.id);
      if (selectedPage?.value?.length == 0) { facebookPages = await getFacebookPages(); }
      if (selectedPage.value.length > 0 || facebookPages?.data[0].id) {
        const pagesAccess = await getPagesAccess(selectedPage?.value.length > 0 ? selectedPage.value : facebookPages?.data[0].id ? facebookPages?.data[0].id : 0);
        if (pagesAccess === undefined) {
          setIsSharingFb(false)
          // toast.error(instaError)
        } else {
          const postPage = await postOnFacebookPage(selectedPage?.value.length > 0 ? selectedPage.value : facebookPages?.data[0].id ? facebookPages?.data[0].id : 0, pagesAccess.access_token ? pagesAccess.access_token : '');
        }
        if (instaError && instaError.length > 0) {
          toast.error(instaError)
          instaError = ''
        } else {
          // let folloerData = {
          //   friendsCount: facebookFriends.summary.total_count,
          //   userId: localStorage.getItem("WalletAddress"),
          //   socialPlatform: 'facebook'
          // }
          // let sharedData = {
          //   postId: postId,
          //   walletAddress: localStorage.getItem("WalletAddress"),
          //   shared: true
          // }
          toast.success('Post is shared to Facebook Successfully!')
          if(comingSoon){
            let data = {
              eventName: "redeeming",
              userId: localStorage.getItem("WalletAddress"),
            }
            dispatch(postEvent(data))
            history.push('/daily-rewards?route=Daily Claim')
          }
          // dispatch(postShared(sharedData))
          // dispatch(postFollowersEvent(folloerData))
        }
        setIsSharingFb(false);
        setShareFacebookPost(false)
      }
    }
    else {
      setIsSharingFb(false);
    }
  }, [facebookUserAccessToken, facebookPagelist])
  return (
    <>
      {pagePopup &&
        <div className='swal-model'>
          <Modal centered className="custom-modal connect-wallet curr-wallet fb-model" show={pagePopup} onHide={handleClose} >
            <Modal.Body>
              <div className='position-relative'>
                <div className='mb-3 modal-main-body'>
                  <Modal.Title className='text-start'>SHARE ON SOCIAL MEDIA</Modal.Title>
                </div>
                <Button className="connect-btn btn ms-2" onClick={handleClose}>
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              </div>
              <div className="select-content pt-2">
                <Select
                  classNamePrefix="custom-select"
                  onChange={(e) => handleTagChange(e)}
                  options={pages}
                  value={selectedPage}
                  search
                  className="fb-insta-select"

                />
                <Button variant="" onClick={() => { handleFacebookPageList() }} className=' main-btn-style select-save'>Ok</Button>
              </div>
            </Modal.Body>
          </Modal>
        </div >
      }<button
        onClick={() => shareInstagramPost()}
        className="btn action-btn facebook"
        disabled={isSharingFb}
      >
        <FontAwesomeIcon className={isSharingFb ? 'me-sm-1 sharing-grow spinner-grow' : "me-sm-1"} icon={faFacebookF} />
        <span className='d-sm-block d-none'>
          {isSharingFb ? "Sharing..." : "Facebook"}
        </span>
      </button>
    </>
  );
}

export default FacebookShare;