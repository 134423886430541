import axios from "axios";
import { ENV } from '../config/config';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert2';
import { toast } from "react-toastify";
let baseUrl = ENV.serverUrl
let liveBaseUrl = ENV.liveServerUrl
// let liveBaseUrl = "https://hex-test.arhamsoft.org/v1/gamification"

// let wpTemplateBaseUrl = "https://treasurebox.myntist.com/wp-json/wp/v2"
let wpTemplateBaseUrl = "http://localhost/hexwp/wp-json/wp/v2"
async function apiHelper(apiType, path, data, token) {
    if (baseUrl === undefined || !baseUrl) {
        baseUrl = ""
    }

    // let encToken
    // if (token.length > 0) {
    //     encToken = ENV.encryptToken(token)
    // }
    // const xauthtoken = JSON.parse(localStorage.getItem("token"))
    const enc_token = ENV.encryptToken(localStorage.getItem("token")) 
    if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
        try {
            let response = await axios({
                method: apiType,
                url: `${baseUrl + path}`,
                data,
                headers: {
                    // token: enc_token,
                    'Authorization': 'Bearer ' + enc_token
                    // 'x-access-token': xauthtoken,
                    // 'x-auth-token': ENV.x_auth_token
                }
            })

            return response
        } catch (error) {
            console.log(error)
            let errorStatus = error.response.status;
            if (errorStatus === 500 || errorStatus === 502 || errorStatus === 503 || errorStatus === 504) {
                swal.fire({
                    title: 'Server Error',
                    text: "Oops Something Went Wrong, Try to refresh this page..",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }else if(errorStatus === 401){
                toast.error("Token invalid or expired please login again")
                setTimeout(() => {
                    ENV.logout();
                }, 2000);
            }
        }
    }
}

async function apiHelperLive(apiType, path, data) {
    if (liveBaseUrl === undefined || !liveBaseUrl) {
        liveBaseUrl = ""
    }
    if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
        try {
            let response = await axios({
                method: apiType,
                url: `${liveBaseUrl + path}`,
                data,
                headers: {
                    'Authorization': `${ENV.Authorization}`,
                    'x-auth-token': ENV.x_auth_token
                }
            })
            return response
        } catch (error) {
            if (error.response) {
                return error.response;
            }
            else if (error.responce.status === 500 || error.responce.status === 502 || error.responce.status === 503 || error.responce.status === 504) {
                swal.fire({
                    title: 'Server Error',
                    text: "Oops Something Went Wrong, Try to refresh this page..",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }
}
async function apiHelperForTemplate(apiType, path, data) {

    if (wpTemplateBaseUrl === undefined || !wpTemplateBaseUrl) {
        wpTemplateBaseUrl = ""
    }
    if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
        try {
            // let response = fetch(`${wpTemplateBaseUrl + path}`)
            //     .then(response => response.json())
            //     .then(data => console.log(data));


            const response = await fetch(`${wpTemplateBaseUrl + path}`, {
                method: apiType, // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors', // no-cors, *cors, same-origin
                // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsIm5hbWUiOiJhZG1pbiIsImlhdCI6MTY1NjkzNjI4NCwiZXhwIjoxODE0NjE2Mjg0fQ.O5Tu3zonBSx6leMIRROEuSJt0UkolqfYoTzk2kkpy2s`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },

                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });





            // let response = await axios({
            //     method: apiType,
            //     url: `${wpTemplateBaseUrl + path}`,
            //     mode: 'no-cors',
            //     headers: {
            //         'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsIm5hbWUiOiJ0cmVhc3VyZWJveCIsImlhdCI6MTY1NTc5OTM5MSwiZXhwIjoxODEzNDc5MzkxfQ.Wf6YT7vGLGnbPOvKfpr0AYb42K9L7vAjW_44X6zRY1U`,
            //         'Access-Control-Allow-Origin': '*',
            //         'Content-Type': 'application/json',
            //     },
            //     withCredentials: true,
            //     credentials: 'same-origin',
            // })
            // return response
        } catch (error) {
            let errorStatus = error.responce.status;
            if (errorStatus === 500 || errorStatus === 502 || errorStatus === 503 || errorStatus === 504) {
                swal.fire({
                    title: 'Server Error',
                    text: "Oops Something Went Wrong, Try to refresh this page..",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }
            // toast.error(error.response.data.message)
        }
    }
}
async function apiMultipart(apiType, path, data) {
    if (wpTemplateBaseUrl === undefined || !wpTemplateBaseUrl) {
        wpTemplateBaseUrl = ""
    }
    if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
        try {
            let response = await axios({
                method: apiType,
                url: `${wpTemplateBaseUrl + path}`,
                data,
                headers: {
                    'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsIm5hbWUiOiJ0cmVhc3VyZWJveCIsImlhdCI6MTY1NTc5OTM5MSwiZXhwIjoxODEzNDc5MzkxfQ.Wf6YT7vGLGnbPOvKfpr0AYb42K9L7vAjW_44X6zRY1U`,
                }
            })
            return response
        } catch (error) {
            let errorStatus = error.responce.status;
            if (errorStatus === 500 || errorStatus === 502 || errorStatus === 503 || errorStatus === 504) {
                swal.fire({
                    title: 'Server Error',
                    text: "Oops Something Went Wrong, Try to refresh this page..",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }
            // toast.error(error.response.data.message)
        }
    }
}

export { apiHelper, apiHelperLive, apiHelperForTemplate, apiMultipart };
