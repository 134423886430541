import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAccount, useDisconnect } from "wagmi";
import { RegisterUser, postEvent } from "../../redux/user/action"
import { setWalletProvider, setCurrentUser, setWalletLoader, clearCurrentUser } from '../../redux/walletProviders/action'
import {
    useConnectModal,
} from '@rainbow-me/rainbowkit';
import GamificationLoader from '../gamificationLoader/GamificationLoader';

function LoginWithRainbow() {
    const dispatch = useDispatch();
    const { disconnect } = useDisconnect()
    const { openConnectModal } = useConnectModal();
    const { address: userAddress, isConnected } = useAccount();
    let walletAdrress = useSelector(state => state?.walletProvider?.currentUser);
    let loaderA = useSelector(state => state?.walletProvider?.walletLoader);
    let history = useHistory();
    let { code } = useParams();
    const [login, setLogin] = useState(false);
    const handleLogin = async (walletType) => {
        try {
            dispatch(setWalletProvider(walletType));
            disconnect();
            localStorage.setItem("logout", false);
            setLogin(true);
            openConnectModal();
        } catch (err) {
            console.log("Errr", err)
        }
    }

    useEffect(() => {
        dispatch(setWalletProvider(0));
        if (localStorage.getItem("logout") === "true") {
            console.log("User LoggedOut but localstorage not have data: ",)
            dispatch(clearCurrentUser());
            // localStorage.setItem("logout", true);
            disconnect();
            localStorage.removeItem('token');
            localStorage.removeItem("twitter_user_secret")
            localStorage.removeItem("twitter_user_token")
            // localStorage.removeItem('userInfo');
            localStorage.removeItem('WalletAddress')
        }
    }, [])

    useEffect(async () => {
        // dispatch(setWalletLoader(true)); 
        const Register = async (_address) => {
            const data = {
                address: _address,
                inviteeCode: code ? code : ""
            }
            let res = await dispatch(RegisterUser(data, history))
            let eventName = '';
            if (res) {
            if(res?.newUser){
                    let data = {
                        eventName: "register",
                        userId: _address,
                        platform: 'gamification',
                    }
                    dispatch(postEvent(data))
                }
                dispatch(setWalletLoader(false));
                
                if (res?.referrerInfo?.refsCount) {
                    let refsCount = res?.referrerInfo?.refsCount
                    if (refsCount >= 100 && refsCount < 500) {
                        eventName = '100-500 people';
                    }
                    else if (refsCount >= 500 && refsCount < 1000) {
                        eventName = '500-1000 people';
                    }
                    else if (refsCount >= 1000 && refsCount < 10000) {
                        eventName = '1000-10000 people';
                    }
                    else if (refsCount >= 10000 && refsCount < 50000) {
                        eventName = '10000-50000 people';
                    }
                    else if (refsCount >= 50000 && refsCount < 100000) {
                        eventName = '50000-100000 people';
                    }
                    else if (refsCount >= 100000 && refsCount < 1000000) {
                        eventName = '100000-1000000 people';
                    }
                    let eventData = {
                        eventName,
                        userId: res?.referrerInfo?.address
                    }
                    let eventRes = dispatch(postEvent(eventData))
                }
            }
        }
        if (userAddress && isConnected && (localStorage.getItem("logout") === "false")) {
            dispatch(setWalletLoader(true));
            await Register(userAddress);
            dispatch(setCurrentUser(userAddress));
            history.push(`/dashboard?address=${userAddress}`)
        }
        else if (
            localStorage.getItem("WalletAddress") !== null && localStorage.getItem("token") !== "" && localStorage.getItem("logout") === "false"
        ) {
            let userAdd = localStorage.getItem("WalletAddress")
            history.push(`/dashboard?address=${userAdd}`)
        }
    }, [userAddress, isConnected]);

    useEffect(() => {
        if (walletAdrress !== null && walletAdrress !== undefined) {
            dispatch(setWalletLoader(false));
            // history.push(`/dashboard?address=${walletAdrress}`)
        }
    }, [walletAdrress])


    return (
        <div className='login-wrap pt-5'>
            <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-7">
                    <div className="intro text-center text-white">
                        <h2 className="mb-3">Connect your Wallet</h2>
                        <p className="mb-0">Please select the wallet you want to connect to.</p>
                    </div>
                    {loaderA ? <GamificationLoader /> : null}
                </div>
            </div>
            <div className="row justify-content-center items"  >
                <div className="col-12 col-md-9 col-xl-5 col-lg-6">
                    <div className="card single-wallet whole-hover" onClick={() => handleLogin(1)}>
                        <span className="meta-tag" href="/login">
                            <div className='wallet-img'>
                                <img src="/images/metamask.png" alt="Metamask" className="img-fluid" />
                            </div>
                            <h4 className="wallet-h4">MetaMask</h4>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center items"  >
                <div className="col-12 col-md-9 col-xl-5 col-lg-6">
                    <div className="card single-wallet whole-hover" onClick={() => handleLogin(2)}>
                        <span className="meta-tag" href="/login">
                            <div className='wallet-img'>
                                <img src="/images/coin-base-wallet.png" alt="coin-base" className="img-fluid" />
                            </div>
                            <h4 className="wallet-h4">Coinbase</h4>
                        </span>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center items"  >
                <div className="col-12 col-md-9 col-xl-5 col-lg-6">
                    <div className="card single-wallet whole-hover" onClick={() => handleLogin(3)}>
                        <span className="meta-tag" href="/login">
                            <div className='wallet-img'>
                                <img src="/images/wallet-connect.png" alt="wallet" className="img-fluid" />
                            </div>
                            <h4 className="wallet-h4">Wallet Connect</h4>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginWithRainbow;