
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Button, Table, Form, Modal } from 'react-bootstrap';
import Dummy from '../../assets/images/logo.svg'
import { useDispatch, useSelector } from 'react-redux';
import { apiHelper, apiMultipart, apiHelperForTemplate } from "../../redux/apiHelper"
import PlaceholderImg from '../../assets/images/placeholder-copy.png';
import { multipart } from "../../redux/treasureBox/action"
import { toast } from 'react-toastify';
const servBaseuRL = process.env.REACT_APP_MEDIA_URL
const ImagePickerIndex = (props) => {
    let imgId = useSelector(state => state?.treasureBoxData?.rec_image_id?.result?.id)
    const dispatch = useDispatch()
    const [thumbnail, setThumbnail] = useState("")
    const [featured_media, setFeatured_media] = useState("")
    var formData = new FormData();
    let result = ""

    const handleChangeimage = async (event) => {
        if (event.target.files.length > 0) {
            // props.setAvatar(event.target.files[0])
            // props.setAvatarName(event.target.files[0].name)
            formData.append("file", event.target.files[0])
        }
        dispatch(multipart(formData))
        props.setAvatar(imgId)
    }
    // useEffect(() => {
    //     if (imgId) {
    //         props.setAvatar(imgId)
    //     }
    // }, [imgId])

    return (
        <Form.Group controlId="formFile" className="mb-4">
            <Form.Label> {thumbnail}</Form.Label>
            <div className="upload-wrapper">
                <div className="upload-img">
                    <img src={Dummy} className="img-fluid " />
                </div>
                <div className="upload-camera"><span className="fa fa-camera"></span></div>
                <Form.Control type="file" name="image" accept="image/jpeg, image/png" onChange={(e) => handleChangeimage(e)} />
            </div>
        </Form.Group>
    )
}

const ImagePicker = (props) => {
    let imgId = useSelector(state => state?.treasureBoxData?.rec_image_id?.result?.id)
    const dispatch = useDispatch()
    const [thumbnail, setThumbnail] = useState("")
    const [featured_media, setFeatured_media] = useState(Dummy)
    var formData = new FormData();
    let result = ""


    const handleChangeimage = async (event) => {
        if (!event.target.files[0].type.startsWith('image/') || event.target.files[0].type==='image/gif' ) {
            toast.error('Please upload an image file');
            setFeatured_media(Dummy)
        }
        else{
        setFeatured_media(URL.createObjectURL(event.target.files[0]))
        let timestamp = Date.now();
        if (event.target.files.length > 0) {
            formData.append('currentTimestampUnix', timestamp)
            formData.append("file", event.target.files[0])
        }
        if (props?.setImageLoader && props?.setImageLoader !== undefined){
            props?.setImageLoader(true);
        }
        let resImg = await dispatch(multipart(formData))
        if (props?.setImageLoader && props?.setImageLoader !== undefined){
            props?.setImageLoader(false);
        }
        // props.setAvatarName(timestamp + '_' + event.target.files[0].name)
        props.setAvatarName(resImg?.data?.data?.filename ? resImg?.data?.data?.filename : event.target.files[0].name)
        if (props?.setAvatar && props?.setAvatar !== undefined) {
            props.setAvatar(resImg?.data?.data?.filename ? resImg?.data?.data?.filename : event.target.files[0].name)
        }
        if (props?.setSaveChanges && props?.setSaveChanges !== undefined) {
            props.setSaveChanges(true)
        }
    }

        // props.setAvatar(imgId)
        // if(props.formType == 'main'){
        //     props.handleFormChange(props.index,event)
        // }else if(props.formType == 'sub'){
        //     props.handleSubFormChange(props.index,props.subIndex,event)
        // }else if(props.formType == 'basic'){
        //     // props.setAvatar(event.target.files[0])
        //     props.setAvatarName(event.target.files[0].name)
        // }

    }
    useEffect(() => {
        // if (imgId) {
        props.setAvatar(1)//imgId
        // }
    }, [imgId])

    return (
        <Form.Group controlId="formFile" className="mb-4 h-100">
            {/* <Form.Label> {thumbnail}</Form.Label> */}
            <div className="upload-wrapper">
                <div className="upload-img">
                    <img src={props.image && featured_media == Dummy ? props.image == '../../assets/images/placeholder-copy.png' ? PlaceholderImg : servBaseuRL + props.image : featured_media} className="img-fluid " />
                </div>
                <div className="upload-camera"><span className="fa fa-camera"></span></div>
                <Form.Control type="file" name='image' accept="image/jpeg, image/png" onChange={(e) => handleChangeimage(e)} />
            </div>
        </Form.Group>
    )
}
const GifPicker = (props) => {
    let imgId = useSelector(state => state?.treasureBoxData?.rec_image_id?.result?.id)
    const dispatch = useDispatch()
    const [thumbnail, setThumbnail] = useState("")
    const [featured_media, setFeatured_media] = useState("")
    var formData = new FormData();
    let result = ""

    const handleChangeimage = async (event) => {
        props.setButtonLoader(false)
        setFeatured_media(URL.createObjectURL(event.target.files[0]));
        let timestamp = Date.now();
        if (event.target.files.length > 0) {
            // props.setAvatar(event.target.files[0])
            // props.setAvatarName(event.target.files[0].name)
            formData.append('currentTimestampUnix', timestamp)
            formData.append("file", event.target.files[0])
        }
        dispatch(multipart(formData))
        // props.setGifName(timestamp + '_' + event.target.files[0].name)
        props.setGifName(event.target.files[0].name)
        props.setButtonLoader(true)
    }
    useEffect(() => {
        // if (imgId) {
        props.setGif(1)//imgId
        // }
    }, [imgId])

    return (
        <Form.Group controlId="formFile" className="mb-4">
            <Form.Label> {thumbnail}</Form.Label>
            <div className="upload-wrapper">
                <div className="upload-img">
                    <img src={props.giff ? servBaseuRL + props.giff : featured_media} className="img-fluid " />
                </div>
                <div className="upload-camera"><span className="fa fa-camera"></span></div>
                <Form.Control type="file" name="my-image" id="gif" accept="image/gif" onChange={(e) => handleChangeimage(e)} />
            </div>
        </Form.Group>
    )
}
const Selectt = (props) => {

    // const category = props.category;
    // const [categoriesOptionList, setCategoriesOptionList] = useState({
    //     value: 'Select the category..',
    //     label: 'Select the category..'
    // })

    // const getUpdatedCategories = async () => {

    //     let categoriesOption = [];
    //     let name = [];

    //     for (let i = 0; i < cat.length; i++) {
    //         categoriesOption.push({ value: cat[i]._id, label: cat[i].name });
    //     }

    //     setCategoriesOptionList(categoriesOption);

    // }
    // useEffect(() => {
    //     getUpdatedCategories();
    // }, [])

    return (<Form.Group className="mb-3" aria-label="Default select example">
        <Select
            defaultValue={{ value: "Select Options....", label: "Select Options...." }}
            onChange={(e) => props.setCategory({ value: e.value, label: e.label })}
            value={props.category}
            options={props.categoryList}
            classNamePrefix="custom-select"
        />
    </Form.Group>)
}
const Field = (props) => {
    return (<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Control type="text" placeholder="Title" name="platform" value={props.title} onChange={(e) => props.setTitle(e.target.value)} />
    </Form.Group>)
}
const TextArea = (props) => {
    return (<Form.Group className="mb-3" controlId="formBasicDescription">
        <Form.Control as="textarea" rows="4" type="text" name="description" placeholder={props.placeholder} value={props.editorVal} onChange={(e) => { props.setDescription(e.target.value) }} />
    </Form.Group>)
}

export { ImagePicker, Field, Selectt, TextArea, GifPicker, ImagePickerIndex };