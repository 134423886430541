import React, { useEffect, useState } from "react"
import Popup from "../../../popup/popup"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom"
import { RegisterUser } from "./../../../../redux/user/action"
import { getQueryParam } from './../../../../config/helper'
import { ENV } from "../../../../config/config";


const Slicker = (props) => {

  const dispatch = useDispatch();
  let location = useLocation();

  let searchParams = new URLSearchParams(location.search)
  let code = getQueryParam(location, 'code')
  let address = searchParams.get('address')
  let userId = searchParams.get('userId')
  let promotions = useSelector(state => state?.promotions?.promotion);
  let sliderPromotions = useSelector(state => state?.promotions?.promotions);
  useEffect(() => {
    Register()
  }, [sliderPromotions])

  const Register = async (ref) => {
    const data = {
      name: "",
      image: ""
    }
    data.inviteeCode = code ? code : ""
    data.address = address
    data.userId = userId

    // dispatch(RegisterUser(data))
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  }

  return (
    <>
      <Popup data={promotions} />
      {sliderPromotions && sliderPromotions.length > 0 ?

        <div className="section-dark-bg text-center ">
          <> <div className="section heading">
            <h5 className=''>Promotions</h5>
          </div>
            <Slider {...settings}>
              {sliderPromotions?.map((promo) => {
                return (
                  <div className="promotion-wrapper">
                    <div className="promotion-img">
                      <img alt="" className="img-fluid" src={ENV.media_url + promo.image}></img>
                      <div className="promotion-description">
                        <p><h3>{promo.description}</h3></p>
                        <a className="btn-gradient-blue" href={promo.url}>Click here!</a>
                      </div>
                    </div>

                  </div>
                )
              })}
            </Slider></>

        </div> : ""}
    </>
  )
}

export default Slicker