import { SET_LOADER, GET_REQUESTS } from "../actionTypes";

const initialState = {
    loaderActive: false
}
const popupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REQUESTS:
            return {
                ...state,
                requests: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload
            }

        default:
            return state;
    }
}
export default popupReducer;