import { POST_VIDEOS, GET_CONTEST, SET_LOADER, POST_EVENT } from "../actionTypes";
import { apiHelper } from "../apiHelper";
import { ENV } from './../../config/config';
import { toast } from 'react-toastify';

let token = localStorage.getItem("token")
let addressForLiveApi = "0x5241b6b1082080C7f103d3C5CA5EAe55eCEA3293"
let myDummyAddress = "0x0297209262AA172478b4B5A54b46A7d1cC77a80B"
let encToken = ENV.encryptToken(token)

export const postVideo = (data) => async (dispatch) => {
    try {
        let enc = ENV.encryptPayload(data)

        let data2 = { enc: enc }
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        const res = await apiHelper("post", `/v1/request`, data2, encToken);
        if (res?.data) {
            dispatch({
                type: POST_VIDEOS,
                payload: res.data
            });
            toast.success("Your request has been submitted for approval. Once approved you will receive points.");
            dispatch({
                type: SET_LOADER,
                payload: false
            });
        }
    } catch (error) {
        console.log(error.message)
        dispatch({
            type: SET_LOADER,
            payload: false
        });
        toast.error("Something went wrong!");
    }
}
export const getContest = () => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        let res = await apiHelper("get", `/v1/contest`, "", encToken);
        if (res?.data) {
            dispatch({
                type: GET_CONTEST,
                payload: res.data
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            });
        }

    } catch (error) {
        console.log(error.message)
    }
}

export const postEvent = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        let enc = ENV.encryptPayload(data)

        let data2 = { enc: enc }
        const res = await apiHelper("post", `/v1/users/event`, data2, encToken);
        if (res?.data) {
            dispatch({
                type: POST_EVENT,
                payload: res.data
            });
            dispatch({
                type: SET_LOADER,
                payload: false
            });
        }
        else {
            console.log("error in response")
        }

    } catch (error) {

        console.log(error.message)
    }
}