import React, { useEffect } from 'react';
import './GamificationLoader.css';
import Logo  from '../../assets/images/logo.svg';



function GamificationLoader() {
    // useEffect(() => {
    //     AOS.init();
    // }, []);

    return (
        <React.Fragment>
            <div className="fullpage-loader-holder d-flex justify-content-center align-items-center">
                <div className='img-loader'>
                    <img className="img-fluid" src={Logo} alt="Full page loader" />
                </div> 
            </div>
        </React.Fragment>
    );
};

export default GamificationLoader;


