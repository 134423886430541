import { CLEAR_TREASUREBOX_DATA,GET_TREASUREBOXES_COUNT, SET_LOADER,SET_TRENDING_POSTS, SET_COMMENTS, ANSWERS_UPDATED, POST_UPDATED, UPLOAD_VIDEO, SET_POST, POST_CONTENT, GET_CATAGORIES, GET_TAGS, RECENT_IMG_UPLOAD, GET_TREASUREBOXES, UPDATE_TREASUREBOXVIEWS } from "../actionTypes";
import { apiHelper } from "../apiHelper";
import { toast } from "react-toastify";

export const postTreasureBox = (data) => async (dispatch) => {
    try {
        let res = await apiHelper("post", `/v1/treasurebox/upload/tbox`, data);

        if (res.data) {
            dispatch({
                type: POST_CONTENT,
                payload: res.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}
export const createPersonality = (data) => async (dispatch) => {
    try {
        let res = await apiHelper("post", `/v1/treasurebox/upload/tbox/${data.type}`, data.formData);
    } catch (error) {
        console.log(error.message);
    }
}
export const uploadVideo = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("post", `/v1/treasurebox/upload/video`, data);
        if (res.data) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            dispatch({
                type: UPLOAD_VIDEO,
                payload: res.data.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
        dispatch()
    } catch (error) {
        console.log(error.message);
    }
}
export const updatePersonality = (data) => async (dispatch) => {
    try {
        let res = await apiHelper("put", `/v1/treasurebox/upload/tbox/${data.type}`, data.formData);
    } catch (error) {
        console.log(error.message);
    }
}

export const getCategories = (data, type) => async (dispatch) => {
    try {
        let res = await apiHelper("get", `/v1/category`, "");
        if (res.data) {
            dispatch({
                type: GET_CATAGORIES,
                payload: res.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getTags = (data, type) => async (dispatch) => {
    try {
        let res = await apiHelper("get", `/v1/tags`, "");
        if (res.data) {
            dispatch({
                type: GET_TAGS,
                payload: res.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}
export const multipart = (data, type) => async (dispatch) => {
    try {
        let res = await apiHelper("post", `/v1/treasurebox/media`, data);
        if (res.data) {
            dispatch({
                type: RECENT_IMG_UPLOAD,
                payload: res.data
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getList = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/treasurebox/?category=${data.category}&tempType=${data.temType}&offset=${data.offset}&limit=${data.limit}`, "");

        if (res.data) {
            dispatch({
                type: GET_TREASUREBOXES,
                payload: res.data.TreasureBox
            })
            dispatch({
                type: GET_TREASUREBOXES_COUNT,
                payload: res.data.TreasureBoxCount
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}


export const multipartStateless = (data, type) => async (dispatch) => {
    try {
        let res = await apiHelper("post", `/v1/treasurebox/media`, data);
        if (res.data) {
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error.message);
    }
}


export const updateTreasureBoxLikes = (id, data) => async (dispatch) => {
    try {
        let res = await apiHelper("put", `/v1/treasurebox/rate/${id}`, data);
        if (res.data) {
            dispatch({
                type: POST_UPDATED
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error)
    }
}

export const getSinglePost = (postId) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/treasurebox/post/${postId}`, "")

        if (res) {
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: SET_POST,
                    payload: data.TreasureBox
                })
                dispatch({
                    type: SET_COMMENTS,
                    payload: data.comments
                })
                dispatch({
                    type: SET_TRENDING_POSTS,
                    payload: data.trendingPosts
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }

        }

    } catch (error) {
        console.log(error)
    }
}

export const updateTreasureBoxViews = (id, data) => async (dispatch) => {
    try {
        let res = await apiHelper("put", `/v1/treasurebox/view/${id}`, data);
        if (res.data) {
            dispatch({
                type: UPDATE_TREASUREBOXVIEWS,
                payload: res.data.views
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error)
    }
}

export const updateTreasureBoxAnswers = (id, data) => async (dispatch) => {
    try {
        let res = await apiHelper("put", `/v1/treasurebox/answer/${id}`, data);
        if (res.data) {
            dispatch({
                type: ANSWERS_UPDATED
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    } catch (error) {
        console.log(error)
    }
}

export const clearTreasureboxData = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_TREASUREBOX_DATA
        })
    } catch (error) {
        console.log(error.message);
    }
}

export const postTreasureBoxComment = (id,data) => async(dispatch) => {
    try{
        let res = await apiHelper("post", `/v1/treasurebox/comment/${id}`, data);
        if (res.data) {
            dispatch({
                type: POST_UPDATED
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    }catch (error){
        console.log(error.message)
    }
}

export const updateTreasureBoxComment = (id,data) => async(dispatch) => {
    try{
        let res = await apiHelper("put", `/v1/treasurebox/update/comment/${id}`, data);
        if (res.data) {
            dispatch({
                type: POST_UPDATED
            })
            return res;
        }
        else {
            toast.error("Error in response");
        }
    }catch (error){
        console.log(error.message)
    }
}