import { SHOW_ERR_POPUP, SHOW_NOTIFICATION_POPUP, SET_UNREAD_NOTIFICATIONS, POST_READ, SET_NFTLOADER, NFT_SOLD_LOADER, POST_EVENT, NFT_CREATED, NFT_URL, SET_UNSEENEVENTS, SET_EVENT, SET_NOTIFICATIONS, SET_REFERRALS, UPDATE_PROFILE, GET_REWARDS_HISTORY, UPDATE_GAMEPROFILE, UPDATE_USER, PROF_COMPLETENESS, GET_STATS, SET_EVENT_HISTORY, SET_LOADER, EDIT_STATUS, GET_STATUS, GET_USERNFTS, GET_LEADERBOARD, GET_NFTS, GET_ACTIVITIES,ALL_ACTIVITIES, GET_OFFERS, GET_COLLECTIONS, GET_COLLECTIBLES, GET_ABOUT, POINTS_DATA, REGISTER } from '../actionTypes';

const initialState = {
    user: {},
    nftCreated: false,
    success: false,
    // loaderActive: false,
    eventHistory: [],
    // loaderActive: true,
    userStats: [],
    nftLoader: true,
    nftData: {},
    nfts: [],
    loader: true,
    nftSoldLoader: "value",
    postevent: '',
    showNotificationPopup: false,
    showdynamicPopup: false
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER:
            return {
                ...state,
                profile: action.payload,
            }
        case SET_REFERRALS:
            return {
                ...state,
                referrals: action.payload,
            }

        case GET_STATUS:
            return {
                ...state,
                profile: action.payload,
                success: false,
                // loaderActive: false
            }
        case UPDATE_USER:
            return {
                ...state,
                profile: action.payload,
            }
        case GET_LEADERBOARD:
            return {
                ...state,
                user: action.payload
            }
        case SHOW_NOTIFICATION_POPUP:
            return {
                ...state,
                showNotificationPopup: action.payload
            }
        case SHOW_ERR_POPUP:
            return {
                ...state,
                showdynamicPopup: action.payload
            }
        case SET_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                unreadNotifications: action.payload
            }
        case GET_NFTS:
            return {
                ...state,
                nfts: action.payload,
                myLoader: false
            }
        case SET_NFTLOADER:
            return {
                ...state,
                nftLoader: false
            }
        case NFT_SOLD_LOADER:
            return {
                ...state,
                nftSoldLoader: action.payload
            }

        case GET_ACTIVITIES:
            return {
                ...state,
                activities: action.payload
            }
        case ALL_ACTIVITIES:
            return {
                ...state,
                allActivities: action.payload
            }
        case GET_OFFERS:
            return {
                ...state,
                offers: action.payload
            }
        case GET_COLLECTIONS:
            return {
                ...state,
                collections: action.payload
            }
        case GET_COLLECTIBLES:
            return {
                ...state,
                collectibles: action.payload
            }
        case GET_ABOUT:
            return {
                ...state,
                about: action.payload
            }
        case EDIT_STATUS:
            return {
                ...state,
                profile: action.payload,
                success: true
            }
        case POST_READ:
            return {
                ...state,
                postRead: action.payload
            }
        case POINTS_DATA:
            return {
                ...state,
                points: action.payload
            }
        case GET_USERNFTS:
            return {
                ...state,
                userNfts: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload,
            }
        case SET_EVENT_HISTORY:
            return {
                ...state,
                eventHistory: action.payload
            }
        case GET_STATS:
            return {
                ...state,
                userStats: action.payload
            }
        case PROF_COMPLETENESS:
            return {
                ...state,
                profCompleteness: action.payload
            }
        case GET_REWARDS_HISTORY:
            return {
                ...state,
                rewardsHistory: action.payload
            }
        case UPDATE_PROFILE:
            return {
                ...state,
                profileImage: action.payload
            }
        case UPDATE_GAMEPROFILE:
            return {
                ...state,
                gameProfileImage: action.payload
            }
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            }
        case SET_EVENT:
            return {
                ...state,
                singleEvent: action.payload
            }
        case SET_UNSEENEVENTS:
            return {
                ...state,
                unseenEvents: action.payload
            }
        case NFT_CREATED:
            return {
                ...state,
                nftCreated: action.payload
            }
        case NFT_URL:
            return {
                ...state,
                nftData: action.payload
            }
        case POST_EVENT:
            return {
                ...state,
                postevent: action.payload
            }
        default:
            return state
    }
}

export default userReducer
