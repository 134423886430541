import { React, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faBullhorn } from '@fortawesome/free-solid-svg-icons';


function NewUserModel(props) {
    const [show, setShow] = useState(true)
    const handleClose = () => {
        setShow(false);
    }
    return (
        <div className='swal-model'>
            <Modal centered className="custom-modal connect-wallet profile-modal" show={show} onHide={handleClose} >
                <Modal.Header>
                    <div className="image">
                        <span className='icon'><FontAwesomeIcon icon={faBullhorn} /></span>
                    </div>
                    <Button className="connect-btn btn ms-2" onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-4 modal-main-body'>
                        <p>To continue please update the <strong>User Name</strong> and <strong>Email Address</strong></p>
                    </div>
                    <div className='mt-3 pt-3 d-flex justify-content-center align-items-center flex-wrap'>
                        <Button className='text-start btc ms-2 mb-1  main-btn-style' onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Modal.Body>

            </Modal>
        </div >
    )
}

export default NewUserModel;