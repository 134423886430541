import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleEvent, updateSeenEvent } from '../../redux/user/action';
import Loader from "../loader/loader";
import { ENV } from "../../config/config";
import Table from 'react-bootstrap/Table';

const moment = require('moment');

const EventDetail = () => {
    const { eventId } = useParams();

    let event = useSelector(state => state?.userData?.singleEvent)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSingleEvent(eventId))
    }, [])

    useEffect(() => {
        if (!event?.isSeen) {
            dispatch(updateSeenEvent(eventId))
        }
    }, [event?.isSeen])
    return (
        <>
            <Loader />
            <div className="page-title">
                <h2> Notification detail</h2>
            </div>


            <div className="notifications">

                <div className="list">
                    <Table responsive >
                        <thead>
                            <tr>
                                <th>Points</th>
                                <th>Platform</th>
                                <th>Event Type</th>
                                <th>Badge</th>
                                <th>Avatar</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {event ? <tr>
                                <td>{event.points}</td>
                                <td>{ENV.capitalizeFirstLetter(event?.platform)}</td>
                                <td>{ENV.rearrangeEventType(event?.eventType)}</td>
                                <td>{event?.badge ? <img className="ava-img" src={ENV.media_url + event?.badge} /> : ""}</td>
                                <td>{event?.avatar ? <img className="ava-img" src={ENV.media_url + event?.avatar} /> : ""}</td>
                                <td>{moment(event.createdAt).format('MM/DD/YYYY')}</td>

                            </tr>
                                : ""}
                        </tbody>
                    </Table>

                </div>

            </div >
        </>
    );
}

export default EventDetail;