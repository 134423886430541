import DailyRewards from "./dailyRewards/dailyRewards"
import TokenozedGift from "./tokenizedGift/tokenizedGift"
function GiftCards() {
    return (
        <div className="row">
            {/* <TokenozedGift /> */}
            <DailyRewards />
        </div>
    );
}

export default GiftCards;