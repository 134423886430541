import { useEffect, useState } from "react";
import "react-step-progress-bar/styles.css";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ProgressBar, Step } from "react-step-progress-bar";
import { getProfileCompleteness } from "../../redux/user/action";
import { getTasks, getProgressLevels } from "../../redux/tasks/action"
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getQueryParam } from '../../config/helper'
import Lottie from 'react-lottie';
import ClaimedTick from '../../assets/lottie/claimed.json';
import ClaimedStars from '../../assets/lottie/claimed-stars.json';

const StepProgressBar = (props) => {
    let location = useLocation();
    let routeParams = location.pathname.split('/');
    let dispatch = useDispatch();
    let progressLevels = useSelector(state => state.tasks?.progressLevels)
    let profileCompleteness = useSelector(state => state.userData?.profCompleteness)
    const [count, setCount] = useState(0)

    let wallet = localStorage.getItem("WalletAddress")
    // let address = getQueryParam(location, 'address')
    // wallet = address
    let myCount = 0
    useEffect(() => {
        dispatch(getProgressLevels())
        dispatch(getProfileCompleteness(wallet))
        // badgesLog()
    }, [])

    useEffect(async () => {
        if (profileCompleteness && progressLevels) {
            badgesLog()
        }
    }, [profileCompleteness, progressLevels])


    const badgesLog = () => {
        let index;
        if (profileCompleteness) {
            if (progressLevels && progressLevels.length > 0) {
                profileCompleteness.forEach(element => {
                    index = progressLevels[0]?.progress && progressLevels[0]?.progress.findIndex(x => x?.task.value === element)
                    if (index > -1) {
                        myCount = myCount + parseInt(progressLevels[0]?.progress[index].progress)
                    }
                });
                setCount(myCount)
            }
        }
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         badgesLog()
    //     }, 5000);
    //   });

    const lottie1 = {
        loop: true,
        autoplay: true,
        animationData: ClaimedStars,
    };
    const lottie2 = {
        loop: true,
        autoplay: true,
        animationData: ClaimedTick,
    };

    return (

        <>
            <div className="progress-stats">

                {/* <div>
                    {progressLevels ?
                        < ProgressBar percent={count} fillBackground="linear-gradient(to right, #fefb72, #f0bb31)">
                            <Step classNme="steps"  >
                                {({ accomplished }) => (
                                    <img
                                        style={{ filter: `brightness(${accomplished ? 1 : 0}) invert(${accomplished ? 0 : 1})` }}
                                        width="20"
                                        alt=""
                                        src="/images/progress-bar-logo.svg"
                                    />
                                )}
                            </Step>

                            {progressLevels && progressLevels.length > 0 ?
                                progressLevels[0].progress.length > 0 ? progressLevels[0]?.progress && progressLevels[0]?.progress.map((platform, key) => {
                                    return (
                                        <Step classNme="steps me-5"  >
                                            {({ accomplished }) => (
                                                <img
                                                    style={{ filter: `brightness(${accomplished ? 1 : 0}) invert(${accomplished ? 0 : 1})` }}

                                                    width="20"
                                                    alt=""
                                                    src="/images/progress-bar-logo.svg"
                                                    key={key} />
                                            )}
                                        </Step>)
                                }) : "" : ""}
                            <h2>{count} %</h2>
                        </ProgressBar>
                        : ""}
                </div> */}
                {routeParams[1] === 'progress' ?
                    progressLevels ?
                        <div className="progress-tasks">
                            <div className="list p-0">
                                <ul>
                                    {progressLevels && progressLevels[0]?.progress && progressLevels[0]?.progress.length > 0 ? progressLevels[0]?.progress && progressLevels[0]?.progress.map((platform, key) => {
                                        let platformExist = profileCompleteness?.findIndex(x =>
                                            x === platform.task.value
                                        )
                                        return (

                                            <li className={platformExist === -1 ? `inactive` : "active"} key={key}>
                                                <p className="task-name"> {platform.task.label} </p>
                                                <p className="percentage mt-0">{platform.progress}% Progress</p>
                                                <div className="lotties-wrap">
                                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                                </div>
                                            </li>
                                        )
                                    }) : ""}
                                </ul>
                            </div>
                        </div> : "" : ""
                }
                <div className="bar-width bar-bg">
                    <CircularProgressbar value={count} text={`${count}%`} />
                </div>
            </div>
        </>
    );
}
export default StepProgressBar;