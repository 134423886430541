
import PointsReward from "./pointsDistribution/pointsDistribution"
import MyNfts from "../../myNFTs/myNFTs"
import GamePurchases from "./gamePurchases/gamePurchases.js"
import GiftCards from "./giftCards/giftCards"
import Promotion from "../../promotion/promotion";
import Slicker from "./promotionSlider/promotions";
import GroupChat from "./chatBox/groupChat";
import { useLocation } from "react-router-dom";
import Connect from "../../../connectWallet/connect";
import { getEnabledPromotion, getPromotions } from "../../../redux/promotions/action";
import { getUserPoints } from "../../../redux/user/action";
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { getQueryParam } from '../../../config/helper'

// const { socket } = ENV

// socket.on("connect", () => {
//     console.log(socket.id); // x8WIv7-mJelg7on_ALbx
// });
function MainContent(props) {
    let location = useLocation();
    let address = getQueryParam(location, 'address')
    let route = getQueryParam(location, 'route')
    const [userInfo, setUserInfo] = useState({ address: '' })
    const dispatch = useDispatch();
    let [chat, setChat] = useState(true)

    useEffect(() => {
        dispatch(getEnabledPromotion("dashboard"));
        return () => {
            setChat(false)
        }
    }, [])
    useEffect(() => {
        dispatch(getPromotions());
    }, [])
    // const dispatch = useDispatch();
    // const onboarding = useRef();
    // const [show, setShow] = useState(true)
    // // localStorage.setItem("WalletAddress", address)
    // let WalletAddress = address
    // useEffect(() => {
    //     connectToMetamask()
    //     if (typeof window.ethereum === 'undefined') {
    //         console.log('Please install MetaMask first!');
    //     }
    //     if (!WalletAddress) {
    //         setShow(true)
    //     }
    //     if (!onboarding.current) {
    //         onboarding.current = new MetaMaskOnboarding();
    //     }
    //     metamaskEventDetector()
    // },[]);

    return (
        <>

            {!address ?
                <div className="guide">
                    <div className="section-red-bg section-dark-bg">
                        <p><span> <i class="fa fa-warning"></i>Warning : </span>Please Connect your Wallet, To install Metamask click on this <a target="_blank" href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en'> Link </a> </p>
                    </div>
                </div>
                : ""}
            {/* <button onClick={buttonClicked}>click to increment :{count}</button> */}
            <PointsReward />
            <div className="my-nfts">
                <Slicker type='Dashboard' />

            </div>
            <div className="my-nfts">
                <MyNfts slidesToScroll={1} />
            </div>
            {/* <div className="my-nfts">
                <Slicker type='Dashboard' />

            </div> */}
            <div className="my-nfts">
                <GiftCards />
            </div>
            <GamePurchases />
            <Promotion type='Dashboard' />
            {chat ? <GroupChat /> : ""}
        </>
    );
}

export default MainContent;