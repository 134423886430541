import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialChat, postChat, singleChat } from '../../../redux/chatbox/action';
import React from 'react';
import './chatBox.css';
import useSound from 'use-sound';
import send from './audio/send.mp3';
import { Button } from "react-bootstrap";
import Pusher from 'pusher-js';
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faMessage } from '@fortawesome/free-solid-svg-icons';

const moment = require('moment');

const ChatBox = (props) => {
    let dispatch = useDispatch();
    let user = props.user;
    const pusher = props.pusher;
    const channel = props.channel;
    let recieverName = props.reciever;
    let userName = props.userName;
    const [play] = useSound(send, { volume: 0.15 });
    let walletAddress = localStorage.getItem('WalletAddress');
    var eventId = user.slice(user.length - 4, user.length) + "-" + walletAddress.slice(walletAddress.length - 4, user.walletAddress);
    const [message, setMessage] = useState('');
    const [wallet, setWallet] = useState(walletAddress);
    const [isActive, setIsActive] = useState(true);
    const [isInputActive, setInput] = useState(true);
    const [loader, setLoader] = useState(true);
    const [event, setEvent] = useState(eventId);
    const [numberOfItems, setNumberOfItems] = useState(3);
    let [slice, setSlice] = useState([]);
    const [chatsRevres, setChatsRev] = useState([])
    const [messageLoader, setMessageLoader] = useState(true);
    let chatHistory = useSelector(state => state?.chat?.singleChat);
    let getChat = useSelector(state => state?.chat?.getChat);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    let chat = { owner: wallet }
    function loadMoreItems() {
        if (numberOfItems >= chatHistory?.data?.length) {
            setHasMoreItems(false)
        }
        setNumberOfItems(numberOfItems + 10)
    }

    useEffect(() => {
        let msgChunk = chatsRevres?.slice(0, numberOfItems)
        setSlice(msgChunk)
    }, [numberOfItems])

    useEffect(() => {
        let data = chatHistory.data;
        let reverseChats = data?.reverse()
        let msgChunk = reverseChats?.slice(0, numberOfItems)
        setSlice(msgChunk)
        setChatsRev(reverseChats)
    }, [chatHistory])

    useEffect(() => {
        const payload = {
            from: walletAddress,
            to: user
        }
        dispatch(singleChat(payload));
    }, [])

    useEffect(() => {
        props.getEventId(event)
    }, [event])

    useEffect(() => {
        var messageBox = document.getElementsByClassName('messages')
        messageBox[0].scrollTo(0, messageBox[0].scrollHeight);
        setTimeout(function () {
            setLoader(false);
        }, 1000)

        pusher.connection.bind("state_change", function (states) {
            if (states.current == 'connected') {
                let boxes = Array.prototype.slice.call(document.querySelectorAll('.loadingMessage'));
                boxes = boxes?.reverse()
                boxes?.forEach(box => {
                    let parent = box.parentNode
                    let childs = parent.childNodes
                    let sendBy = childs[1].innerHTML
                    let messageText = childs[3].innerHTML
                    let createdTime = childs[5].innerHTML
                    let event_id = childs[7].innerHTML
                    let receivedby = childs[9].innerHTML

                    const reconnectPayload = {
                        username: sendBy,
                        message: messageText,
                        createdAt: createdTime,
                        eventId: event_id,
                        from: wallet,
                        to: receivedby
                    };
                    dispatch(postChat(reconnectPayload));
                    box.remove();
                });
            }
        });
        myFunction()
    }, [getChat])

    let myFunction = () => {

        if (chatHistory) {
            if (chatHistory?.data) {
                if (chatHistory?.data[0]?.eventId?.length > 0) {
                    setEvent(chatHistory?.data[0].eventId)
                    channel.bind(chatHistory?.data[0]?.eventId, function (data) {
                        const messageClass = data.from !== chat.owner ? 'reply new-box' : 'user new-box-user'
                        if (data.from !== chat.owner && data.message !== "subscribe" && data.eventId == chatHistory?.data[0]?.eventId) {
                            var ele = document.getElementsByClassName('messages')
                            ele[0].insertAdjacentHTML("afterbegin",
                                `<li class="clearfix message ${messageClass}">
                                <div class="sender">${data.username}</div>
                                <div class="message">${data.message}</div>
                                <div class="date">${data.createdAt}</div>
                                </li>`)
                            ele[0].scrollTop = ele[0].scrollHeight;
                        }
                        else if (data.message !== "subscribe" && data.eventId == chatHistory?.data[0]?.eventId) {
                            let boxes = Array.prototype.slice.call(document.querySelectorAll('.loadingMessage'));
                            boxes[0]?.remove();
                        }
                        let boxes = Array.prototype.slice.call(document.querySelectorAll('.loadingMessage'));
                        boxes = boxes?.reverse()
                        boxes?.forEach(box => {

                            let parent = box.parentNode
                            let childs = parent.childNodes
                            let sendBy = childs[1].innerHTML
                            let messageText = childs[3].innerHTML
                            let createdTime = childs[5].innerHTML
                            let event_id = childs[7].innerHTML
                            let receivedby = childs[9].innerHTML

                            const reconnectPayload = {
                                username: sendBy,
                                message: messageText,
                                createdAt: createdTime,
                                eventId: event_id,
                                from: wallet,
                                to: receivedby
                            };
                            dispatch(postChat(reconnectPayload));
                            box.remove();
                        });
                    });
                }
                else {
                    setEvent(eventId)
                    channel.bind(eventId, function (data) {
                        const messageClass = data.from !== chat.owner ? 'reply new-box' : 'user new-box-user'
                        if (data.from !== chat.owner && data.message !== "subscribe" && data.eventId == eventId) {
                            var ele = document.getElementsByClassName('messages')
                            ele[0].insertAdjacentHTML("afterbegin",
                                `<li class="clearfix message ${messageClass}">
                                <div class="sender">${data.username}</div>
                                <div class="message">${data.message}</div>  
                                <div class="date">${data.createdAt}</div>
                                </li>`)
                            ele[0].scrollTop = ele[0].scrollHeight;
                        }
                        else if (data.message !== "subscribe" && data.eventId == eventId) {
                            let boxes = Array.prototype.slice.call(document.querySelectorAll('.loadingMessage'));
                            boxes[0]?.remove();
                        }

                        let boxes = Array.prototype.slice.call(document.querySelectorAll('.loadingMessage'));


                        boxes = boxes?.reverse()
                        boxes?.forEach(box => {

                            let parent = box.parentNode
                            let childs = parent.childNodes
                            let sendBy = childs[1].innerHTML
                            let messageText = childs[3].innerHTML
                            let createdTime = childs[5].innerHTML
                            let event_id = childs[7].innerHTML
                            let receivedby = childs[9].innerHTML

                            const reconnectPayload = {
                                username: sendBy,
                                message: messageText,
                                createdAt: createdTime,
                                eventId: event_id,
                                from: wallet,
                                to: receivedby
                            };
                            dispatch(postChat(reconnectPayload));
                            box.remove();
                        });
                    });
                }
            }
        }
    }
    const handleMessageSend = (e) => {

        e.preventDefault()
        let createdAt = new Date()
        createdAt = createdAt.toLocaleTimeString()
        var payload = {};
        if (chatHistory?.data[0]?.eventId?.length > 0) {
            payload = {
                username: userName && userName?.length > 0 ? userName : wallet.slice(wallet?.length - 4, wallet.length),
                message: message,
                createdAt: createdAt,
                eventId: chatHistory.data[0].eventId,
                from: wallet,
                to: user
            };
        }
        else {
            payload = {
                username: userName && userName?.length > 0 ? userName : wallet.slice(wallet.length - 4, wallet.length),
                message: message,
                createdAt: createdAt,
                eventId: event,
                from: wallet,
                to: user
            };
        }
        dispatch(postChat(payload));
        setMessageLoader(true)
        const messageClass = wallet !== chat.owner ? 'reply new-box' : 'user new-box-user'
        const loadingClass = messageLoader ? "spinner-border loadingMessage" : ""
        var ele = document.getElementsByClassName('messages')
        ele[0].insertAdjacentHTML("afterbegin",
            `<li class="clearfix message ${messageClass}">
                <div class="sender">${userName?.length > 0 ? userName : wallet.slice(wallet.length - 4, wallet.length)}</div>
                <div class="message">${message}</div>
                <div class="date">${createdAt}</div>
                <div class="event-id-wrap d-none">${payload.eventId}</div>
                <div class="receiver-wrap d-none">${payload.to}</div>
                <div class="${loadingClass}"></div>
            </li>`)
        ele[0].scrollTop = ele[0].scrollHeight;
        setMessage('')

        play();
    }
    const ToggleChatWindow = () => {
        setIsActive(current => !current);
    }
    const HandleClose = () => {
        chatHistory = [];
        channel.unbind(event);
        dispatch(initialChat())
        props.resetNotification(user)
        props.chatClose(false)
    }

    return (
        <>
            <div className={isActive ? 'opened chatbubble' : 'chatbubble'}>

                <div className="unexpanded align-items-center">
                    <div className={!isActive ? "title" : 'title expanded-title-window'} onClick={ToggleChatWindow}>{!isActive &&
                        <FontAwesomeIcon icon={faMessage} />} </div>
                    {isActive && <Button className={'title'} onClick={HandleClose}><FontAwesomeIcon icon={faClose} /></Button>}
                </div>
                <div className="expanded chat-window">
                    <div className={isInputActive ? 'chats active' : 'chats'}>
                        <div className={loader ? 'historyLoaderWrapper' : ''}><div className={loader ? 'historyLoader spinner-border' : ''}></div></div>
                        <ul id="scrollableDiv" className={isInputActive ? 'messages clearfix active' : 'messages clearfix'}>
                            <InfiniteScroll
                                dataLength={slice?.length == undefined ? 10 : slice?.length}
                                next={loadMoreItems}
                                style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                                inverse={true}
                                hasMore={hasMoreItems}
                                scrollableTarget="scrollableDiv"
                                endMessage={
                                    // <b>Yay! You have seen it all</b>
                                    <b></b>

                                }
                            >
                                {slice ? slice.map((msg) => {

                                    return (
                                        <li className={msg.from == wallet ? 'clearfix message user' : 'clearfix message reply'}>
                                            <div class="sender">{msg.from === chat.owner ? userName?.length > 0 ? userName : wallet.slice(wallet.length - 4, wallet.length) : recieverName ? recieverName : msg.username}</div>
                                            <div class="message">{msg.message}</div>
                                            <div class="date">{moment(msg.createdAt).format('h:mm:ss A')}</div>
                                        </li>
                                    )
                                }) : null}
                            </InfiniteScroll>
                        </ul>

                        <div className={isInputActive ? 'input active' : 'input'}>
                            <form className="form-inline" id="messageOthers">
                                <div className="form-group">
                                    <input type="text" className="form-control" value={message} id="newMessage" placeholder="Enter Message" onChange={e => {
                                        if (e.target.value && e.target.value.trim() !== "") {
                                            setMessage(e.target.value)
                                        }
                                        else {
                                            setMessage("")
                                        }
                                    }
                                    } />
                                </div>
                                <button type="submit" className="btn btn-primary" onClick={handleMessageSend} disabled={message ? false : true}>Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatBox