
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import AOS from 'aos';
import './full-page-loader.css';
import loader from "./circles.svg"

function FullPageLoader() {
    const loaderActive = useSelector(state => state.userData.loaderActive)

    useEffect(() => {
        if (loaderActive) {
            AOS.init();
        }
        // }, [])
    }, [loaderActive]);

    return (
        <React.Fragment>
            {loaderActive ?

                <div className="loader">
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            {/* <span class="sr-only">Loading...</span> */}
                        </div>
                    </div>
                </div>
                : ""}
        </React.Fragment>
    );
};

export default FullPageLoader;