import { CLEAR_TREASUREBOX_DATA,GET_TREASUREBOXES_COUNT, SET_LOADER,SET_TRENDING_POSTS, SET_COMMENTS, ANSWERS_UPDATED, POST_UPDATED, UPLOAD_VIDEO, SET_POST, POST_CONTENT, GET_CATAGORIES, GET_TAGS, RECENT_IMG_UPLOAD, GET_TREASUREBOXES, UPDATE_TREASUREBOXVIEWS } from "../actionTypes";

const initialState = {
    content: [],
    catogories: [],
    tags: [],
    list: [],
    views: 0,
    postUpdated:false,
    answersUpdated:false,
    comments:[],
    trendingPosts:[],
    loaderActive:false
}

const treasureBox = (state = initialState, action) => {

    switch (action.type) {
        case POST_CONTENT:
            return {
                ...state,
                content: action.payload
            }
        case GET_CATAGORIES:
            return {
                ...state,
                catogories: action.payload
            }
        case GET_TAGS:
            return {
                ...state,
                tags: action.payload
            }
        case RECENT_IMG_UPLOAD:
            return {
                ...state,
                rec_image_id: action.payload
            }
        case GET_TREASUREBOXES:
            return {
                ...state,
                list: state.list.concat(action.payload)
            }
        case GET_TREASUREBOXES_COUNT:
            return {
                ...state,
                treasureBoxCount: action.payload
            }
        case POST_UPDATED:
            return {
                ...state,
                postUpdated: !state.postUpdated,
            }
        case SET_POST:
            return {
                ...state,
                post: action.payload
            }
        case UPDATE_TREASUREBOXVIEWS:
            return {
                ...state,
                views: action.payload
            }
        case UPLOAD_VIDEO:
            return {
                ...state,
                file: action.payload
            }
        case ANSWERS_UPDATED:
            return {
                ...state,
                answersUpdated: !state.answersUpdated
            }
        case SET_COMMENTS:
            return {
                ...state,
                comments: action.payload
            }
        case SET_TRENDING_POSTS:
            return {
                ...state,
                trendingPosts: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload,
            }
        case CLEAR_TREASUREBOX_DATA:
            return initialState
            
        default:
            return state;
    }
}
export default treasureBox;