
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react"
import { getStats } from "../../../redux/user/action"
import { Link, useParams, useLocation } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);
const options = {
  scales: {
    y: {
      title: {
        display: true,
        text: 'Points'
      },
      ticks: {
        beginAtZero: true,
        min: 0
      }
    }
    // ,
    // yAxes: [{
    //   ticks: {
    //     beginAtZero: true,
    //     min: 0
    //   }
    // }]
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  }
};

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', "Aug", 'Sep', 'Oct', 'Nov', 'Dec'];

const borderColor = '#FECD34';
const backgroundColor = 'rgba(255, 99, 132, 0.5)';
const _borderColor = '#2BE4FD';
const _backgroundColor = 'rgba(89, 162, 235, 0.5)';



function Stats() {
  const userStats = useSelector(state => state?.userData?.userStats)
  // const address = useSelector(state => state?.userData?.profile?.address)
  const address = localStorage.getItem("WalletAddress")
  const perWeek = userStats?.perWeek
  const perWeekDays = userStats?.weekDays
  const eventsPerMonth = userStats?.eventsPerMonth
  const eventsPerDay = userStats?.eventsPerDay
  const dateArray = userStats?.dateArray

  const [perWeekData, setPerWeek] = useState([])

  const weeks = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const days = ['1', '2', '2', '3:00 pm', '4:00 pm', '5:00 pm', '6:00 pm'];

  const monthly = {
    labels: months,
    datasets: [

      {
        fill: true,
        data: eventsPerMonth,
        borderColor: _borderColor,
        backgroundColor: _backgroundColor,
        tension: 0.5,
      },
    ],
  };
  const weekly = {
    labels: perWeekDays,
    datasets: [
      {
        fill: true,
        data: perWeekData,
        borderColor: _borderColor,
        backgroundColor: _backgroundColor,
        tension: 0.5,
      },
    ],
  };
  const daily = {
    labels: dateArray,
    datasets: [
      {
        fill: true,
        data: eventsPerDay,
        borderColor: _borderColor,
        backgroundColor: _backgroundColor,
        tension: 0.5,
      },
    ],
  };
  const [data, setData] = useState(monthly);
  const history = useHistory();
  let location = useLocation();
  let name = new URLSearchParams(location.search).get('name');
  const [current, setCurrent] = useState("monthly");
  const dispatch = useDispatch();
  let dataObj = {}
  // let { address } = useParams()
  useEffect(() => {
    if (name) {
      setCurrent(name);
      dataObj = { type: name }
    } else {
      dataObj = { type: "monthly" }
    }
    dataObj.address = address
    dispatch(getStats(dataObj))
  }, [name]);

  useEffect(() => {
    if (userStats.perWeek !== undefined) {
      setPerWeek(perWeek)
    }
  }, [userStats.perWeek]);

  useEffect(() => {
    setData(monthly);
    if (name !== '') {
      if (name === 'daily') {
        setData(daily)
      } else if (name === 'weekly') {
        setData(weekly);
      } else if (name === 'monthly') {
        setData(monthly);
      }
    }
  }, [name, perWeekData]);

  const typeClicked = (type) => {

    history.push(`/profile?name=${type}&&route=Profile`)
  }

  return (
    <div className="stats-sec">
      <div className="section-dark-bg">
        <div className="heading">
          <h4>Leaderboard Stats</h4>
          <ul>
            <li>
              <a className={current === "monthly" ? "active" : ""} onClick={() => typeClicked("monthly")}>Monthly</a>
            </li>
            <li>
              <a className={current === "weekly" ? "active" : ""} onClick={() => typeClicked("weekly")}>Weekly</a>
            </li>
            <li>
              <a className={current === "daily" ? "active" : ""} onClick={() => typeClicked("daily")}>Daily</a>
            </li>
          </ul>
        </div>
        <div className='chart'>
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
}

export default Stats;

