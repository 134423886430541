import authReducer from "./auth/authReducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk'
import userReducer from "./user/userReducer";
import giftCardReducer from './giftCard/giftCardReducer';
import notesReducer from "./notification/reducer";
import taskReducer from './tasks/reducer';
import BadgeReducer from './badges/reducer';
import contestVideos from "./uploadContest/reducer";
import treasureBox from './treasureBox/reducer'
import quiz from "./testRedux/reducer";
import contestDetails from "./contest/reducer";
import promotionReducer from "./promotions/reducer";
import chatDetails from "./chatbox/reducer";
import walletReducer from "./walletProviders/walletReducer";
import popups from "./activity/reducer";
import requestReducer from "./requests/reducer";

const middleware = [thunk]
const reducer = combineReducers({
  auth: authReducer,
  userData: userReducer,
  giftCardData: giftCardReducer,
  notesData: notesReducer,
  tasks: taskReducer,
  badgesData: BadgeReducer,
  contestVideoData: contestVideos,
  quizData: quiz,
  treasureBoxData: treasureBox,
  contestDetailsData: contestDetails,
  promotions: promotionReducer,
  chat: chatDetails,
  popups: popups,
  walletProvider: walletReducer,
  requests: requestReducer
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store