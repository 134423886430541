
import { Link, useLocation } from 'react-router-dom'
import { ENV } from "../../../config/config"
import React, { useEffect, useState } from "react"
import { getBadges } from "../../../redux/badges/action"
import { useDispatch, useSelector } from "react-redux";
import Sparkles from 'react-sparkle'
import { getQueryParam } from '../../../config/helper';
function BadgesEarned() {
    let location = useLocation()
    let address = getQueryParam(location, 'address')
    const badgesEarned = useSelector(state => state.userData?.profile?.badges)
    const allBadges = useSelector(state => state?.badgesData?.badges)
    const loaderA = useSelector(state => state.badgesData.loaderActive);
    const [myBadges, setBadges] = useState([])

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBadges())
    }, [])
    useEffect(() => {
        if (allBadges) {
            if (badgesEarned) {
                setBadges(badgesLog())
            }
        }
    }, [badgesEarned, allBadges])

    const badgesLog = () => {

        let toatalBadges = []
        let index;
        badgesEarned && badgesEarned.length > 0 && badgesEarned.forEach(element => {
            index = allBadges && allBadges.findIndex(x => x.name === element.name)
            toatalBadges.push(allBadges[index])
        });

        return toatalBadges
    }
    return (
        <div className="badges-earned">
            <div className="badges-earned-bg">
                <div className="heading">
                    <h4>Badges</h4>
                    {myBadges.length > 0 ? <Link to={`/badges/${address}?route=Badges`} className="view-all">VIEW ALL</Link>
                        : ""}
                </div>
                <div className="badges-list">
                    <ul>
                        {myBadges && myBadges.length > 0 ? myBadges.slice(0, 6).map((singleBadge, key) => {
                            return (<li style={{ position: 'relative' }} key={key}>
                                <img src={ENV.media_url + singleBadge?.picture} alt="" className="img-fluid" />
                            </li>)
                        }) :
                            <div className='not-found'>
                                <p>No badge to show!</p>
                            </div>
                        }
                    </ul>
                </div>
            </div>
        </div >
    );
}

export default BadgesEarned;

