// importing layout
import Layout1 from "./layouts/layout_1";
import Layout2 from "./layouts/layout_2";
import Layout3 from "./layouts/layout_3";
import Layout4 from "./layouts/layout_4";
import Layout5 from "./layouts/layout_5";
import Layout6 from "./layouts/layout_6";


// importing all the themes
import { lazy } from 'react';
import Dashboard from './components/dashboard';
import Profile from './components/profile';
import LeaderBoard from './components/leaderboard/leaderboard';
import NotificationsCopy from './components/notifications/notificationsCopy';
import NotifDetail from './components/notifications/notifDetail';
import Settings from "./components/settings/settings"
import ListGiftCards from './components/dashboard/mainContent/giftCards/tokenizedGift/listGiftCards';
import ConnectWallet from "./connectWallet/connect.js"
import WalletModal from "./components/walletmodal/modal"
import AllActivities from './components/activities/allActivities';
import AllBadges from "./components/profile/badges/badges"
import Progress from "./components/progress/progress"
import AllRewards from "./components/dashboard/mainContent/giftCards/dailyRewards/allRwards"
import PointHistory from "./components/pointsDetail/pointsDetail"
import UploadContest from "./components/uploadContestVideo/uploadContest";
import DailyTasks from "./components/dailyTask/dailyTask";
import LevelsAchievements from "./components/levelsAchievements/levelsAchievements";
import UploadTreasureBox from "./components/treasureBox/uploadTreasureBox";
import SingleReward from "./components/dashboard/mainContent/giftCards/dailyRewards/singleReward"
import Claim from "./components/claim/claim"
import Referrals from "./components/Referrals/referrals";
import PageNotFound from "./components/routeNotFound/PageNotFound";
import Avatars from "./components/avatars/index.js"
import LoginWithRainbow from "./components/Login/LoginWithRainbow";
import TwitterShared from "./components/TwitterShared";
import SharingModule from "./components/sharingModule";
import LandingComponents from "./components/includes/landingPage/landingComponents";
const routes = [
    {
        path: "/",
        layout: Layout6,
        access: true,
        exact: true,
        component: LandingComponents,
    },
    {
        path: "/profile",
        layout: Layout1,
        access: false,
        exact: true,
        component: Profile,
    },
    {
        path: "/login",
        layout: Layout5,
        access: true,
        exact: true,
        component: LoginWithRainbow,
    },
    {
        path: "/leaderboard",
        layout: Layout1,
        access: true,
        exact: true,
        component: LeaderBoard,
    },
    {
        path: "/notifications",
        layout: Layout1,
        access: false,
        exact: true,
        component: NotificationsCopy,
    },
    {
        path: "/detail/:eventId",
        layout: Layout1,
        access: false,
        exact: true,
        component: NotifDetail,
    },
    {
        path: "/twitter-connected",
        layout: Layout5,
        access: false,
        exact: true,
        component: TwitterShared,
    },
    {
        path: "/shareContent",
        layout: Layout5,
        access: false,
        exact: true,
        component: SharingModule,
    },
    {
        path: "/daily-rewards",
        layout: Layout1,
        access: false,
        exact: true,
        component: DailyTasks,
    },
    {
        path: "/levels",
        layout: Layout1,
        access: false,
        exact: true,
        component: LevelsAchievements,
    },
    // {
    //     path: "/levels",
    //     layout: Layout3,
    //     access: true,
    //     exact: true,
    //     component: LevelsAchievements,
    // },
    // {
    //     path: "/contest/details",
    //     layout: Layout3,
    //     access: true,
    //     exact: true,
    //     component: ContestDetails,
    // },
    {
        path: "/settings",
        layout: Layout1,
        access: false,
        exact: true,
        component: Settings,
    },
    // {
    //     path: "/uploadTreasureBox",
    //     layout: Layout2,
    //     access: true,
    //     exact: true,
    //     component: UploadTreasureBox,
    // },
    {
        path: "/reward/:id",
        layout: Layout1,
        access: false,
        exact: true,
        component: SingleReward,
    },
    // {
    //     path: "/create-game",
    //     layout: Layout1,
    //     access: true,
    //     exact: true,
    //     component: CreateGame,
    // },
    // {
    //     path: "/post",
    //     layout: Layout1,
    //     access: true,
    //     exact: true,
    //     component: EditPost,
    // },
    {
        path: "/dashboard",
        layout: Layout1,
        access: false,
        exact: true,
        component: Dashboard,
    },
    // {
    //     path: '/code/:code',
    //     layout: Layout1,
    //     access: true,
    //     exact: true,
    //     component: Dashboard,
    // },
    {
        path: "/uploadContest",
        // layout: Layout3,
        layout: Layout1,
        access: false,
        exact: true,
        component: UploadContest,
    },
    {
        path: "/listCards",
        layout: Layout1,
        access: false,
        exact: true,
        component: ListGiftCards,
    },
    {
        path: "/activities",
        layout: Layout1,
        access: false,
        exact: true,
        component: AllActivities,
    },
    {
        path: "/history/:pointType",
        layout: Layout1,
        access: false,
        exact: true,
        component: PointHistory,
    },
    {
        path: "/badges/:address",
        layout: Layout1,
        access: false,
        exact: true,
        component: AllBadges,
    },
    {
        path: "/progress/:address",
        layout: Layout1,
        access: false,
        exact: true,
        component: Progress,
    },
    {
        path: "/rewards-all",
        layout: Layout1,
        access: false,
        exact: true,
        component: AllRewards,
    },
    {
        path: "/user-referrals",
        layout: Layout1,
        access: false,
        exact: true,
        component: Referrals,
    },

    // {
    //     path: "/claim",
    //     layout: Layout1,
    //     access: true,
    //     exact: true,
    //     component: Claim,
    // },
    // {
    //     path: "/",
    //     layout: Layout5,
    //     access: true,
    //     exact: true,
    //     component: LoginWithRainbow,
    // },
    {
        path: "/invite/:code",
        layout: Layout5,
        access: true,
        exact: true,
        component: LoginWithRainbow,
    },
    // {
    //     path: "/posts",
    //     layout: Layout3,
    //     access: true,
    //     exact: true,
    //     component: Posts,
    // },
    // {
    //     path: "/post-view/:id",
    //     layout: Layout4,
    //     access: true,
    //     exact: true,
    //     component: Post,
    // },
    {
        path: "/sell/:type",
        layout: Layout1,
        access: false,
        exact: true,
        component: Avatars,
    },
    {
        path: "*",
        layout: Layout4,
        access: true,
        exact: true,
        component: PageNotFound,
    },


];

export default routes;