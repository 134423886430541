import { GET_POPUPS, SET_LOADER, VERIFY_ACTIVITY, ACKNOWLEDGE_ACTIVITY, SET_ACTIVITY_POPUP } from "../actionTypes";
import { apiHelper } from '../apiHelper';
import { ENV } from "./../../config/config";
let token = localStorage.getItem("token")
let encToken = ENV.encryptToken(token)
export const getActivityPopups = () => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        token = localStorage.getItem("token")
        encToken = ENV.encryptToken(token)
        const res = await apiHelper("get", `/v1/activity`, "", encToken);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            if (res?.data) {
                let { data } = res;

                dispatch({
                    type: GET_POPUPS,
                    payload: data
                })
            }
        }
    } catch (error) {
        console.log(error.message);
    }
}
// export const updateActivity = async (data, id) => {
//     try {
//         setLoader = true;
//         encData.enc = ENV.encryptPayload(data)
//         let res = await apiHelper("put", `/v1/activity/${id}`, encData, encToken);

//         if (res.data) {
//             setLoader = false;
//             return res;
//         }
//         else {
//             toast.error("Error in response");
//         }

//     } catch (error) {
//         console.log(error.message);
//     }
// }

export const updateActivity = (data, id) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        token = localStorage.getItem("token")
        encToken = ENV.encryptToken(token)
        const res = await apiHelper("put", `/v1/activity/${id}`, data, encToken);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            // if (res?.data) {
            //     let { data } = res;

            //     dispatch({
            //         type: GET_POPUPS,
            //         payload: data
            //     })
            // }
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const verifyActivity = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });

        const res = await apiHelper("post", `/v1/activity/verify`, data);

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            if (res?.data) {
                let popup = res.data.popup;
                dispatch({
                    type: SET_ACTIVITY_POPUP,
                    payload: popup
                })
                // dispatch({
                //     type: VERIFY_ACTIVITY,
                //     payload: data
                // })
            }
        }
    } catch (error) {
        console.log(error.message);
    }
}


export const acknowledgeActivity = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });

        const res = await apiHelper("post", `/v1/activity/acknowledge`, data);
        // debugger
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            if (res?.data) {
                let { data } = res;

                dispatch({
                    type: ACKNOWLEDGE_ACTIVITY,
                    payload: data.success
                })
            }
        }
    } catch (error) {
        console.log(error.message);
    }
}