import React, { useEffect } from 'react'
import FacebookShare from './facebookShare'
import InstaShare from './instaShare'
import TwitterShare from './twitterShare'
import TiktokShare from './tiktokShare'
import YoutubeShare from './youtubeShare'
function SocialShare(props) {
    const { imageUrl, postCaption, media_type } = props
  return (
    <>
    <FacebookShare imageUrl={imageUrl} postCaption={postCaption && postCaption?.length>0?postCaption:''} media_type={media_type && media_type.length>0?media_type:'image'} >Facebook</FacebookShare>
    <InstaShare imageUrl={imageUrl} postCaption={postCaption && postCaption?.length>0?postCaption:''} media_type={media_type && media_type.length>0?media_type:'image'}>Instagram</InstaShare>
    <TwitterShare imageUrl={imageUrl} postCaption={postCaption && postCaption?.length>0?postCaption:''} media_type={media_type && media_type.length>0?media_type:'image'} postType={media_type && media_type.length>0?media_type:'image'} taskType={props.taskType && props.taskType!=='' && props.taskType!==undefined && props.taskType!==null ?props?.taskType:null}>Twitter</TwitterShare>
    
    {/* {props.media_type == 'video' ?
    <>
        <TiktokShare url={props.imageUrl} postId={postId} postType={props.media_type}>Instagram</TiktokShare>
        <YoutubeShare url={props.imageUrl} postId={postId} postType={props.media_type}>Youtube</YoutubeShare>
    </>
    : ''} */}
    </>
  )
}

export default SocialShare