import React, { useEffect, useState } from "react";
import axios from 'axios';
// import ffmpeg from 'fluent-ffmpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { postEvent, postFollowersEvent, postShared } from "../../redux/user/action";
// import { transformation } from "../../config/helper"
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select';
import { useLocation,useHistory } from 'react-router-dom';
import { getQueryParam } from "../../config/helper"

function InstaShare(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let comingSoon = getQueryParam(location, 'comingSoon')
  const [imageUrl, setImageUrl] = useState(props.imageUrl);
  const [media_type] = useState(props.media_type);
  const [postCaption, setPostCaption] = useState(props.postCaption);
  const [postId] = useState(props.postId);
  const [pagePopup, setPagePopup] = useState(false);
  const [pages, setPages] = useState({ value: '', label: 'Select page' });
  const [selectedPage, setSelectedPage] = useState({ value: '', label: 'Select page' });
  const [isSharingPost, setIsSharingPost] = useState(false);
  const [sharePost, setSharePost] = useState(false);
  const [facebookPagelist, setFbPageList] = useState(false);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
  let instaError = '';
  let followers = '';
  let facebookPages = '';
  let pageSelector = false;
  useEffect(() => {
    if (props.imageUrl) {

      // let transform = transformation(props.imageUrl, "instagram")
      // if (props.imageUrl?.split("upload/")[0].includes('image')) {
      //   setImageUrl(props.imageUrl?.split("upload/")[0] + `upload/${transform}/` + props.imageUrl?.split("upload/")[1])
      // }
      // if (props.imageUrl?.split("upload/")[0].includes('video')) {
      setImageUrl(props.imageUrl)
      // setImageUrl(props.imageUrl?.split("upload/")[0] + `upload/${transform}/` + props.imageUrl?.split("upload/")[1].split(/\.(?=[^\.]+$)/)[0] + ".jpeg")
      // }
    }

  }, [props.imageUrl])
  const handleClose = () => {
    setPagePopup(false);
    setIsSharingPost(false)
  }
  const handleTagChange = (event) => {
    setSelectedPage({ value: event.value, label: event.label })
    if (event.value.length > 0) {
    }
    else {
      toast.error("Please Select Page")
    }
  }
  const handleFacebookPageList = () => {
    if (selectedPage.value.length > 0) {
      setFbPageList(!facebookPagelist)
      setPagePopup(false)
      pageSelector = false
    }
    else {
      if (pageSelector == false) {
        toast.error('Please select Facebook page linked with Instagram!')
      }
    }

  }
  const handleErrorMessage = (message) => {
    if (instaError.length == 0) {
      instaError = message
    }
  }
  /* --------------------------------------------------------
   *                      FACEBOOK LOGIN
   * --------------------------------------------------------
   */

  // Check if the user is authenticated with Facebook
  useEffect(() => {
    window?.FB?.getLoginStatus((response) => {
      setFacebookUserAccessToken(response.authResponse?.accessToken);
    });
  }, []);


  const logInToFB = () => {
    window?.FB?.login(
      (response) => {
        setFacebookUserAccessToken(response.authResponse?.accessToken);
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope: "instagram_basic,pages_show_list,instagram_content_publish,ads_management,business_management,pages_read_engagement",
      }
    );
  };

  const logOutOfFB = () => {
    window?.FB?.logout(() => {
      setFacebookUserAccessToken(undefined);
    });
  };

  /* --------------------------------------------------------
   *             INSTAGRAM AND FACEBOOK GRAPH APIs
   * --------------------------------------------------------
   */
  const handleContainerStatus = (instaContainerId, instagramAccountId) => {
    setIsSharingPost(true)
    return new Promise((resolve) => {
      window.FB.api(
        instaContainerId,
        {
          access_token: facebookUserAccessToken,
          fields: "status_code",
        },
        async (response) => {
          if (response.status_code == 'IN_PROGRESS') {
            setIsSharingPost(true)
            setTimeout(() => {
              handleContainerStatus(instaContainerId, instagramAccountId)
              setIsSharingPost(false)
            }, 10000);
          }
          else {
            await publishMediaObjectContainer(
              instagramAccountId,
              instaContainerId
            );
            
            // let followerData = {
            //   friendsCount: followers,
            //   userId: localStorage.getItem("WalletAddress"),
            //   socialPlatform: 'instagram'
            // }
            // let sharedData = {
            //   postId: postId,
            //   walletAddress: localStorage.getItem("WalletAddress"),
            //   shared: true
            // }
            if (instaError && instaError.length > 0) {
              toast.error(instaError)
              instaError = ''
            }
            else {
              toast.success('Post is shared to Instagram Successfully!')
              if(comingSoon){
                let data = {
                  eventName: "redeeming",
                  userId: localStorage.getItem("WalletAddress"),
                }
                dispatch(postEvent(data))
                history.push('/daily-rewards?route=Daily Claim')
              }
              // dispatch(postShared(sharedData))
              // dispatch(postFollowersEvent(followerData))
            }
            setIsSharingPost(false);
            setSharePost(false)
            resolve(response);
          }

        }
      );
    });

  };


  const getFacebookPages = () => {
    return new Promise((resolve) => {
      window.FB.api(
        "me/accounts",
        { access_token: facebookUserAccessToken },
        (response) => {
          if (response.data) {
            if (response.data.length > 0) {
              let tagsOptions = []
              if (response.data) {
                for (let i = 0; i < response?.data?.length; i++) {
                  tagsOptions.push({ value: response?.data[i].id, label: response?.data[i].name })

                }
                setPages(tagsOptions)
              }
            }
            if (response.data.length > 1) {
              setSelectedPage({ value: '', label: 'Select page' })
              setPagePopup(true)
            }
            else {
              resolve(response.data);
              pageSelector = true
              handleFacebookPageList()
            }
            resolve('');

            // }
          }
          else {
            if (response.error.error_user_msg) {
              handleErrorMessage(response.error.error_user_msg)
            }
            else if (response?.error) {
              handleErrorMessage(response.error?.message)
            }
            else {
              handleErrorMessage('Facebook page linked with Instagram not found!')
            }
            resolve(response.data);
          }
        }
      );
    });
  };

  const getInstagramAccountId = (facebookPageId) => {
    if (facebookPageId !== 0) {
      return new Promise((resolve) => {
        window.FB.api(
          facebookPageId,
          {
            access_token: facebookUserAccessToken,
            fields: "instagram_business_account",
          },
          (response) => {
            if (response.instagram_business_account) {
              resolve(response.instagram_business_account.id);
            }
            else {
              if (response?.error?.error_user_msg) {
                handleErrorMessage(response.error.error_user_msg)
              }
              else if (response?.error) {
                handleErrorMessage(response.error?.message)
              } else {
                handleErrorMessage('Instagram business account Required!')
              }
              resolve(response.instagram_business_account);
            }

          }
        );
      });
    }
    else {
      handleErrorMessage('Facebook page linked with Instagram not found!')
    }
  };
  const getInstagramFollowers = (instagramAccountId) => {
    return new Promise((resolve) => {
      window.FB.api(
        instagramAccountId,
        {
          access_token: facebookUserAccessToken,
          fields: "followers_count",
        },
        (response) => {
          if (response.followers_count || response.followers_count == 0) {
            resolve(response.followers_count);
          }
          else {
            if (response.error.error_user_msg) {
              handleErrorMessage(response.error.error_user_msg)
            }
            else if (response?.error) {
              handleErrorMessage(response.error?.message)
            }
            else {
              handleErrorMessage('Instagram business account Required!')
            }
            resolve(response);
          }

        }
      );
    });
  };


  const createMediaObjectContainer = (instagramAccountId) => {
    if (media_type == 'video') {
      // const gifUrl = imageUrl;

      // // Download the GIF image
      // axios.get(gifUrl, { responseType: 'arraybuffer' }).then(response => {
      //   const gifData = Buffer.from(response.data, 'binary');

      //   // Use fluent-ffmpeg to convert the GIF to MP4
      //   ffmpeg()
      //     .input(gifData)
      //     .videoCodec('libx264')
      //     .outputOptions('-pix_fmt yuv420p')
      //     .on('error', function(err) {
      //       console.log('An error occurred: ' + err.message);
      //     })
      //     .on('end', function() {
      //       console.log('Finished converting GIF to MP4');
      //       setImageUrl('/output.mp4');
      //     })
      //     .save('/output.mp4');
      // });

      return new Promise((resolve) => {
        window.FB.api(
          `${instagramAccountId}/media`,
          "POST",
          {
            media_type: 'VIDEO',
            video_url: imageUrl,
            access_token: facebookUserAccessToken,
            image_url: imageUrl,
            caption: postCaption,
          },
          (response) => {
            if (response.id) {
              resolve(response.id);
            }
            else {
              if (response.error.error_user_msg) {

                handleErrorMessage(response.error.error_user_msg)
              }
              else if (response?.error) {
                handleErrorMessage(response.error?.message)
              }
              else {
                handleErrorMessage('File format is not acceptable on Instagram!')
              }
              resolve(response);
            }
          }
        );
      });
    }
    else {
      return new Promise((resolve) => {
        window.FB.api(
          `${instagramAccountId}/media`,
          "POST",
          {
            access_token: facebookUserAccessToken,
            image_url: imageUrl,
            caption: postCaption,
          },
          (response) => {
            if (response.id) {
              resolve(response.id);
            }
            else {
              if (response.error.error_user_msg) {
                handleErrorMessage(response.error.error_user_msg)
              }
              else if (response?.error) {
                handleErrorMessage(response.error?.message)
              }
              else {
                handleErrorMessage('File format is not acceptable on Instagram!')
              }
              resolve(response);
            }
          }
        );
      });
    }
  };

  const publishMediaObjectContainer = (
    instagramAccountId,
    mediaObjectContainerId
  ) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media_publish`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          creation_id: mediaObjectContainerId,
        },
        (response) => {
          if (response.id) {
            resolve(response.id);
          }
          else {
            if (response.error.error_user_msg) {
              if (response.error.code == '9007') {
                handleContainerStatus(mediaObjectContainerId, instagramAccountId)
              }
              handleErrorMessage(response.error.error_user_msg)
            }
            else if (response?.error) {
              handleErrorMessage(response.error?.message)
            }
            else {
              handleErrorMessage('File format is not acceptable on Instagram!')
            }
            resolve(response);
          }
        }
      );
    });
  };

  const shareInstagramPost = async () => {
    setSelectedPage({ value: '', label: 'Select page' })
    setSharePost(true)
    logInToFB()
  };
  useEffect(async () => {
    if (facebookUserAccessToken && sharePost) {
      setIsSharingPost(true);
      if (selectedPage.value.length == 0) { facebookPages = await getFacebookPages(); }
      if (selectedPage.value.length > 0 || facebookPages[0]?.id) {
        const instagramAccountId = await getInstagramAccountId(selectedPage?.value.length > 0 ? selectedPage.value : facebookPages[0]?.id ? facebookPages[0]?.id : 0);

        if (instagramAccountId !== undefined) {
          followers = await getInstagramFollowers(instagramAccountId);
          const mediaObjectContainerId = await createMediaObjectContainer(
            instagramAccountId
          );

          await publishMediaObjectContainer(
            instagramAccountId,
            mediaObjectContainerId
          );
          // let data = {
          //   postId,
          //   eventName: "sharing",
          //   userId: localStorage.getItem("WalletAddress"),
          //   socialPlatform: 'instagram',
          // }
          // let followerData = {
          //   friendsCount: followers,
          //   userId: localStorage.getItem("WalletAddress"),
          //   socialPlatform: 'instagram'
          // }
          // let sharedData = {
          //   postId: postId,
          //   walletAddress: localStorage.getItem("WalletAddress"),
          //   shared: true
          // }
          if (instaError && instaError.length > 0) {
            if (instaError.includes('The media is not ready to be published')) {
              toast.info(instaError)
            }
            else {
              toast.error(instaError)
            }

            instaError = ''
          }
          else {
            toast.success('Post is shared to Instagram Successfully!')
            // dispatch(postEvent(data))
            // dispatch(postShared(sharedData))
            // dispatch(postFollowersEvent(followerData))
          }
          setIsSharingPost(false);
          setSharePost(false)
        } else {
          toast.error(instaError)
          setIsSharingPost(false);
          setSharePost(false)

        }
      } else {
        setIsSharingPost(false);
      }
    }

  }, [facebookUserAccessToken, facebookPagelist])
  return (
    <>
      {pagePopup &&
        <div className='swal-model'>
          <Modal centered className="custom-modal connect-wallet curr-wallet insta-model fb-model" show={pagePopup} onHide={handleClose} >
            <Modal.Body>

              <div className='position-relative'>
                <div className='mb-3 modal-main-body'>
                  <Modal.Title className='text-start'>SHARE ON SOCIAL MEDIA</Modal.Title>
                </div>
                <Button className="connect-btn btn ms-2" onClick={handleClose}>
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              </div>
              <div className="select-content">
                <Select
                  classNamePrefix="custom-select"
                  onChange={(e) => handleTagChange(e)}
                  options={pages}
                  value={selectedPage}
                  search
                  className="fb-insta-select"
                />


                <Button variant="" onClick={() => { handleFacebookPageList() }} className=' main-btn-style select-save'>Ok
                </Button>
              </div>


            </Modal.Body>
          </Modal>
        </div >

      }
      {/* <input
            className="d-none"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            placeholder="Enter a JPEG image url..."
          />
          <textarea
            className="d-none"
            value={postCaption}
            onChange={(e) => setPostCaption(e.target.value)}
            placeholder="Write a caption..."
            rows="10"
          >

          </textarea> */}
      <button
        onClick={shareInstagramPost}
        className="btn action-btn instagram"
        disabled={isSharingPost || !imageUrl}
      >
        <FontAwesomeIcon className={isSharingPost ? 'me-sm-1 sharing-grow spinner-grow' : "me-sm-1"} icon={faInstagram} />
        <span className='d-sm-block d-none'>
          {isSharingPost ? "Sharing..." : "Instagram"}
        </span>
      </button>
    </>
  );
}

export default InstaShare;