import React from 'react';
import { WagmiConfig, createClient } from 'wagmi';
import { darkTheme, RainbowKitProvider, connectorsForWallets, wallet } from '@rainbow-me/rainbowkit';
import { chains, provider } from "../lib/rainbowkit";
import { useSelector } from 'react-redux';




// const ErrorFallback = () => {
//   return (
//     <div role="alert">
//       <h2>Ooops, something went wrong</h2>
//       <BaseButton
//         onClick={() => window.location.assign(window.location.origin)}
//       >
//         Refresh
//       </BaseButton>
//     </div>
//   );
// };

// type AppProviderProps = {
//   children: React.ReactNode;
// };

export const AppProvider = ({ children }) => {


  let walletType = useSelector(state => state?.walletProvider?.provider);
  const getwallets = (type) => {
    if(type === 1) {
      return [wallet.metaMask({chains})]
    }else if(type === 2) {
      return [wallet.coinbase({chains})]
    } else if(type === 3) {
      return [wallet.walletConnect({chains})]
    }else {
      return [
          wallet.metaMask({chains}),
          wallet.coinbase({chains}),
          wallet.walletConnect({chains}),
        ] 
    }
  }

  let currntWallets = getwallets(walletType);


 const currentClient = createClient({
  autoConnect: false,
  connectors : connectorsForWallets(
    [
      {
        groupName: 'Recommended',
        wallets: currntWallets
      },
    ]
  ),
  provider,
});
  return (
          <WagmiConfig client={ currentClient }>
              <RainbowKitProvider
                chains={chains}
                appInfo={{ appName: 'Gamification' }}
                theme={darkTheme()}
              >
                {children}
              </RainbowKitProvider>
          </WagmiConfig>
  );
};
