import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import FaceAvater from "../../assets/images/face-avater.png"
import SpecialNotes from '../../assets/images/special-notes-img.png';
import { Link } from 'react-router-dom';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// AOS.init();

const Index = ({ notesModalClose }) => {

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    notesModalClose(false);
  }

  return (
    <>
        <Modal className="promotion-modal modal-animation special-notes-modal" show={show} onHide={handleClose} animation={true} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header className="text-center" closeButton>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6} className="d-flex align-items-center">
                <div className="image">
                  <img src={SpecialNotes} alt="" className="img-fluid" />
                </div>
              </Col>
              <Col md={6} className="pt-3">
                <div className="content">
                  <h2>NOTES TITLE</h2>
                  <div> 
                        <p>TPT Stakes average 40% interest a year. This section provides basic information for the benefit and use of taxpayers seeking to import vehicles.</p>
                  </div>
                  <div className="action-btn">
                    <button className="btn btn-primary">Learn More</button>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
    </>
  )
}

export default Index