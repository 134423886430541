import React from "react";
import Header from '../components/includes/header/header';

class Layout3 extends React.Component {
    render() {
        const { children } = this.props
        return (
            <div className="simple-layout">
                <div className="container">
                    {children}
                </div>
            </div>
        )
    }
}
export default Layout3;