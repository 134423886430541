import { Link } from "react-router-dom"
import Loader from "../loader/loader";
import logo from "../../assets/images/logo.svg"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { getUserStatus, claimDailyPoints, getDailyClaimRewards } from "../../redux/user/action"
import Countdown from 'react-countdown';
import moment from 'moment';
import Promotion from "../promotion/promotion";
import { postEvent } from "./../../redux/uploadContest/action"
import { ENV } from "../../config/config"
import Notifications, { notify } from "react-notify-toast";
import SuccessPop from "../successPopup/successPop";
import swal from 'sweetalert2';
import { countDownDailyReward, countDownMonthlyReward } from '../../config/helper'
// const { socket } = ENV
import ComingsoonPopup from "../successPopup/popup"
import { getEnabledPromotion } from "../../redux/promotions/action";
import TimeImage from "../../assets/images/claim-time.png"
import Lottie from 'react-lottie';
import ClaimedTick from '../../assets/lottie/claimed.json';
import ClaimedStars from '../../assets/lottie/claimed-stars.json';
import Popup from "../popup/popup"
function DailyTasks(props) {
    let dispatch = useDispatch()
    let promotions = useSelector(state => state?.promotions?.promotion);
    const loaderActive = useSelector(state => state.userData.loaderActive)
    const user = useSelector(state => state?.userData?.profile)
    const showdynamicPopup = useSelector(state => state?.userData?.showdynamicPopup)
    const rewardHistory = useSelector(state => state?.userData?.profile?.dailyReward)
    let userPoints = 0;
    let trueIndex = -1;
    if (user) {
        let pointsData = user?.pointsData ? user?.pointsData : 0;
        for (let index = 0; index < pointsData.length; index++) {
            userPoints = pointsData[index].points + userPoints;
        }

        for (const key in user?.dailyReward) {
        if (user?.dailyReward[key]) {
            trueIndex = Number(key.slice(3)); // Extract the numeric part of the key
            break;
        }
        }
    }
    let WalletAddress = localStorage.getItem("WalletAddress")
    var new_date = moment(new Date(), "DD-MM-YYYY").add(1, 'days').startOf('day');
    const [history, setHistory] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [showComingsoon, setShowcomingsoon] = useState(false)
    const [popupDescription, setPopupDescription] = useState("You can claim only once a day!")
    const toggleModel = async(day) => {
        setModalShow(true);
        setPopupDescription("You can claim only once a day!")
        setBtnDisable(true)
        await dispatch(claimDailyPoints({ userId: WalletAddress, eventName: "daily claim", pointType: "daily", day: day }))
        setBtnDisable(false)
    }
    useEffect(() => {
        dispatch(getUserStatus({ address: WalletAddress }))
        dispatch(getDailyClaimRewards(WalletAddress))
        dispatch(getEnabledPromotion("dailyClaim"));

    }, [])
    useEffect(() => { setShowcomingsoon(showdynamicPopup) }, [showdynamicPopup])
    useEffect(() => {
        if (user) {
            localStorage.setItem("token", user?.accessToken)
        }
    }, [user])

    const handleMyMint = () => {
        setShowcomingsoon(true)
        setPopupDescription("Coming Soon")
        setHistory(false)
        // swal.fire('Coming Soon ...')
    }
    const lottie1 = {
        loop: true,
        autoplay: true,
        animationData: ClaimedStars,
    };
    const lottie2 = {
        loop: true,
        autoplay: true,
        animationData: ClaimedTick,
    };
    return (
        <>
            {/* <Loader /> */}
            {/* <SuccessPop show={modalShow} setModalShow={setModalShow} /> */}
            {showComingsoon || showdynamicPopup ? <ComingsoonPopup heading="My Mint" desc={popupDescription} setShowcomingsoon={setShowcomingsoon} /> : ""}
            <Popup data={promotions} />
            <Notifications />

            <div className="coinmarket-rewards">
                <div className="content">
                    <h3>My Myntist Rewards</h3>
                    <div className="actions">
                        <button className="btn-gradient-blue" onClick={handleMyMint}>Redeem Points</button>
                    </div>
                </div>
                <div className="image-box">
                    <span>Total Points</span>
                    <span>{userPoints}</span>
                </div>
            </div>
            {history ?
                <div className="notifications">
                    <div className="list">
                        <ul>
                            <li className="head">
                                <p className="rewards">Date</p>
                                <p className="task">Points</p>
                            </li>
                            {rewardHistory && rewardHistory.length > 0 ? rewardHistory.map((reward) => {
                                return (<><li>
                                    <p className="rewards ">{moment(reward.createdAt).format('MMMM d, YYYY')}</p>

                                    <p className="task">{reward.points} </p>

                                </li></>)
                            }) : ""}
                        </ul>
                    </div>
                </div>
                :
                <div className="claim-rewards">
                    <div className="treasure-image">
                        <img src="/images/box.png" alt="Box" className="img-fluid" />
                    </div>
                    {/* <div className="time-wrap position-relative">
                        <div className="time-title">
                            <strong>AVAIL MONTHLY REWARD IN</strong>
                            <div className="time-count">
                                <Countdown date={new Date(new_date)} renderer={countDownMonthlyReward}></Countdown>
                            </div>
                        </div>
                        <div className="time-img">
                            62%
                        </div>
                    </div> */}
                    <div className="claim-rewards-header">
                        <h3>Claim your daily rewards</h3>
                        <p>You can claim daily rewards by coming on this page daily</p>
                    </div>
                    <div className="next-rewards-list">
                        <ul className="tresure-week">
                            {/* className={user?.dailyReward?.day1 === true ? "able" : "disable"}  this className from below functional li is cut to complete design. */}
                            {/* add a className .claimed on below able */}
                            <li className={user?.dailyReward?.day1 === true ? "able to-claim" : trueIndex>=1? "claimed disable" :'disable' } onClick={() => toggleModel(1)}>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <p className="day">DAY 1</p>
                                        <p className="points">+10</p>
                                    </div>
                                    <div className="image">
                                        <img src="/images/day2.png" alt="coins" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="claim-status coming-soon">
                                    <strong>COMING SOON</strong>
                                </div>
                                <div className="claim-status expires-in">
                                    <p>Expires in</p>
                                    <h6><Countdown date={new Date(new_date)} renderer={countDownDailyReward}></Countdown></h6>
                                </div>
                                <div className="claim-status availed">
                                    <strong>AVAILED</strong>
                                </div>
                                <div className="lotties-wrap lottie-animations">
                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                </div>
                            </li>
                            <li className={user?.dailyReward?.day2 === true ? "able to-claim" : trueIndex>=2? "claimed disable" :'disable' } onClick={(e) => { toggleModel(2) }}>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <p className="day">DAY 2</p>
                                        <p className="points">+10</p>
                                    </div>
                                    <div className="image">
                                        <img src="/images/day2.png" alt="coins" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="claim-status coming-soon">
                                    <strong>COMING SOON</strong>
                                </div>
                                <div className="claim-status expires-in">
                                    <p>Expires in</p>
                                    <h6><Countdown date={new Date(new_date)} renderer={countDownDailyReward}></Countdown></h6>
                                </div>
                                <div className="claim-status availed">
                                    <strong>AVAILED</strong>
                                </div>
                                <div className="lotties-wrap lottie-animations">
                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                </div>
                            </li>
                            <li className={user?.dailyReward?.day3 === true ? "able to-claim" : trueIndex>=3? "claimed disable" :'disable' } onClick={(e) => { toggleModel(3) }}>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <p className="day">DAY 3</p>
                                        <p className="points">+10</p>
                                    </div>
                                    <div className="image">
                                        <img src="/images/day2.png" alt="coins" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="claim-status coming-soon">
                                    <strong>COMING SOON</strong>
                                </div>
                                <div className="claim-status expires-in">
                                    <p>Expires in</p>
                                    <h6><Countdown date={new Date(new_date)} renderer={countDownDailyReward}></Countdown></h6>
                                </div>
                                <div className="claim-status availed">
                                    <strong>AVAILED</strong>
                                </div>
                                <div className="lotties-wrap lottie-animations">
                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                </div>
                            </li>
                            <li className={user?.dailyReward?.day4 === true ? "able to-claim" : trueIndex>=4? "claimed disable" :'disable' } onClick={(e) => { toggleModel(4) }} >
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <p className="day">DAY 4</p>
                                        <p className="points">+10</p>
                                    </div>
                                    <div className="image">
                                        <img src="/images/day2.png" alt="coins" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="claim-status coming-soon">
                                    <strong>COMING SOON</strong>
                                </div>
                                <div className="claim-status expires-in">
                                    <p>Expires in</p>
                                    <h6><Countdown date={new Date(new_date)} renderer={countDownDailyReward}></Countdown></h6>
                                </div>
                                <div className="claim-status availed">
                                    <strong>AVAILED</strong>
                                </div>
                                <div className="lotties-wrap lottie-animations">
                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                </div>
                            </li>
                            <li className={user?.dailyReward?.day5 === true ? "able to-claim" : trueIndex>=5? "claimed disable" :'disable' } onClick={(e) => { toggleModel(5) }} >
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <p className="day">DAY 5</p>
                                        <p className="points">+10</p>
                                    </div>
                                    <div className="image">
                                        <img src="/images/day2.png" alt="coins" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="claim-status coming-soon">
                                    <strong>COMING SOON</strong>
                                </div>
                                <div className="claim-status expires-in">
                                    <p>Expires in</p>
                                    <h6><Countdown date={new Date(new_date)} renderer={countDownDailyReward}></Countdown></h6>
                                </div>
                                <div className="claim-status availed">
                                    <strong>AVAILED</strong>
                                </div>
                                <div className="lotties-wrap lottie-animations">
                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                </div>
                            </li>
                            <li className={user?.dailyReward?.day6 === true ? "able to-claim" : trueIndex>=6? "claimed disable" :'disable' } onClick={(e) => { toggleModel(6) }} >
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <p className="day">DAY 6</p>
                                        <p className="points">+10</p>
                                    </div>
                                    <div className="image">
                                        <img src="/images/day2.png" alt="coins" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="claim-status coming-soon">
                                    <strong>COMING SOON</strong>
                                </div>
                                <div className="claim-status expires-in">
                                    <p>Expires in</p>
                                    <h6><Countdown date={new Date(new_date)} renderer={countDownDailyReward}></Countdown></h6>
                                </div>
                                <div className="claim-status availed">
                                    <strong>AVAILED</strong>
                                </div>
                                <div className="lotties-wrap lottie-animations">
                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                </div>
                            </li>
                            <li className={user?.dailyReward?.day7 === true ? "able to-claim" : trueIndex>=7? "claimed disable" :'disable' } onClick={(e) => { toggleModel(7) }}>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <p className="day">DAY 7</p>
                                        <p className="points">+10</p>
                                    </div>
                                    <div className="image">
                                        <img src="/images/day2.png" alt="coins" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="claim-status coming-soon">
                                    <strong>COMING SOON</strong>
                                </div>
                                <div className="claim-status expires-in">
                                    <p>Expires in</p>
                                    <h6><Countdown date={new Date(new_date)} renderer={countDownDailyReward}></Countdown></h6>
                                </div>
                                <div className="claim-status availed">
                                    <strong>AVAILED</strong>
                                </div>
                                <div className="lotties-wrap lottie-animations">
                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>}

            <Promotion type='Daily claim' />
        </>
    );
}
export default DailyTasks;