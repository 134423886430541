import { GET_BADGES, SET_LOADER, POST_BADGES ,GET_SINGLE_BADGE} from "../actionTypes";

const initialState = {
    badges: [],
    loaderActive: false
}
const badgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BADGES:
            return {
                ...state,
                badges: action.payload
            }
        case GET_SINGLE_BADGE:
            return {
                ...state,
                badges: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload
            }
            case POST_BADGES:
            return {
                ...state,
                badges: action.payload
            }
        default:
            return state;
    }
}
export default badgeReducer;