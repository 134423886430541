import { GET_ENABLED_PROMOTION, SET_LOADER, GET_PROMOTION } from '../actionTypes';

const initialState = {
    // singleTask: {}
    loaderActive: true,
}

const getPromotions = (state = initialState, action) => {
    switch (action.type) {

        case GET_ENABLED_PROMOTION:
            return {
                ...state,
                promotion: action.payload
            }
        case GET_PROMOTION:
            return {
                ...state,
                promotions: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload
            }

        default:
            return state
    }
}

export default getPromotions;