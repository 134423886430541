import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button , FormControl} from 'react-bootstrap'

const CreateNftPopUp = (props) => {
    let dispatch = useDispatch()
 
    const [chainName, setChainName] = useState('ETH')
    const [copies, setCopies] = useState(1)

    const handleClose = () => {
        props.setShowPopup(false);
        setChainName('');
        setCopies(1);
    }
    const handleChainName=(chain)=>{
        setChainName(chain)
    }
    return(
        <>
            <div className='swal-model'>
            <Modal centered className="custom-modal connect-wallet curr-wallet create-nft" show={props.showPopup} onHide={handleClose} >
                    <Modal.Body>
                        <div className='position-relative header'>
                            <div className='mb-3 modal-main-body'>
                                <Modal.Title className='text-start'>Create Nft</Modal.Title>
                            </div>
                            <Button className="connect-btn btn ms-2" onClick={handleClose}>
                                <FontAwesomeIcon icon={faClose} />
                            </Button>
                        </div>

                        <div className='curr-heading text-start'>Please select the blockchain you want to connect to</div>
                        <div className='d-flex justify-content-center py-3'>
                            <Button className={`curr-chain me-2 ${chainName==='ETH'?'active':''}`} onClick={() => { handleChainName('ETH') }}>
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"></path></svg>
                            </Button>
                            <Button className={`curr-chain ${chainName==='BNB'?'active':''}`} onClick={() => { handleChainName('BNB') }}>
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" role="img" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><title></title><path d="M16.624 13.9202l2.7175 2.7154-7.353 7.353-7.353-7.352 2.7175-2.7164 4.6355 4.6595 4.6356-4.6595zm4.6366-4.6366L24 12l-2.7154 2.7164L18.5682 12l2.6924-2.7164zm-9.272.001l2.7163 2.6914-2.7164 2.7174v-.001L9.2721 12l2.7164-2.7154zm-9.2722-.001L5.4088 12l-2.6914 2.6924L0 12l2.7164-2.7164zM11.9885.0115l7.353 7.329-2.7174 2.7154-4.6356-4.6356-4.6355 4.6595-2.7174-2.7154 7.353-7.353z"></path></svg>
                            </Button>
                        </div>
                    <div className='curr-heading text-start'>No. of copies</div>
                    <FormControl
                        type="number"
                        min={1}
                        id="copies"
                        readOnly
                        value={copies}
                        onChange={(e) => setCopies(e.target.value)}
                        aria-describedby="Define no. of copies to create an NFT with multiple copies"
                    />
                        <Button variant="" type='submit' className=' main-btn-style' onClick={() => { props.handleSell({chainName, copies}) }}>Submit
                            {props.buttonLoader ?
                            <span class="spinner-border spinner-border-sm tags-spinner-border ms-2" role="status" aria-hidden="true"></span>
                            : ''
                        }
                        </Button>

                </Modal.Body>
        </Modal>
        </div >
        </>
    )
}

export default CreateNftPopUp;