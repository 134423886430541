import { useEffect } from 'react'
import { Table, ButtonGroup, Button } from 'react-bootstrap'
import Dummy from '../../assets/images/placeholder.png';
import { ENV } from "../../config/config"
import { useDispatch, useSelector } from "react-redux";
import { getReferrals } from './../../redux/user/action';
import { getEnabledPromotion } from "../../redux/promotions/action";
import { getUserStatus } from "../../redux/user/action";

import Popup from "../popup/popup"

function Referrls() {
    const dispatch = useDispatch();
    const referrls = useSelector(state => state.userData.referrals)
    let promotions = useSelector(state => state?.promotions?.promotion);
    const user = useSelector(state => state.userData?.profile)
    console.log(referrls)
    let walletAddress = ENV.getUserKey('WalletAddress')
    useEffect(() => {
        dispatch(getUserStatus({ address: walletAddress }))
    }, [])
    useEffect(() => {
        if (user) {
            dispatch(getReferrals(user._id))
        }
        if (!user) {
            dispatch(getUserStatus({ address: walletAddress }))
        }
        dispatch(getEnabledPromotion("referral"));
    }, [user])
    return (
        <div className="main-leaderboard">
            <Popup data={promotions} />
            <div className="table-wrapper">
                <h2>Referrals</h2>
                {
                    referrls && referrls.length > 0 ?
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="" style={{ textAlign: 'center' }}>RANK</th>
                                    <th>NAME</th>
                                    <th>POINTS</th>
                                    <th>BADGES</th>
                                    {/* <th>ASSETS</th>
                            <th>NFTS</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {referrls && referrls.length > 0 ? referrls.map((user, key) => {
                                    return (
                                        <>
                                            <tr key={key} className={key == 0 ? 'first' : "" || key == 1 ? 'second' : "" || key == 2 ? 'third' : "" || key > 2 ? '' : ""}>
                                                <td style={{ textAlign: 'center' }}>0{key + 1}</td>
                                                <td>
                                                    {/* <Link to={`/profile`}> */}
                                                    <div className="user-details">
                                                        <div className="image">
                                                            {user?.picture ? <img src={process.env.REACT_APP_SERVER_URL + '/media/' + user?.picture} alt="User" className="img-fluid" /> : <img src={Dummy} alt="User" className="img-fluid" />}
                                                        </div>
                                                        <div className="user-info">
                                                            <h5>{user.name ? user?.name : 'N/A'}</h5>
                                                            <span>ID: {user.address.slice(user.address.length - 4, user.address.length).toUpperCase()}</span>
                                                        </div>
                                                    </div>
                                                    {/* </Link> */}
                                                </td>
                                                <td>{user.points}</td>
                                                <td>
                                                    <div className="badges">
                                                        <ul>
                                                            {user?.badges.length > 0 ?
                                                                user?.badges.length < 3 ?
                                                                    user.badges.map((badge, key) => {
                                                                        return (
                                                                            <li key={key}>
                                                                                <img src={process.env.REACT_APP_SERVER_URL + '/media/' + badge?.image} alt="Diamond" className="img-fluid" />{user?.badges.length <= 3 ? "" : "+" + user?.badges.length - 3}
                                                                            </li>
                                                                        )
                                                                    }) : <>{user.badges.slice(0, 2).map((badge, key) => {
                                                                        return (
                                                                            <li key={key}>
                                                                                <img src={process.env.REACT_APP_SERVER_URL + '/media/' + badge?.image} alt="Diamond" className="img-fluid" />
                                                                            </li>
                                                                        )
                                                                    })}<>+{user?.badges.length - 2}</> </>
                                                                : ""}

                                                        </ul>
                                                    </div>
                                                </td>
                                                {/* <td>{user.assets}</td>
                                        <td>{user.nfts ? user.nfts : "N/A"}</td> */}
                                            </tr>
                                        </>
                                    )

                                }) : "No referral to show!"}
                            </tbody>
                        </Table>
                        :
                        <div className='not-found'>
                            <p>No referral to show!</p>
                        </div>
                }

            </div>
        </div>
    );
}

export default Referrls;