import { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPointHistory } from '../../redux/user/action';
import Loader from "../loader/loader";
import SkeltonLoader from "../loader/skeltonLoader";
import DataTable from 'react-data-table-component';
import { ENV } from "../../config/config";

const PointsDetail = () => {
    const { pointType } = useParams();
    const history = useHistory()
    const loaderActive = useSelector(state => state.userData.loaderActive)
    const user = useSelector(state => state?.userData?.profile)

    let location = useLocation();
    let userId = ENV.getUserKey("userId")
    let address = ENV.getUserKey("WalletAddress")
    const [totalRows, setTotalRows] = useState([]);
    const [tableLoader, setTableloader] = useState(true)
    const [maxRows, setRows] = useState([]);
    const data = {
        pointType: pointType
    }
    if (address) {
        data.address = address
    } else if (userId) {
        data.userId = userId
    } else {
        history.push("/")
    }
    let events = useSelector(state => state?.userData?.eventHistory)
    const dispatch = useDispatch()

    useEffect(() => {
        setTableloader(true)
        dispatch(getPointHistory(data))
        setTableloader(false)
    }, [])
    useEffect(() => {
        setTableloader(true)
        if (events) {
            setTimeout(() => {
                setTableloader(false)
            }, 1000);
            calculatePagination(events.length)
        }
    }, [events])
    const calculatePagination = (rowsCount) => {
        setRows(rowsCount)
        if (rowsCount > 100) {
            setTotalRows([10, 25, 75, 100, rowsCount]);
        }
        else if (rowsCount >= 50) {
            setTotalRows([10, 25, rowsCount]);
        }
        else if (rowsCount < 50 && rowsCount > 10) {
            setTotalRows([10, rowsCount]);
        }
        else if (rowsCount <= 10) {
            setTotalRows([rowsCount]);
        }
    }

    const columns = [
        {
            name: "Points",
            selector: (row) => row?.points ? row?.points : 0,
            wrap: false,
            width: "20%"

            // sortable:true
        },
        {
            name: "Platform",
            selector: (row) => row?.platform,
            wrap: false,
            width: "20%"
            // sortable:true
        },
        {
            name: "Event Type",
            selector: (row) => ENV.rearrangeEventType(row?.eventType),
            wrap: false,
            width: "20%"
            // sortable:true
        },
        {
            name: "Badge ",
            selector: (row) => row?.badge ? <img className="ava-img" src={ENV.media_url + row?.badge}></img> : "",
            wrap: false,
            width: "20%",
            style: {
                padding: "4px",
            }
        },
        {
            name: "Avatar",
            selector: (row) => row?.avatar ? <img className="ava-img" src={ENV.media_url + row?.avatar}></img> : "",
            wrap: false,
            width: "20%",
            style: {
                padding: "4px"
            }
        }
    ]

    const customStyles = {
        table: {
            style: {
                background: '#131316'
            },
        },
        noData: {
            style: {
                display: 'flex',
                justifyContent: 'start',
                color: "white",
                backgroundColor: '#1E1E24',
                padding: "5px 10px",
                minHeight: '65px',
            },
        },
        progress: {
            style: {
                backgroundColor: '#1E1E24',
            },
        },
        rows: {
            style: {
                minHeight: '65px', // override the row height
                color: '#FFFFFF',
                background: '#2b2b33',
                fontSize: '14px',
            },
            stripedStyle: {
                color: "#fff",
                backgroundColor: "#1E1E24"
            },
        },
        headCells: {
            style: {
                fontSize: "18px",
                color: '#FFFFFF',
                background: '#000000',
            },
        },
        pagination: {
            style: {
                color: "#FFFFFF",
                backgroundColor: "#000000",
                borderTopStyle: 'solid',
            },
            pageButtonsStyle: {
                color: "#FFFFFF",
                fill: "#FFFFFF",

                '&:disabled': {
                    cursor: 'unset',
                    color: "#EDEDED",
                    fill: "#EDEDED",
                },
            }
        }
    };
    const noDataMessage = <span className="not-found"> There are no records to display</span>;
    return (
        <>
            {/* <div className="page-title">
                <h2>{pointType?.charAt(0).toUpperCase() + pointType?.slice(1)} Point History</h2>
            </div> */}
            {console.log(columns)}
            <div className="points-list">
                <div className="notifications">
                    <div className="list p-0">
                        {/* {events && events ? */}
                        <div className="points-table table-responsive">
                            {events && events.length > 9 ?
                                <DataTable
                                    columns={columns}
                                    data={events}
                                    pagination
                                    paginationRowsPerPageOptions={totalRows}
                                    paginationTotalRows={maxRows}
                                    // highlightOnHover
                                    pointerOnHover
                                    customStyles={customStyles}
                                    persistTableHead
                                    fixedHeader
                                    striped
                                    fixedHeaderScrollHeight="100%"
                                    progressPending={tableLoader || loaderActive}
                                    progressComponent={<SkeltonLoader height={'60'} width={'100%'} radious={'5'} skeletonLength={events ? events.length : 7} />}
                                /> :
                                
                                <DataTable
                                    columns={columns}
                                    data={events}
                                    pointerOnHover
                                    customStyles={customStyles}
                                    persistTableHead
                                    fixedHeader
                                    progressPending={tableLoader || loaderActive}
                                    noDataComponent={noDataMessage}
                                    striped
                                    progressComponent={<SkeltonLoader height={'60'} width={'100%'} radious={'5'} skeletonLength={events ? events.length : 7} />}
                                />
                                }
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default PointsDetail;