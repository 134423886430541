import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { showNotePopup, showErrorPopup } from "../../redux/user/action"
import Lottie from 'react-lottie';
import SunRays from '../../assets/lottie/rays.json';
import LogoPopup from '../../assets/images/logo-popup.png';

function CongratesModal(props) {
    let dispatch = useDispatch()
    let history = useHistory()
    // const [show, setShow] = useState(true)

    const onShare = async () => {
        console.log(props.popupData?.event?._id)
        dispatch(showNotePopup(false))
        history.push(`/shareContent?comingSoon=true`)
    }
    const handleClose = () => {
        props.setShowcomingsoon(false)
        dispatch(showNotePopup(false))
        dispatch(showErrorPopup(false))
    }

    const sunrays = {
        loop: true,
        autoplay: true,
        animationData: SunRays,
    };

    return (
        <>
            <Modal conHide={handleClose} show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="activity-popup">
                {/* <Button className="close-btn" onClick={handleClose}>
                    <FontAwesomeIcon icon={faClose} name="searchbtn" />
                </Button> */}
                <div className='popup-lotties position-relative'>
                    <div className="popup-logo">
                        <img src={LogoPopup} alt="logo" className="img-fluid"/>
                    </div>
                    <div className="lottie lottie-1">
                        <Lottie className="lottie" options={sunrays} />
                    </div>
                </div>
                <div className='popup-content'>
                    <h2>{props?.desc}</h2>
                    <p>Prepare to be captivated by an unforgettable journey coming soon.</p>
                    <span onClick={onShare} className='popup-btn mb-3'>
                        <FontAwesomeIcon icon={faShareNodes} name="searchbtn" />
                        <span className="ps-2">Share</span>
                    </span>
                    <Button className='popup-btn-close' onClick={handleClose}>Skip</Button>
                </div>

            </Modal>
        </>
    )
}

export default CongratesModal