
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react"
import { getSingleTask } from "../../../../../redux/tasks/action"
// import Loader from "../../../../loader/loader";
import FullPageLoader from '../../../../loader/loader';
import { Accordion, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

let base = process.env.REACT_APP_SERVER_URL + '/media/'
export var socket = null;
function SingleReward(props) {
    const task = useSelector(state => state.tasks.singleTask)
    const loaderA = useSelector(state => state.tasks.loaderActive)
    const [toggle, settoggle] = useState(0);
    const [loader, setloader] = useState(true)

    let { id } = useParams();
    const dispatch = useDispatch()
    useEffect(() => {
        getSingletask();
    }, [])
    const getSingletask = () => {
        setloader(true);
        dispatch(getSingleTask(id))
        setloader(false);

    }
    return (
        <>
            {loaderA ? <FullPageLoader /> : null}
            <div className="page-title">
                <h2>Task Detail</h2>
            </div>

            <div className="banner-title">
                <h1><span>BADGES &</span> Avatars</h1>
                <p>How To Achieve Badges & Avatars On Different Levels</p>
            </div>
            <div className="buyers-rewards">
                <h3>BUYERS REWARDS</h3>
                {task ?
                    <div className="item">
                        <Row className='px-1'>
                            <Col lg={4}>
                                <div className="avatar-badge">
                                    <div className="avatar">
                                        <div className="image">
                                            <img src={base + task?.avatar[0]?.avatar[0]?.image} alt="Avatar" className="img-fluid figure-img " />
                                        </div>
                                        <span>Avatar</span>
                                    </div>
                                    <div className="badge">
                                        <div className="image">
                                            <img src={base + task?.badge[0]?.picture} alt="Badge" className="img-fluid figure-img" />
                                        </div>
                                        <span>Badge</span>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={8}>
                                <div className='overview'>
                                    <h3 className="more ">Overview</h3>
                                    <p>Cras ultricies ligula sed magna dictum porta. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Pellentesque in ipsum id orci porta dapibus. Donec rutrum congue leo eget malesuada.</p>
                                    <div className='you-will-get'>
                                        <h5>You 'll get to</h5>
                                        <div className="others" dangerouslySetInnerHTML={{ __html: task?.description }}></div>
                                    </div>
                                    <div className='more-items'>
                                        {/* {toggle === key ? */}
                                        <div className={`others ${toggle ? 'show' : 'show'}`}>
                                            <p className=''>Niche Avatars:</p>
                                            <ul>
                                                <li>
                                                    <div className="image">
                                                        <img src={base + task.avatar[0]?.musicAvatar[0]?.image} alt="" className="img-fluid" />
                                                    </div>
                                                    <span>Music Collector</span>
                                                </li>
                                                <li>
                                                    <div className="image">
                                                        <img src={base + task.avatar[0]?.ebookAvatar[0]?.image} alt="" className="img-fluid" />
                                                    </div>
                                                    <span>EBook Collector</span>
                                                </li>
                                                <li>
                                                    <div className="image">
                                                        <img src={base + task?.avatar[0]?.artAvatar[0]?.image} alt="" className="img-fluid" />
                                                    </div>
                                                    <span>Art Collector</span>
                                                </li>
                                                <li>
                                                    <div className="image">
                                                        <img src={base + task?.avatar[0]?.gamesAvatar[0]?.image} alt="" className="img-fluid" />
                                                    </div>
                                                    <span>Game Collector</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div> : ""}
            </div>
        </>
    );
}
export default SingleReward;