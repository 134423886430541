import { GET_ENABLED_PROMOTION, SET_LOADER, GET_PROMOTION } from "../actionTypes";
import { apiHelper } from "../apiHelper";
import { ENV } from "./../../config/config";
let token = localStorage.getItem("token")
let encToken = ENV.encryptToken(token)


/////////////////GET ENABLED PROMOTION///////////////////


export const getEnabledPromotion = (type) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        token = localStorage.getItem("token")
        encToken = ENV.encryptToken(token)

        let res = await apiHelper("get", `/v1/notes/promotion/${type}/?enabled=true`, "", encToken)
        if (res?.data) {
            let { data } = res
            dispatch({
                type: GET_ENABLED_PROMOTION,
                payload: data
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            });
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const getPromotions = (type) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        let res = await apiHelper("get", `/v1/promotion/enabled`, "",encToken)
        if (res?.data) {
            let { data } = res
            dispatch({
                type: GET_PROMOTION,
                payload: data.promotions
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            });
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const setPromotions = (type) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        dispatch({
            type: GET_ENABLED_PROMOTION,
            payload: []
        })
        dispatch({
            type: SET_LOADER,
            payload: false
        });

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
/////////////////GET ENABLED PROMOTION///////////////////
