import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { getQueryParam } from '../../../../config/helper'
import { getUserPoints } from "../../../../redux/user/action";
function PointsDistribution(props) {
    let location = useLocation();
    const points = useSelector(state => state.userData.points)
    const [utility, setUtility] = useState(0)
    const [social, setSocial] = useState(0)
    const [contest, setContest] = useState(0)
    const [daily, setDaily] = useState(0)
    useEffect(() => {
        pointsFunction()
    }, [points])
    let pointsFunction = () => {
        setUtility(0)
        setSocial(0)
        setDaily(0)
        setContest(0)
        points?.forEach(element => {
            if (element._id.trim() === 'utility') {
                setUtility(element.points)
            }
            if (element._id.trim() === 'social') {
                setSocial(element.points)
            }
            if (element._id.trim() === 'contest') {
                setContest(element.points)
            }
            if (element._id.trim() === 'daily') {
                setDaily(element.points)
            }
        });
    }

    return (
        <div className="points-list">
            <div className="row">
                <div className="col-6 col-xl-3 points-list-col mb-2">
                    <Link className="utility-points-item-bg" to={`/history/utility?route=Utility Points`}>
                        <div className="utility-points-bg">
                            <h3 className="">{utility && utility}</h3>
                            <h5 style={{color: "#000"}}>Utility Points</h5>
                        </div>
                    </Link>
                </div>

                <div className="col-6 col-xl-3 points-list-col mb-2">
                    <Link className="social-points-item-bg utility-points-item-bg" to={`/history/social?route=Social Points`}>
                        <div className="social-points-bg">
                            <h3 className="">{social && social}</h3>
                            <h5 style={{color: "#000"}}>Social Points</h5>
                        </div>
                    </Link>
                </div>

                <div className="col-6 col-xl-3 points-list-col mb-2">
                    <Link className="contest-points-item-bg utility-points-item-bg" to={`/history/contest?route=Contest Points`}>
                        <div className="contest-points-bg">
                            <h3 className="">{contest && contest}</h3>
                            <h5>Contest Points</h5>
                        </div>
                    </Link>
                </div>

                <div className="col-6 col-xl-3 points-list-col mb-2">
                    <Link className="daily-points-item-bg utility-points-item-bg" to={`/history/daily?route=Daily Points`}>
                        <div className="daily-points-bg">
                            <h3 className="">{daily && daily}</h3>
                            <h5>Daily Points</h5>
                        </div>
                    </Link>
                </div>

            </div>
        </div>
    );
}

export default PointsDistribution;