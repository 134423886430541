import {WALLET_PROVIDER, CURRENT_USER, CLEAR_CURRENT_USER, WALLET_LOADER} from '../actionTypes';

export const setWalletProvider = (walletType) => async (dispatch) => {
    try {
        dispatch({
            type: WALLET_PROVIDER,
            payload: walletType
        })

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}

export const setCurrentUser = (walletAdd) => async (dispatch) => {
    try {
        dispatch({
            type: CURRENT_USER,
            payload: walletAdd
        })

    } catch (error) {
        console.log(error)
        dispatch({
            type: WALLET_LOADER,
            payload: false
        })
        // toast.error(error.response.message)
    }
}

export const clearCurrentUser = () => async (dispatch) => {
    try{
        dispatch({
            type: CLEAR_CURRENT_USER
        })
    } catch (error) {
        console.log(error);
    }
}

export const setWalletLoader = (data) => async (dispatch) => {
    try{
        dispatch({
            type: WALLET_LOADER,
            payload: data
        })
    } catch (error) {
        console.log("Error: ", error);
        dispatch({
            type: WALLET_LOADER,
            payload: false
        })
    }
}