import React from 'react'
import { useEffect, useState } from 'react';
import { getGiftCard } from '../../../../../redux/user/action';
import { useDispatch, useSelector } from "react-redux";
import { Table } from 'react-bootstrap'
import FullPageLoader from '../../../../loader/loader';
import GamificationLoader from '../../../../gamificationLoader/GamificationLoader';

const ListGiftCards = () => {
    const giftCards = useSelector(state => state.giftCardData.giftCards);
    const singleGiftCard = useSelector(state => state.giftCardData.singleCard);
    const loaderA = useSelector(state => state.giftCardData.loaderActive);
    let token = localStorage.getItem("token")
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(getGiftCard());
        }
    }, [singleGiftCard, token]);

    return (
        <>
            {loaderA ? <GamificationLoader /> : null}
            <div className="giftcards-list">
                <div className="container">
                    <div className="giftcard-table">
                        <div className="title">
                            <h2>Discount Coupons</h2>
                        </div>
                        {/* <Table responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {giftCards && giftCards.length > 1 ? giftCards.map((card, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{card.name}</td>
                                            <td>{card.price}%</td>
                                            <td>{card.description}</td>
                                        </tr>
                                    )
                                }) : ""}

                            </tbody>
                        </Table> */}

                    </div>
                    <div className="row">
                        {giftCards && giftCards.length > 0 ? giftCards.map((card, key) => {
                            return (
                                <div className="col-lg-6 col-xl-6 col-xxl-4 mb-5">
                                    <div className="gift-card">
                                        <div className="card-wrapper">
                                            <h2><span className="vibes">{card.name}</span><br />VOUCHER</h2>
                                            <div className="value-wrapper">
                                                <label>DISCOUNT<br /> VALUE</label>
                                                <span>{card.discount}%</span>
                                            </div>
                                            <p>{card.description}<br /> accumsan.</p>
                                        </div>

                                    </div>

                                </div>
                            )
                        }) : ""}
                    </div>

                </div>
            </div>

        </>
    )

}
export default ListGiftCards;