import { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { ENV } from "../../config/config"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getUserStatus, editUserStatus, RegisterUser } from "../../redux/user/action"
import { postEvent } from "./../../redux/uploadContest/action"
import { toast } from "react-toastify";
import FullPageLoader from '../loader/loader';
import Promotion from '../promotion/promotion';
import { getQueryParam } from '../../config/helper';
import Popup from "../popup/popup"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from "react-share";
import { getEnabledPromotion } from "../../redux/promotions/action";
import PlaceholderImg from '../../assets/images/placeholder-copy.png';
import GamificationLoader from "../../components/gamificationLoader/GamificationLoader";
import { ImagePicker } from "../formData/formData"

import NewUserModel from './NewUserModel';
import { useLocation, useHistory } from 'react-router-dom';
function Settings(props) {
    const customId = "custom-id-yes";

    let history = useHistory();
    const location = useLocation();
    const user = useSelector(state => state.userData?.profile)
    let new_signup = getQueryParam(location, 'signup')
    const dispatch = useDispatch();
    let promotions = useSelector(state => state?.promotions?.promotion);

    useEffect(() => {
        dispatch(getEnabledPromotion("settings"));
    }, [])
    let address = ENV.getUserKey("WalletAddress")
    let userId = ENV.getUserKey("userId")


    const success = useSelector(state => state.userData.success)
    let loaderA = useSelector(state => state.userData.loaderActive)
    const [name, setName] = useState(user?.name ? user?.name : "")
    const [firstName, setFirstName] = useState(user?.firstName ? user?.firstName : "")
    const [lastName, setLastName] = useState(user?.lastName ? user?.lastName : "")
    const [avatar, setAvatar] = useState("")
    const [avatarName, setAvatarName] = useState(user?.picture ? user?.picture : "")
    const [about, setAbout] = useState(user?.about ? user?.about : "")
    const [errors, setErrors] = useState("")
    const [copied, setCopied] = useState(false)
    const [email, setEmail] = useState("")
    const [buttonLoader, setButtonLoader] = useState(false)
    const [imageLoader, setImageLoader] = useState(false)
    const [codeCopied, setCodeCopiedopied] = useState(false)
    const [saveChanges, setSaveChanges] = useState(false);
    let code = getQueryParam(location, 'code')
    useEffect(() => {
        if (codeCopied) {
            toast.success("Referral code copied to clipboard!", { toastId: customId })
            setCopied(false)
        }
    }, [codeCopied])

    useEffect(() => {
        if (copied) {
            toast.success("Wallet address copied to clipboard ", { toastId: customId })
            setCopied(false)
        }
    }, [copied])
    useEffect(() => {
        if (user) {
            if (user?.name) {
                setName(user?.name)
            }
            if (user?.firstName) {

                setFirstName(user?.firstName)
            }
            if (user?.lastName) {

                setLastName(user?.lastName)
            }
            if (user?.picture) {

                setAvatarName(user?.picture)
            }
            if (user?.about) {

                setAbout(user?.about)
            }
            if (user?.email) {

                setEmail(user?.email)
            }
        }
        if (!user) { dispatch(getUserStatus({ address })) }
    }, [user?.inviteCode, user])


    const editProfile = async (e) => {
        e.preventDefault();
        const alph = /^[a-z A-Z]+$/;
        let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (firstName === "" || firstName === null || firstName === undefined) {
            setErrors("First name is required!");
        }
        else if (lastName === "" || lastName === null || lastName === undefined) {
            setErrors("Last name is required!");
        }
        else if (firstName === lastName) {
            setErrors("First Name and Last name can't be same!");
        }
        else if (name === "" || name === null || name === undefined) {
            setErrors("User Name is required!");
        }
        // else if (!name.match(alph)) {
        //     setErrors("Name can't contain numbers or special characters!");

        // }
        else if (email === "" || email === null || email === undefined) {
            setErrors("Email is required!");

        } else if (!email.match(emailReg)) {
            setErrors("Please enter a valid email !");
        }
        // else if (avatarName === "") {
        //     setErrors("Picture is required!");
        // }
        else if (about === "") {
            setErrors("About is required!");
        } else if (about.length > 300) {
            setErrors("About should be lesser than 300 characters!")
        } else {
            setErrors("");
            if (saveChanges) {
                var formData = {
                    name: name,
                    firstName: firstName,
                    lastName: lastName
                };
                if (email !== "") {
                    let eventData = {
                        "eventName": "email entered",
                        "userId": address
                    }

                    dispatch(postEvent(eventData))
                    // toast.success("Profile Updated Successfully!")
                }

                formData.email = email
                formData.about = about;
                if (address) {
                    formData.address = address
                }
                if (userId) {
                    formData.userId = userId;
                }

                formData.email = email
                formData.about = about;
                if (avatarName) {
                    formData.picture = avatarName
                }
                if (address) {
                    formData.address = address
                }
                if (userId) {
                    formData.userId = userId;
                }

                let payload = {
                    userId: address,
                    formData: formData
                }
                setButtonLoader(true)
                let res = await dispatch(editUserStatus(payload))
                setButtonLoader(false)
                if (res?.data?.code === 409) {
                    toast.error("username already exists")
                }
                else {
                    history.push(`/dashboard?address=${address}`)
                    if (res?.data?.sucess) {
                        toast.success("Profile Updated Successfully!")
                    }
                }
                setSaveChanges(false);
            }
        }
    }
    return (
        <>
            <Popup data={promotions} />
            {!user?.name && !user?.email && new_signup && <NewUserModel />}

            {/* <div className="page-title">
                <h2>Settings</h2>
            </div> */}

            <div className="setting-wrapper">
                {user?.inviteCode ?
                    <div className="address-wrapper">
                        <div className="copy-wrapper">
                            <p>{window.location.origin + '/invite/' + user?.inviteCode}</p>
                            <CopyToClipboard text={window.location.origin + '/invite/' + user?.inviteCode}
                                onCopy={() => setCodeCopiedopied(true)}>
                                <div className="copy-icon">
                                    <i className="fa fa-copy"></i>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                    :
                    ""}
                {/* <div className="share-btns">
                    <label>Share on : </label>
                    <div>
                        <FacebookShareButton
                            url={"gamification.arhamsoft.org" + '/invite/' + user?.inviteCode}
                            quote={"please use the following URL for signing in "}
                            hashtag={"#Myntist"}
                            description={"------------------------No desc"}
                            className="Demo__some-network__share-button"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton
                            title={"please use the following URL for signing in "}
                            url={"gamification.arhamsoft.org" + '/invite/' + user?.inviteCode}
                            hashtags={["Gamification", "gamification"]}
                        >
                            <TwitterIcon size={32} round />

                        </TwitterShareButton>
                    </div>
                </div> */}
                <div className="wallet-address">
                    <strong>Wallet Address</strong>
                    <div className="field-wrapper">
                        <p>{address && address}</p>
                        <CopyToClipboard text={address && address} onCopy={() => setCopied(true)}>
                            <div className="copy-icon">
                                <i className="fa fa-copy"></i>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className="personal-info">
                    <strong>
                        <span>Personal Information</span>
                        <span><FontAwesomeIcon icon={faPencil} name="edit" /></span>
                    </strong>
                    <div className="information">
                        <div className="image">
                            <ImagePicker setImageLoader={setImageLoader} placeholder="Add thumbnail" setSaveChanges={setSaveChanges} setAvatar={setAvatar} setAvatarName={setAvatarName} name={'image'} image={user?.picture ? user?.picture : '../../assets/images/placeholder-copy.png'} />
                            {/* <img src={user?.picture ? ENV.media_url + user.picture : PlaceholderImg} alt="Profile" className="img-fluid" onChange={onChangeImage} /> */}
                        </div>
                        <Form>
                            {/* <Form.Group controlId="formFile" className="mb-4">
                                <Form.Label>Profile Image</Form.Label>
                                <Form.Control type="file" name={avatarName} accept="image/*" onChange={onChangeImage} />
                            </Form.Group> */}
                            <Form.Group className="mb-md-3 d-md-flex" controlId="formBasicName">
                                <div className='me-md-1 flex-1 mb-md-0 mb-2'>
                                    <label>First Name <span>*</span></label>
                                    <Form.Control type="text" placeholder="First Name" name="name" value={firstName} onChange={(e) => { setSaveChanges(true); setFirstName(e.target.value.trimStart()) }} />
                                </div>
                                <div className='ms-md-1 flex-1 mb-md-0 mb-2'>
                                    <label>Last Name <span>*</span></label>
                                    <Form.Control type="text" placeholder="Last Name" name="name" value={lastName} onChange={(e) => { setSaveChanges(true); setLastName(e.target.value.trimStart()) }} />
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-md-3 d-md-flex " controlId="formBasicName">
                                <div className='me-md-1 flex-1 mb-md-0 mb-2'>
                                    <label>User Name <span>*</span></label>
                                    <Form.Control type="text" placeholder="Your Name" name="name" value={name} onChange={(e) => { setSaveChanges(true); setName(e.target.value.trimStart().replace(/\s/g, '')) }} />
                                </div>
                                <div className='ms-md-1 flex-1 mb-md-0 mb-2'>
                                    <label>Email <span>*</span></label>
                                    <Form.Control type="email" placeholder="Your Email" name="name" value={email} onChange={(e) => { setSaveChanges(true); setEmail(e.target.value) }} />
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicDescription">
                                <label>About Me <span>*</span></label>
                                <Form.Control as="textarea" rows="4" type="text" name="description" placeholder="About me" value={about} onChange={(e) => { setSaveChanges(true); setAbout(e.target.value) }} />
                            </Form.Group>
                            {errors ? (
                                <div
                                    style={{ color: "#FE6E00" }}
                                    className="alert alert-danger"
                                >
                                    {errors}
                                </div>
                            ) : (
                                ""
                            )}
                            <Button className='main-btn-style d-block' desabled={buttonLoader || imageLoader} onClick={(e) => { editProfile(e) }}>
                                {buttonLoader ?
                                    <span class="spinner-border spinner-border-sm tags-spinner-border me-2" role="status" aria-hidden="true"></span>
                                    : ''
                                }
                                Save Changes
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
            {loaderA || imageLoader ? <GamificationLoader /> : null}
            <Promotion type='settings' />

        </>
    );
}
export default Settings;