import { React, useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { getUserStatus } from "../../redux/user/action"
import { acknowledgeActivity } from "../../redux/activity/action"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Lottie from 'react-lottie';
import SunRays from '../../assets/lottie/rays.json';
import PopupBox from '../../assets/lottie/popup-box.json';
import PopupCards from '../../assets/lottie/popup-cards.json';

function ActivityPopup(props) {
    const [show, setShow] = useState(true)
    let userData = {
        ActivityPopup: true,
    }
    const dispatch = useDispatch();
    let address = localStorage.getItem('WalletAddress')

    const handleClose = async () => {
        // let data = {
        //     eventType: props?.popups?.eventType,
        //     address: address,
        //     acknowledged: true,
        //     platform: props?.popups?.platform,
        // }
        // await dispatch(acknowledgeActivity(data))
        await dispatch(getUserStatus({ address: address, platform: "Gamification" }))
        setShow(false)
        props.setShowPopup(false)
    }

    const rayscontent1 = {
        loop: true,
        autoplay: true,
        animationData: PopupBox,
    };
    const rayscontent2 = {
        loop: true,
        autoplay: true,
        animationData: PopupCards,
    };
    const sunrays = {
        loop: true,
        autoplay: true,
        animationData: SunRays,
    };

    return (
        <div className='swal-model'>
            <Modal centered className="activity-popup" show={true} onHide={() => handleClose} >
                <div className='popup-lotties position-relative'>
                    <div className="lottie lottie-1">
                        <Lottie className="lottie" options={sunrays} />
                    </div>
                    <div className={props?.popups?.platforms === "treasurebox" || props?.popups?.platforms === "gamification" ? "lottie lottie-box lottie-2" : "lottie  lottie-cards lottie-2"}>
                        {props?.popups?.platforms === "treasurebox" || props?.popups?.platforms === "gamification" ?
                            <Lottie className="rayscontent-1" options={rayscontent1} />
                            :
                            <Lottie className="rayscontent-2" options={rayscontent2} />
                        }
                    </div>
                </div>
                <div className='popup-content'>
                    <div className='mb-4 modal-main-body'>
                        <h3>{props?.popupData?.content}</h3>
                    </div>
                    <div className='d-flex justify-content-center align-items-center flex-wrap flex-column'>
                        <a href={props?.popupData?.url} target='_blank' className='popup-btn mb-3' onClick={handleClose}>Go</a>
                        <Button className='popup-btn-close' onClick={handleClose}>Skip</Button>
                    </div>
                </div>

            </Modal>
        </div >
    )
}

export default ActivityPopup;