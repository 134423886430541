import { POST_VIDEOS , GET_CONTEST , SET_LOADER, POST_EVENT } from "../actionTypes";

const initialState = {
    video:[],
    contest:[],
    loaderActive: true,
    
}
 
const contestVideos = (state = initialState, action ) => {

    switch (action.type) {
        
        case POST_VIDEOS:
            return {
                ...state,
                videos : action.payload
            }
        case POST_EVENT:
            return {
                ...state,
                event : action.payload
        }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload
            }
        case GET_CONTEST:
            return {
                ...state,
                contest : action.payload
        }
        default:
            return state;
    }
}

export default contestVideos;