
import { ENV } from "../../../config/config"
import React, { useState, useEffect } from "react"

import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getAbout } from '../../../redux/user/action';


function About() {
    const socket = ENV.socket
    const [count, setCount] = useState(0)

    const about = useSelector(state => state.userData?.profile?.about)



    const buttonClicked = () => {
        let data = { count }
        socket.emit("counter_clicked", data)
    }
    // useEffect(() => {
    //     socket.on("counter_updated", (count) => {
    //         console.log(count);
    //         setCount(count)
    //     })
    // }, [socket])


    return (
        <div className="about-sec">
            <div className="section-dark-bg">
                <div className="heading">
                    <h4>About Me</h4>
                </div>
                {/* <button onClick={buttonClicked}>click to increment :{count}</button> */}
                {about ? <p>{about && about}</p> : <div className="not-found"><p>No about entered yet!</p></div>}
                {/* <p>{about && about}</p> */}
            </div>
        </div>
    );
}

export default About;

