import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import React, { useEffect } from "react"
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './routes'
import { PrivateRoute } from './privateRoute';
import Notifications, { notify } from "react-notify-toast";
import '@rainbow-me/rainbowkit/styles.css';
import { ENV } from "./config/config";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AppProvider } from './provider/AppProvider';
AOS.init();
const { socket } = ENV
function App() {
  // useEffect(() => {
  //   socket.on("event_created", (data) => {
  //     let notData = `${data + " event created successfully"}`
  //     notify.show(
  //       notData,
  //       "success",
  //       5000
  //     );
  //   })
  // }, [socket])
  // let WalletAddress = localStorage.getItem("WalletAddress")
  // useEffect(() => {
  //   // localStorage.setItem("showDot", false)
  //   socket.on("daliy claim", (data) => {
  //     if (data.userId == WalletAddress) {
  //       localStorage.setItem("showDot", true)
  //     }
  //   })
  // }, [socket])
  return (
    <div className="wrapper">
      <Notifications />
      <AppProvider>
        <Router >
          <Switch>
            {
              routes.map((route, index) => {
                return (
                  <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    access={route.access}
                    component={props =>
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    }
                  />
                )
              })
            }
          </Switch>
        </Router>
      </AppProvider>
    </div>
  );
}

export default App;
