import React from 'react'
import { Col, Container, Row, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faReddit, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import Logo from "../../../assets/images/logo.svg"
// import MobileDetect from 'mobile-detect';
// var md = new MobileDetect(window.navigator.userAgent);
import "./footer.css"

function footer() {
	return (
		<>
            <section className='footer-sec'>
                <div className='footer-sec-padding'>
                    <Container fluid>
                        <Row>
                            <Col lg={4}>
                                <div className='footer-wrapper pe-xl-5 pe-lg-9 me-lg-3'>
                                    <Navbar.Brand href='/' className='d-flex align-items-center'>
                                        <div className="footer-logo me-2">
                                            <img height={50} width={50} loading='lazy' src={Logo} alt="logo" className="img-fluid" />
                                            {/* <img height={50} width={50} loading='lazy' src={`https://res.cloudinary.com/doqwmwmx0/image/upload/h_50,w_50/v1676641311/assets/logo_j9mxim.svg`} alt="logo" className="img-fluid" /> */}
                                        </div>
                                        <div className='footer-logo-text text-end'>
                                            <h5>Myntist</h5>
                                            <span>Gamification</span>
                                        </div>
                                    </Navbar.Brand>
                                    <p>Aliquam faucibus, odio nec commodo aliquam, neque felis placerat dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed condimentum est.</p>
                                    <h5 className='mb-2'>Join the Community</h5>
                                    <ul className='social-links d-flex flex-wrap pe-xxl-5 pe-xl-4 pe-lg-3 mb-xxl-4 mb-3'>
                                        <a href='https://www.facebook.com/Myntist'><li className='d-flex align-items-center justify-content-center me-2 fa'><FontAwesomeIcon icon={faFacebookF} /></li></a>
                                        <a href='https://www.youtube.com/channel/UCNmA1NF0EDzG_tRiRG5o8Uw' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 yt'><FontAwesomeIcon icon={faYoutube} /></li></a>
                                        <a href='https://www.instagram.com/myntist/' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 in'><FontAwesomeIcon icon={faInstagram} /></li></a>
                                        <a href='https://twitter.com/myntist?s=20&t=e5BMBb0SqOVPtqmerNsW5A' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 tw'><FontAwesomeIcon icon={faTwitter} /></li></a>
                                        <a href='https://www.reddit.com/user/Myntist' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 re'><FontAwesomeIcon icon={faReddit} /></li></a>
                                        <a href='https://www.tiktok.com/@myntist' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 gi'><FontAwesomeIcon icon={faTiktok} /></li></a>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <Row>
                                    <Col md={4} sm={6} className="footer-links-col">
                                        <div className='footer-links-wrapper'>
                                            <h5>Important Links</h5>
                                            <ul className='footer-links-ul'>
                                                <li><a href='https://treasurebox.arhamsoft.org/'>TreasureBox</a></li>
                                                <li><a href='https://nft.myntist.com/'>NFT Marketplace</a></li>
                                                <li><a href='https://shop.myntist.com/'>Physical Goods</a></li>
                                                <li><a href='https://app.myntist.com/'>Myntist Token</a></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={6} className="footer-links-col">
                                        <div className='footer-links-wrapper'>
                                            <h5>My Account</h5>
                                            <ul className='footer-links-ul'>
                                                <li><Link to='/profile?route=Profile' >Profile</Link></li>
                                                <li><Link to='/settings?route=Settings' >Settings</Link></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={6} className="footer-links-col">
                                        <div className='footer-links-wrapper'>
                                            <h5>Company</h5>
                                            <ul className='footer-links-ul'>
                                                <li><a href='#' target="_blank">Contact Us</a></li>
                                                <li><a to='#' >Privacy Policy</a></li>
                                                <li><a to='#' >Terms and Conditions</a></li>
                                                <li><a to='#'>How It Works</a></li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <hr />
                <div className='all-right-services text-center'>
                    <p>All Right Reserved by Myntist</p>
                </div>
            </section>

        </>
	)
}

export default footer