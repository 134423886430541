import {React, useState} from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import Step1 from "../../../../assets/images/step-1.png"
import Step2 from "../../../../assets/images/step-2.png"
import Step3 from "../../../../assets/images/step-3.png"
import Layer1 from "../../../../assets/images/earning-bg-1.png"
import Layer2 from "../../../../assets/images/earning-bg-2.png"
import Layer3 from "../../../../assets/images/earning-bg-3.png"
import Layer4 from "../../../../assets/images/earning-bg-4.png"
import animationData from '../../../../assets/lottie/logo.json';
import LegitGamifi from '../legitGamifi/legitGamifi.js'
import CountUp, { useCountUp } from 'react-countup';

import "./earningSteps.css"


const EarningSteps = () => {
    useCountUp({
        ref: 'counter',
        end: 100,
        enableScrollSpy: true,
        scrollSpyDelay: 1000,
      });
      const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
  return (
    <div className='main-earning'>
        <div className='landing-wrap landing-index'>
            <div className='section-earning text-center'>
                <h2 className='title-heading'>Start earning in 5 seconds</h2>
                <ul className='d-sm-flex align-items-center justify-content-center'>
                    <li className='one'>
                        <div className='inner-points'>
                            <h2 className='d-block'>
                                <span className='me-2'><CountUp end={999} enableScrollSpy /></span><span>+</span>
                            </h2>
                            <span className='d-block inner-points-h'>Registered User</span>
                        </div>
                    </li>
                    <li  className='two'>
                        <div className='inner-points'>
                            <h2 className='d-block'>
                                <span className='me-2'><CountUp end={100} enableScrollSpy /></span><span>+</span>
                            </h2>
                            <span className='d-block inner-points-h'>TASK COMPLETED</span>
                        </div>
                    </li>
                    <li className="three">
                        <div className='inner-points'>
                            <h2 className='d-block'>
                                <span className='me-2'><CountUp end={1100} enableScrollSpy /></span><span>+</span>
                            </h2>
                            <span className='d-block inner-points-h'>TOTAL COINS EARNED</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div>
                <div className='all-steps step-1'>
                    <Row className='align-items-center'>
                        <Col xl={7} lg={6}>
                            <div className='step-content'>
                                <h2 className='title-heading'>COMPLETE TASKS</h2>
                                <p> Our platform offers a variety of tasks that you can complete and earn rewards such as badges, avatars, and points. Whether you're looking to challenge yourself, learn something new, or simply have fun, our tasks will keep you engaged and motivated. </p>
                                <p> Get a shot at winning points & badge by reaching the target audience. Also, get a chance to earn exciting rewards for number of purchases, staking feature rewards, and more. So what are you waiting for? Start earning rewards today by signing up for our platforms!</p>
                            </div>
                        </Col>
                        <Col xl={5} lg={6}>
                            <div className='step-img ms-lg-auto'>
                                <img src={Step1} alt="earning step" className='img-fluid'/>
                            </div>
                        </Col>
                    </Row> 
                </div>
                <div className='all-steps step-2'>
                    <Row className='flex-row-reverse align-items-center'>
                        <Col xl={7} lg={6}>
                            <div className='step-content'>
                                <h2 className='title-heading'>SHARE AND WIN</h2>
                                <p> Are you looking for a platform to share your content with others? Look no further than Treasurebox! Our unique platform allows you to share your content with ease, while also giving you the opportunity to earn points through various activities.</p>
                                <p> By engaging in certain activities on the platform, you can earn points that can be redeemed for exciting rewards and incentives.  It's a win-win situation: you get to showcase your content and creativity while earning rewards for your efforts.</p>
                            </div>
                        </Col>
                        <Col xl={5} lg={6}>
                            <div className='step-img'>
                                <img src={Step2} alt="earning step" className='img-fluid'/>
                            </div>
                        </Col>
                    </Row> 
                </div>
                <div className='all-steps step-3'>
                <Row className='align-items-center'>
                        <Col xl={7} lg={6}>
                            <div className='step-content'>
                                <h2 className='title-heading'>REFER a FRIEND</h2>
                                <p> By referring your friends to MYNTIST Gamification, you can earn points that you can redeem for rewards such as badges, and avatars. Plus, when your friends sign up using your referral link, they'll also earn points to help them get started.</p>
                                <p> We've made it easy for you to refer your friends and earn points. Simply share your unique referral link with your friends and family through social media, email, or any other platform you prefer. You can also complete refer tasks to earn additional points and increase your chances of earning rewards.</p>
                            </div>
                        </Col>
                        <Col xl={5} lg={6}>
                            <div className='step-img ms-lg-auto'>
                                <img src={Step3} alt="earning step" className='img-fluid'/>
                            </div>
                        </Col>
                    </Row> 
                </div>
            </div>
            <LegitGamifi/>
            {/* <StayInLoop/> */}
            <section className="stay-in-loop">
			<Container fluid>
				<div className="stay-in-loop-holder d-flex justify-content-center align-items-center">
					<div className="signup-form-holder text-white mb-0">
						<h2 className='title-heading mb-2'>Are you ready to create content, engage, and earn rewards? </h2>
						<h3 className='mb-lg-5 mb-4'>Sign up today and and join our community</h3>
						<Link to={`/login`} className='btn-style-2'>Join Now</Link>
					</div>
					<div className="lottie-animation-holder">
						<Lottie
							options={defaultOptions}
						/>
					</div>
				</div>
			</Container>
		</section>
        </div>
        <div className='layer-1'>
            <img src={Layer1} alt="layer" className='img-fluid' />
        </div>
        <div className='layer-2'>
            <img src={Layer2} alt="layer" className='img-fluid' />
        </div>
        <div className='layer-3'>
            <img src={Layer3} alt="layer" className='img-fluid' />
        </div>
        <div className='layer-4'>
            <img src={Layer4} alt="layer" className='img-fluid' />
        </div>
    </div>
  )
}

export default EarningSteps