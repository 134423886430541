import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from "react"
import MetaMaskOnboarding from "@metamask/onboarding";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { Modal, Button } from 'react-bootstrap'
import { RegisterUser } from "../redux/user/action"
import { useHistory, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import { getCurrentUser } from "../redux/user/action";
import ConnectModal from './connectModel'
// import swal from 'sweetalert2';

function Connect(props) {
    return (
        <></>

        // <ConnectModal show={true} flag={props.flag} history_url={props.history_url}/>);
)}

export default Connect;

