
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react"
import { getCollections } from "../../../redux/user/action"
import { ENV } from "../../../config/config"
import Loader from '../../loader/loader';
import { getQueryParam } from '../../../config/helper';
import Banner from "../../../assets/images/logo1.svg"
function Collections(props) {
    let location = useLocation()
    const CollectionItems = useSelector(state => state.userData.collections?.collections)
    const CollectionLink = useSelector(state => state.userData.collections?.viewAll)
    const loaderA = useSelector(state => state.userData.loaderActive)
    // let address = getQueryParam(location, 'address')
    let wallet = ENV.getUserKey("WalletAddress")

    const dispatch = useDispatch();
    // const data = {}
    useEffect(async () => {
        if (!CollectionItems && wallet) {
            dispatch(getCollections(wallet))
        }
    }, [])
    return (
        <div className="collection-sec">
            {/* { loaderA ? <Loader /> : null } */}

            <div className="section-dark-bg">
                <div className="heading">
                    <h4>My Collections</h4>
                    <a href={CollectionLink ? CollectionLink : ''} className="view-all" target="_blank">VIEW ALL</a>
                </div>
                {CollectionItems && CollectionItems?.length > 0 ?
                    <div className="collection-list">
                        <ul>
                            {
                                CollectionItems?.slice(0, 2).map((Collection, key) => {
                                    return (
                                        <li>
                                            <div className='collection-wrap'>
                                                <Link to="#">
                                                    <div className='banner-img'>
                                                        <img src={Collection?.featuredImg} alt="" className='img-fluid' />
                                                    </div>
                                                    <div className="image logo-img">
                                                        <img src={Collection?.logo} alt="" className="img-fluid" />
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='collection-text'>
                                                <h2>{ENV.capitalizeFirstLetter(Collection?.url)}</h2>
                                                <span>{ENV.capitalizeFirstLetter(Collection?.name)}</span>
                                            </div>
                                        </li>)
                                })
                            }

                        </ul>
                    </div>
                    :
                    <div className='not-found'>
                        <p>No collection to show!</p>
                    </div>
                }
            </div>

        </div>
    );
}

export default Collections;

