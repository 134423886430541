import io from 'socket.io-client'
require('dotenv').config();
const dataEncryptionKey = process.env.REACT_APP_ENC_KEY
// const token = '12131SSSSaaadsddgdgdfgdfgdfgf'

const CryptoJS = require("crypto-js");

export const ENV = {
    serverUrl: process.env.REACT_APP_SERVER_URL,
    gamificationUrl: process.env.REACT_APP_GAMIFICATION_URL,
    liveServerUrl: process.env.REACT_APP_LIVE_SERVER_URL,
    socket: io(process.env.REACT_APP_SERVER_URL, {
        cors: {
            origin: process.env.REACT_APP_SERVER_URL,
            credentials: true
        }, transports: ['websocket']
    }),
    Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
    x_access_token: JSON.parse(localStorage.getItem("userToken")),
    x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,
    media_url: process.env.REACT_APP_MEDIA_URL,
    saveItem: function (name, value) {
        localStorage.setItem(`${name}`, JSON.stringify(value));
    },
    removeItem: function (name) {
        localStorage.removeItem(name);
    },
    encryptUserData: function (data, token) {
        if (data) {
            localStorage.setItem('userInfo', JSON.stringify(data));
        }
        if (token) {
            localStorage.setItem('uToken', JSON.stringify(token));
        }
        return true;
    },
    decryptRes: function (userData) {
        // if (userData) {
        let bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
        let originalData = bytes.toString(CryptoJS.enc.Utf8);
        originalData = JSON.parse(originalData);

        // }
        // data = JSON.stringify(data);
        // let encryptedUser = CryptoJS.AES.encrypt(data, dataEncryptionKey);
        return originalData;
    },
    encryptPayload: function (userData) {
        var encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(userData), dataEncryptionKey).toString();
        return encryptedUser;
    },
    encryptToken: function (token) {
        var encryptedToken = CryptoJS.AES.encrypt(JSON.stringify({ token }), dataEncryptionKey).toString();
        return encryptedToken;
    },
    getUserKey: function (keys = null) {
        let userData = localStorage.getItem(keys);
        return userData;
    }, getToken: function () {
        let userData = localStorage.getItem('uToken');
        if (userData) {
            return userData;
        }
        return {};
    },
    // getHeaders: function () {
    //     let token = JSON.parse(localStorage.getItem('uToken'));
    //     let headers = {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //     };
    //     if (token) {
    //         headers["Authorization"] = "Bearer " + token;
    //         headers["access-token"] = token;
    //     }
    //     return headers;
    // },
    rearrangeEventType: function (type) {
        if (type == "tBox_viewed") {
            return "Post view"
        } else if (type == "tbox_created") {
            return "Post creation"
        } else if (type == "tBox_shared") {
            return "Post sharing"
        } else if (type == "sign_up") {
            return "Sign up"
        } else if (type == "social_followers") {
            return "social followers"
        } else if (type == "tbox_boosted") {
            return "Post boosting"
        } else {
            if (type) {
                return ENV.capitalizeFirstLetter(type?.replaceAll('_', ' '))
            }
        }
    },
    capitalizeFirstLetter: function (str) {
        return str?.charAt(0).toUpperCase() + str?.slice(1)
    },
    logout: function () {
        localStorage.setItem("logout", true);
        localStorage.removeItem('token');
        // localStorage.removeItem('userInfo');
        localStorage.removeItem('WalletAddress')
        localStorage.removeItem("twitter_user_secret")
        localStorage.removeItem("twitter_user_token")
        window.location.href = "/login"
    }
}