import React from "react";
import Header from '../components/includes/header/header';

class Layout5 extends React.Component {
    render() {
        const { children } = this.props
        return (
            <>
                <Header />
                <div className="container-fluid">
                    {children}
                </div>
            </>
        )
    }
}
export default Layout5;