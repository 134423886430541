import { GET_GIFTCARD, POST_GIFTCARD, SINGLE_GIFTCARD, UPDATE_GIFTCARD, DELETE_GIFTCARD, SET_LOADER} from '../actionTypes';

const initialState = {
    giftCards : [],
    postGiftCard: [],
    singleCard:{},
    cardDeleted:false,
    loaderActive: true
}

const giftCardReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case GET_GIFTCARD:
            return {
                ...state,
                giftCards: action.payload,
                cardDeleted:false
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload
            }
        case POST_GIFTCARD:
            return {
                ...state,
                postGiftCard: action.payload
            }
        case SINGLE_GIFTCARD:
            return {
                ...state,
                singleCard: action.payload
            }
            
        case UPDATE_GIFTCARD:
            return {
                ...state,
                singleCard: action.payload
            }
            
            case DELETE_GIFTCARD:
                return {
                    ...state,
                    cardDeleted: true
                }
        default:
            return state
    }
}

export default giftCardReducer;
