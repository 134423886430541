import { GET_NOTES, SET_LOADER } from "../actionTypes";
import { apiHelper } from '../apiHelper';
import { ENV } from "./../../config/config";
let token = localStorage.getItem("token")
let encToken = ENV.encryptToken(token)
export const getNotes = () => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })

        token = localStorage.getItem("token")
        encToken = ENV.encryptToken(token)
        let res = await apiHelper("get", `/v1/notes`, "", encToken)
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_NOTES,
                    payload: data
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}