import { Table, ButtonGroup, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { getUserStatus, getLeaderboard } from '../../redux/user/action';
import { useEffect, useState } from 'react';
import { ENV } from "./../../config/config"
import { Link, useLocation } from 'react-router-dom';
import { getQueryParam } from '../../config/helper'
import format from 'date-fns/format';
import { subDays, subMonths, fromUnixTime, set } from 'date-fns';
import PlaceholderImg from '../../assets/images/placeholder-copy.png';
import Promotion from "../promotion/promotion";
import ChatBox from './chatBox/chatBox';
import { FaRocketchat } from 'react-icons/fa';
import Pusher from 'pusher-js';
import { postChat, singleChat, getMessageNotification, readNotification, initialChat } from '../../redux/chatbox/action';
import Popup from "../popup/popup"
import { getEnabledPromotion } from "../../redux/promotions/action";
import { SkeletonLayout } from "react-skeleton-loader-pulse";


function LeaderBoard(props) {
    let location = useLocation()
    let contestId = getQueryParam(location, 'contestId') ? getQueryParam(location, 'contestId') : ''
    let promotions = useSelector(state => state?.promotions?.promotion);
    let users = useSelector(state => state.userData.user)
    let loaderA = useSelector(state => state.userData.loaderActive)
    let chat1 = useSelector(state => state?.chat?.singleChat);
    let userPoints = 0;

    let dispatch = useDispatch();
    let walletAddress = ENV.getUserKey("WalletAddress");
    let LoggedUserWallet = localStorage.getItem('WalletAddress');
    let [chat, setChat] = useState(false)
    let [chatUser, setChatUser] = useState()
    let [chatId, setChatId] = useState([])
    let [from, setFrom] = useState(subMonths(new Date(), 1))
    let [to, setTo] = useState(new Date())
    var currentTime = new Date();
    const [initialButton, setinitialButton] = useState(true);
    const getuser = useSelector(state => state.userData?.profile);
    const userNfts = useSelector((state) => state.userData.nfts);
    let notification = useSelector(state => state?.chat?.notification);
    let chatHistory = useSelector(state => state?.chat?.singleChat);
    let LogedInuser = getuser?.name;
    let [userName, setUserName] = useState(LogedInuser)
    let [recieverName, setRecieverName] = useState([])
    const [sender, setSender] = useState([])
    const [reciever, setReciever] = useState([])
    const [bindId, setbindId] = useState()
    const [unreadCount, setUnreadCount] = useState(0);
    let [pusher, setPusher] = useState([])
    let [channel, setChannel] = useState([])
    let userwalletId = [];
    let subscriptionId = [];
    let newChannel = [];
    if (users) {
        let copied;
        for (let i = 0; i < users.length; i++) {
            copied = users[i]
            if (users[i].pointsData.length > 0) {
                for (let j = 0; j < users[i].pointsData.length; j++) {
                    copied[users[i].pointsData[j].pointtype] = users[i].pointsData[j].points
                }

            }
            users[i] = copied
        }
    }
    useEffect(() => {
        // const query = { from: format(from, "yyyy-MM-dd"), to: format(new Date(currentTime.getTime() + 86400000), "yyyy-MM-dd"), loader: true }
        // const query = { from: '', to: '', filter: 'monthly', loader: true }
        // setFrom(query.from)
        // setTo(query.to)
        // dispatch(getLeaderboard(query, contestId))
        filterData('monthly')
        dispatch(getEnabledPromotion("leaderboard"));
        return () => {
            if (newChannel) {
                setChat(false)
                newChannel.unbind()
            } //whenever the component removes it will executes
        }
    }, [])

    useEffect(() => {
        if (pusher.length == 0) {
            let newPusher = new Pusher('27cbce362df659b8a71c', {
                cluster: 'ap2',
            });
            setPusher(newPusher)

            newChannel = newPusher.subscribe("chat");
            setChannel(newChannel)
        }
        if (!users) {
            dispatch(getUserStatus({ address: LoggedUserWallet }));
        }
        newChannel?.bind("notification", function (data) {
            if (data.to == LoggedUserWallet) {
                setUnreadCount(data.unreadMessages)
                setReciever(reciever => [...reciever, data.from])
            }
        });
    }, [])

    useEffect(() => {
        let wallet = localStorage.getItem('WalletAddress');
        if (users?.length > 0) {
            users?.forEach(currentUser => {
                userwalletId.push(currentUser._id)
            })
            const userData = {
                from: userwalletId,
                to: wallet
            }
            dispatch(getMessageNotification(userData))
        }
    }, [users])

    useEffect(() => {
        setSender([])
        if (notification) {
            notification.data?.forEach(notify => {
                setSender(sender => [...sender, notify.from])
            })
        }
        dispatch(initialChat())
    }, [notification])

    const chatClose = (index) => {
        setChat(index);
        setChatId('');
    }

    const getEventId = (id) => {
        setbindId(id);
    }
    const handleCustomRanking = (date, e) => {
        setFrom(date[0])
        let dateRange = new Date((date[1]).getTime() + 86400000);
        setTo(dateRange)
        const query = { from: format(date[0], "yyyy-MM-dd"), to: format(dateRange, "yyyy-MM-dd"), loader: false }
        dispatch(getLeaderboard(query, contestId))
    }

    const handleRanking = (date) => {
        setinitialButton(false)
        setFrom(date[0])
        setTo(date[1])
        const query = { from: format(date[0], "yyyy-MM-dd"), to: format(date[1], "yyyy-MM-dd"), loader: true }
        dispatch(getLeaderboard(query, contestId))
    }

    const handleClearRanking = (e) => {
        const query = { from: '', to: '', loader: false }
        setFrom(query.from)
        setTo(query.to)
        dispatch(getLeaderboard(query))
    }

    const handleChatBox = async (e, id, name) => {
        e.preventDefault()
        resetNotificationCount(id)
        setChatId(id)
        setUserName(LogedInuser)
        setRecieverName(name)
        let walletAddress = localStorage.getItem('WalletAddress');
        var eventId = id.slice(id.length - 4, id.length) + "-" + walletAddress.slice(walletAddress.length - 4, walletAddress.length);
        const payload = {
            username: walletAddress,
            message: "subscribe",
            createdAt: "12/12/2000",
            eventId: eventId,
            createdAt: "12/12/2012",
            from: walletAddress,
            to: id
        }
        dispatch(postChat(payload));
        if (chat) {
            channel.unbind(bindId)
            resetNotificationCount(chatId)
            setChat(false)
            setChatId(id)
            dispatch(initialChat())
            setTimeout(() => {
                setChat(true)
            }, 500);
        }
        else {
            const readMessages = {
                from: id,
                to: walletAddress
            }
            setChatId(id)
            dispatch(readNotification(readMessages));
            resetNotificationCount(id)
            setTimeout(() => {
                setChat(true)
            }, 500);
        }

    }
    const resetNotificationCount = (id) => {

        const readMessages = {
            from: id,
            to: walletAddress
        }
        dispatch(readNotification(readMessages));
        let senderArray = sender.filter(a => a !== id)
        setSender(senderArray)
        let recieverArray = reciever.filter(a => a !== id)
        setReciever(recieverArray)
    }
    const getAllSum = (user) => {
        let userPoints = 0;

        if (user) {
            let pointsData = user?.pointsData ? user?.pointsData : 0;
            for (let index = 0; index < pointsData.length; index++) {
                userPoints = pointsData[index].points + userPoints;
            }
        }
        return (userPoints)
        // return ((user?.tbox_created ? user.tbox_created : 0) +
        //     (user?.tBox_share ? user.tBox_share : 0) +
        //     (user?.tBox_shared ? user.tBox_shared : 0) +
        //     (user?.tBox_viewed ? user.tBox_viewed : 0) +
        //     (user?.social_followers ? user.social_followers : 0) +
        //     (user?.sign_up ? user.sign_up : 0) +
        //     (user?.referring ? user.referring : 0) +
        //     (user?.tbox_boosted ? user.tbox_boosted : 0))
    }
    function nth(n) { return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th" }

    const filterData = (filter) => {
        const query = {
            filter: filter ? filter : 'monthly',
            loader: true
        }
        dispatch(getLeaderboard(query, contestId))
    }

    return (
        <>
            <Popup data={promotions} />
            {chat ?
                <>
                    <div className='chat-close-component'>
                        <ChatBox pusher={pusher} channel={channel} getEventId={getEventId} chatClose={chatClose} resetNotification={resetNotificationCount} user={chatId} userName={userName} reciever={recieverName} />
                    </div>
                </> : ""}
            <div className="main-leaderboard">
                <div className="table-wrapper">
                    {/* <h2>LEADERBOARD</h2> */}
                    {/* <div className="filters">
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="secondary" onClick={() => handleRanking([new Date(), new Date(currentTime.getTime() + 86400000)])}>Daily</Button>
                            <Button variant="secondary" onClick={() => handleRanking([subDays(new Date(), 7), new Date(currentTime.getTime() + 86400000)])}>Weekly</Button>
                            {initialButton ? <Button className="" variant="secondary" onClick={() => handleRanking([subMonths(new Date(), 1), new Date(currentTime.getTime() + 86400000)])}>Monthly</Button> : <Button variant="secondary" onClick={() => handleRanking([subMonths(new Date(), 1), new Date(currentTime.getTime() + 86400000)])}>Monthly</Button>}
                        </ButtonGroup>
                        <DateRangePicker className="custom-picker" placeholder="Select Date Range" value={[from, to]} onOk={handleCustomRanking} onClean={handleClearRanking} editable={false} character={'-'}/>
                    </div> */}
                    {/* <button type='button' className='btn btn-gradient-pink me-2' onClick={() => handleRanking([new Date(), new Date()])}>Daily</button>
                <button type='button' className='btn btn-gradient-pink me-2' onClick={() => handleRanking([subDays(new Date(), 7), new Date()])}>Weekly</button>
                <button type='button' className='btn btn-gradient-pink me-5' onClick={() => handleRanking([subMonths(new Date(), 1), new Date()])}>Monthly</button> */}
                    {users && users.length > 0 ? users.filter(user => user._id == walletAddress).map((filtered, key) => {
                        return (<>
                            {
                                filtered?.rank ? (filtered.rank) + nth(filtered.rank) : ((users.findIndex(user => user._id == walletAddress)) + 1) + nth((users.findIndex(user => user._id == walletAddress)) + 1)
                            }
                        </>)
                    }) : ""}

                    <button onClick={(e) => { filterData('weekly') }}>weekly</button>
                    <button onClick={(e) => { filterData('monthly') }}>Monthly</button>
                    <Table responsive className='leader-board-header'>
                        <thead>
                            <tr className='forth'>
                                <th className="" style={{ textAlign: 'center' }}>RANK</th>
                                <th>NAME</th>
                                <th>BADGES</th>
                                <th>AVATARS</th>
                                <th>NFTS</th>
                                <th>ASSETS</th>
                                <th>POINTS</th>
                                <th>CHAT</th>
                            </tr>
                        </thead>

                        <tbody>
                            {loaderA ? <SkeletonLayout
                                align="center"
                                color="#000000"
                                items={[
                                    {
                                        height: 70,
                                        marginBottom: 20,
                                        marginTop: 0,
                                        marginRight: 0,
                                        marginLeft: 0,
                                        width: "100%",
                                        borderRadius: "3px",

                                    },
                                    {
                                        height: 70,
                                        marginBottom: 20,
                                        marginTop: 0,
                                        marginRight: 0,
                                        marginLeft: 0,
                                        width: "100%",
                                        borderRadius: "3px",

                                    },
                                    {
                                        height: 70,
                                        marginBottom: 20,
                                        marginTop: 0,
                                        marginRight: 0,
                                        marginLeft: 0,
                                        width: "100%",
                                        borderRadius: "3px",

                                    },
                                    {
                                        height: 70,
                                        marginBottom: 20,
                                        marginTop: 0,
                                        marginRight: 0,
                                        marginLeft: 0,
                                        width: "100%",
                                        borderRadius: "3px",

                                    },
                                    {
                                        height: 70,
                                        marginBottom: 20,
                                        marginTop: 0,
                                        marginRight: 0,
                                        marginLeft: 0,
                                        width: "100%",
                                        borderRadius: "3px",

                                    },
                                    {
                                        height: 70,
                                        marginBottom: 20,
                                        marginTop: 0,
                                        marginRight: 0,
                                        marginLeft: 0,
                                        width: "100%",
                                        borderRadius: "3px",

                                    },
                                ]}
                            /> :

                                <>

                                    {users && users.length > 0 ? users.map((user, key) => {
                                        return (
                                            <>
                                                <tr key={key} className={`${key == 0 ? 'first' : "" || key == 1 ? 'second' : "" || key == 2 ? 'third' : "" || key > 2 ? '' : ""} ${user?._id == LoggedUserWallet ? "user-record" : ""} `}>
                                                    <td style={{ textAlign: 'center' }}>{user?.rank ? (user.rank) + nth(user.rank) : (key + 1) + nth(key + 1)}</td>
                                                    <td>
                                                        <div className="user-details">
                                                            <div className="image">
                                                                {user?.picture?.length > 0 ? <img src={process.env.REACT_APP_SERVER_URL + '/media/' + user?.picture} alt="User" className="img-fluid" /> : <img src={PlaceholderImg} alt="User" className="img-fluid" />}
                                                            </div>
                                                            <div className="user-info">
                                                                <h5><strong>{user?.name}</strong> {user?._id == LoggedUserWallet ? <span> (You)</span> : ""}</h5>
                                                                <span>ID: {user._id?.slice(user._id.length - 4, user._id.length).toUpperCase()}</span>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="badges">
                                                            <ul>
                                                                {user?.badges.length > 0 ?
                                                                    user?.badges.length < 3 ?
                                                                        user.badges.map((badge, key) => {
                                                                            return (
                                                                                <li key={key}>
                                                                                    <img src={process.env.REACT_APP_SERVER_URL + '/media/' + badge.image} alt="Diamond" className="img-fluid" />{user?.badges.length <= 3 ? "" : "+" + user?.badges.length - 3}
                                                                                </li>
                                                                            )
                                                                        }) : <>{user.badges.slice(0, 2).map((badge, key) => {
                                                                            return (
                                                                                <li key={key}>
                                                                                    <img src={process.env.REACT_APP_SERVER_URL + '/media/' + badge.image} alt="Diamond" className="img-fluid" />
                                                                                </li>
                                                                            )
                                                                        })}<li>+{user?.badges.length - 2}</li> </>
                                                                    : ""}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="badges">
                                                            <ul>
                                                                {user?.avatars?.length > 0 ?
                                                                    user?.avatars?.length < 3 ?
                                                                        user.avatars?.map((avatar, key) => {
                                                                            return (
                                                                                <li key={key}>
                                                                                    <img src={process.env.REACT_APP_SERVER_URL + '/media/' + avatar.name} alt="Diamond" className="img-fluid" />{user?.avatars.length <= 3 ? "" : "+" + user?.avatars.length - 3}
                                                                                </li>
                                                                            )
                                                                        }) : <>{user.avatars.slice(0, 2).map((avatar, key) => {
                                                                            return (
                                                                                <li key={key}>
                                                                                    <img src={process.env.REACT_APP_SERVER_URL + '/media/' + avatar.name} alt="Diamond" className="img-fluid" />
                                                                                </li>
                                                                            )
                                                                        })}<li>+{user?.avatars.length - 2}</li> </>
                                                                    : ""}
                                                            </ul>
                                                        </div>
                                                    </td>

                                                    <td>{user?.nftsCount > 0 ? user?.nftsCount : 0}</td>
                                                    <td>{(user?.nftsCount > 0 ? user?.nftsCount : 0) + (user?.badges?.length > 0 ? user?.badges?.length : 0) + (user?.avatars?.length > 0 ? user?.avatars?.length : 0)}</td>
                                                    <td>{getAllSum(user)}</td>
                                                    <td>
                                                        {LoggedUserWallet !== user._id ? <Button className='messageButton' onClick={(e) => handleChatBox(e, user._id, user.name)}><FaRocketchat /> {(chatId !== user._id) && ((notification && sender.includes(user._id)) || (unreadCount > 0 && reciever.includes(user._id))) ? <span className={'button__badge'}>{unreadCount && reciever.includes(user._id) ? unreadCount : sender.filter(v => v === user._id).length}</span> : ""}</Button> : ""}
                                                    </td>

                                                </tr>
                                            </>
                                        )

                                    }) : "No user found!"}
                                </>
                            }
                        </tbody>

                    </Table>
                </div >
            </div >
            <Promotion type='LeaderBoard' />

        </>
    );
}

export default LeaderBoard;