import { useState, useEffect } from 'react';
import { useLocation,useHistory } from 'react-router-dom';
import { getQueryParam } from '../../config/helper';
import React from 'react';
import { apiHelper } from '../../redux/apiHelper'
import { postEvent, postFollowersEvent, postShared } from "../../redux/user/action";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { transformation } from "../../config/helper"

function TwitterShare(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  // let [searchParams, setSearchParams] = useSearchParams();
  let oauth_token = getQueryParam(location, 'oauth_token')
  let eventId = getQueryParam(location, 'eventId')
  let comingSoon = getQueryParam(location, 'comingSoon')
  let oauth_verifier = getQueryParam(location, 'oauth_verifier')
  let twitter_user_token = localStorage.getItem("twitter_user_token")
  let twitter_user_secret = localStorage.getItem("twitter_user_secret")
  const [sharing, setSharing] = useState(false)
  const [connect, setConnect] = useState(false)
  const [loginExpired, setLoginExpired] = useState(twitter_user_secret && twitter_user_secret !== 'undefined' ? false : true)
  let address = localStorage.getItem("WalletAddress")
  const [postCaption, setPostCaption] = useState(props.postCaption);
  const [imageUrl, setImageUrl] = useState(props.imageUrl);
  useEffect(() => {
    if (props.imageUrl) {


      //   let transform = transformation(props.imageUrl, "twitter")
      if (props?.imageUrl) {
        setImageUrl(props.imageUrl)
      }


      // if (props.imageUrl?.split("upload/")[0].includes('image')) {
      //   setImageUrl(props.imageUrl?.split("upload/")[0] + 'upload/q_auto/' + props.imageUrl?.split("upload/")[1])
      // }
      // if (props.imageUrl?.split("upload/")[0].includes('video')) {
      //   setImageUrl(props.imageUrl?.split("upload/")[0] + 'upload/q_70,eo_130,so_0.0/' + props.imageUrl?.split("upload/")[1])
      // }
    }

  }, [props.imageUrl])
  const handleauth = async () => {
    setConnect(true)
    // const res = await axios.post(`http://localhost:4000/oauthTwitterLink`)
    let res = ''
    if (props.taskType && props.taskType !== null) {
      res = await apiHelper("get", `/v1/social/twitter/oauth?address=${address}`, "")
    } else if (comingSoon && comingSoon !== undefined) {
      res = await apiHelper("get", `/v1/social/twitter/oauth?comingSoon=true`, "")
    } else {
      res = await apiHelper("get", `/v1/social/twitter/oauth?eventId=${eventId}`, "")
    }
    setLoginExpired(true)
    localStorage.setItem("oauth_token_secret", res.data.oauth_token_secret)
    localStorage.setItem("oauth_token", res.data.oauth_token)
    localStorage.setItem("oauth_verifier", res.data.oauth_callback_confirmed)
    setConnect(false)
    window.location.href = res.data.url
  }
  const callbackLink = async () => {
    let userAuthResponce = ''
    let userDetails = ''
    setSharing(true)
    let oauth_token_secret = localStorage.getItem('oauth_token_secret')
    if (!twitter_user_token || !twitter_user_secret || twitter_user_secret == '' || twitter_user_token == '' || twitter_user_secret == 'undefined' || twitter_user_token == 'undefined') {
      userAuthResponce = await apiHelper("get", `/v1/social/twitter/redirect?oauth_token=${oauth_token}&&oauth_token_secret=${oauth_token_secret}&&oauth_verifier=${oauth_verifier}`, '');
      localStorage.setItem("twitter_user_token", userAuthResponce?.data?.accessToken)
      localStorage.setItem("twitter_user_secret", userAuthResponce?.data?.accessSecret)
    }
    if (!twitter_user_token || !twitter_user_secret || twitter_user_secret == '' || twitter_user_token == '' || twitter_user_secret == 'undefined' || twitter_user_token == 'undefined' ? userAuthResponce?.data?.accessToken && userAuthResponce?.data?.accessSecret : twitter_user_token && twitter_user_secret) {
      setLoginExpired(false)
      if (!twitter_user_token || !twitter_user_secret || twitter_user_secret == '' || twitter_user_token == '' || twitter_user_secret == 'undefined' || twitter_user_token == 'undefined') {
        userDetails = await apiHelper("get", `/v1/social/twitter/share?postUrl=${imageUrl}&&postTitle=${postCaption}&&oauth_token=${userAuthResponce?.data?.accessToken}&&oauth_token_secret=${userAuthResponce?.data?.accessSecret}`, '');
      }
      else {
        userDetails = await apiHelper("get", `/v1/social/twitter/share?postUrl=${imageUrl}&&postTitle=${postCaption}&&oauth_token=${twitter_user_token}&&oauth_token_secret=${twitter_user_secret}`, '');
      }
      if (userDetails && userDetails !== '' && userDetails?.status == 200 && userDetails?.data?.mimeType == "video/mpeg4-generic") {
        await callbackLink()
      } else {
        if (userDetails && userDetails !== '' && userDetails?.status == 200 && userDetails?.data?.tweet?.data?.id && userDetails?.data?.profileData?.data?.public_metrics) {

            // let data = {
            //   eventName: "coming soon",
            //   userId: localStorage.getItem("WalletAddress"),
            // }
          //   let folloerData = {
          //     friendsCount: userDetails?.data?.user?.followers_count,
          //     userId: localStorage.getItem("WalletAddress"),
          //     socialPlatform: 'twitter'
          //   }
          //   let sharedData = {
          //     postId: postId,
          //     walletAddress: localStorage.getItem("WalletAddress"),
          //     shared: true
          //   }
          toast.success('Post is shared to Twitter Successfully!')
          if(comingSoon){
            let data = {
              eventName: "redeeming",
              userId: localStorage.getItem("WalletAddress"),
            }
            dispatch(postEvent(data))
            history.push('/daily-rewards?route=Daily Claim')
          }
          //   dispatch(postShared(sharedData))
          //   dispatch(postFollowersEvent(folloerData))
        } else {
          toast.error('An error has occurred while making a tweet!')
        }
      }

    }
    else {
      setLoginExpired(true)
      toast.error('Access denied please connect Twitter!')
    }
    setSharing(false)
  }
  return (
    <>
      {!loginExpired || oauth_verifier ?
        <button className='btn action-btn twitter' disabled={sharing} onClick={callbackLink}>

          <FontAwesomeIcon className={sharing ? 'me-sm-1  sharing-grow spinner-grow' : "me-sm-1"} icon={faTwitter} />

          <span className='d-sm-block d-none'>
            {sharing ? 'Sharing..' : 'Twitter'}
          </span>
        </button>
        :
        <button className='btn action-btn twitter' disabled={sharing || connect} onClick={handleauth}>
          <FontAwesomeIcon className={sharing ? 'me-sm-1 sharing-grow spinner-grow' : "me-sm-1"} icon={faTwitter} />
          <span className='d-sm-block d-none'>
            {connect ? "Connecting.." : "Connect Twitter"}
          </span>
        </button>
      }

    </>
  )
}

export default TwitterShare








