import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Lottie from 'react-lottie';
import Trophy from '../../../../assets/lottie/trophy.json';
import Points from '../../../../assets/lottie/points.json';
import Tasks from '../../../../assets/lottie/tasks.json';

import "./legitGamifi.css"

function legitGamifi() {
    const firstLottie = {
        loop: true,
        autoplay: true,
        animationData: Tasks,
    };
    const secondLottie = {
        loop: true,
        autoplay: true,
        animationData: Trophy,
    };
    const thirdLottie = {
        loop: true,
        autoplay: true,
        animationData: Points,
    };
  return (
    <>
        <div className='gamifi-legit py-4 mb-xl-5 mb-sm-4 mb-2'>
            <h2 className='text-center title-heading'>is gamification legit?</h2>
            <Row>
                <Col lg={4} className="mb-lg-0 mb-3">
                    <div className='reward-box'>
                        <div className='lottie-wrap'>
                            <Lottie options={firstLottie} />
                        </div>
                        <h5>tailored tasks</h5>
                        <p>Let your curiosity guide you as you explore tasks and treasures in the NFT Marketplace, Physical Goods Marketplace, and Treasure Box.</p>
                    </div>
                </Col>
                <Col lg={4} className="mb-lg-0 mb-3">
                    <div className='reward-box'>
                        <div className='lottie-wrap'>
                            <Lottie options={secondLottie} />
                        </div>
                        <h5>variety of rewards</h5>
                        <p>Earn badges, avatars and points for completing challenges, participating in contests, & engaging with other users. Showcase your content to a wider audience today. </p>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className='reward-box'>
                        <div className='lottie-wrap'>
                            <Lottie options={thirdLottie} />
                        </div>
                        <h5>earned points</h5>
                        <p>Earn points on every task and redeem them as Myntist tokens. Use them for trading and other exciting opportunities on our platform!</p>
                    </div>
                </Col>
            </Row>    
        </div>   
    </>
  )
}

export default legitGamifi