import React from "react";
import ThemeHeader from '../components/includes/themeHeader/themeHeader';
import Footer from '../components/includes/footer/footer';


class Layout6 extends React.Component {
    render() {
        const { children } = this.props
        return (
            <div className="landing-page">
                <ThemeHeader />
                <div className="">
                    {children}
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Layout6;