import React from 'react'
import { Link } from 'react-router-dom';
import image from '../../assets/images/astronaut.svg';
import logo from '../../assets/images/logo.svg';
function PageNotFound() {
  return (
      <>
    <div className='d-inline-block'>
    <Link to="/" className='logo'>
      <div className='logoImage me-4'><img src={logo} className="" /></div>
      <h5>Myntist</h5>
    </Link>
    </div>
    <div className='notfound-container d-flex flex-column justify-content-center'>
    <p className="main-title">404</p>
    <p className="text-center"><strong>NOT FOUND</strong></p>
    <p className="subtitle ">
        You’re either misspelling the URL or requesting a page that's no longer here.
    </p>
    <img src={image} className="astronaut" />
    </div>
    </>
  )
}

export default PageNotFound