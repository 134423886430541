import React, { useState, useEffect, useRef } from 'react'
import Table from 'react-bootstrap/Table';
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Avatar from "../../assets/images/avatar-dummy.png"
import { Link } from 'react-router-dom'
import Loader from "../loader/loader"
import { useDispatch, useSelector } from "react-redux";
import { getNotes } from '../../redux/notification/action';
import ScrollToBottom from 'react-scroll-to-bottom';
import { ENV } from "../../config/config"
import { getActivities, getNotifications, readNotification, getSingleEvent, updateSeenEvent } from "../../redux/user/action"
import Promotion from "../promotion/promotion";
import DataTable from 'react-data-table-component';
import SkeltonLoader from '../../components/loader/skeltonLoader';
import points from "../../assets/images/points.png"
import badges from "../../assets/images/badges.png"
// import ReactPaginate from 'react-paginate';
import { faFacebookF, faPinterest, faMedium, faYoutube, faInstagram, faTwitter, faReddit, faTiktok, faLinkedin, faDiscord, faGithub, faTelegram } from '@fortawesome/free-brands-svg-icons'
const { socket, media_url } = ENV
const moment = require('moment');


function NotificationsCopy() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);



    const { eventId } = useParams();
    const event_id = useRef("")

    let event = useSelector(state => state?.userData?.singleEvent)

    const dispatch = useDispatch();
    const notes = useSelector(state => state.notesData.notes)
    const notifications = useSelector(state => state.userData.notifications)
    const postRead = useSelector(state => state.userData?.postRead)
    // const [pageCount, setPageCount] = useState(0);
    // const [itemPerPage, setitemPerPage] = useState(10);
    // const [itemOffset, setItemOffset] = useState(0);
    const loader = useSelector(state => state.userData.loaderActive)
    const loaderB = useSelector(state => state.notesData.loaderActive)
    const [totalRows, setTotalRows] = useState([]);
    const [tableLoader, setTableloader] = useState(true)
    const [maxRows, setRows] = useState([]);
    const [selectedNote, setSelectednote] = useState(null)
    let history = useHistory()
    let walletAddress = ENV.getUserKey("WalletAddress")
    const data = { walletAddress }
    // useEffect(() => {
    //     dispatch(getSingleEvent(eventId))
    // }, [])

    useEffect(() => {
        if (event && !event?.isSeen) {
            dispatch(updateSeenEvent(event_id.current))
        }
    }, [event?.isSeen])
    useEffect(() => {
        // dispatch(getUnseenEvents(WalletAddress))
        if (postRead) {
            dispatch(getNotifications(walletAddress))
        }
    }, [postRead])
    useEffect(async () => {
        if (walletAddress) {
            setTableloader(true)
            // await dispatch(getActivities(data))
            await dispatch(getNotifications(walletAddress))
            setTableloader(false)
        }
        dispatch(getNotes());

    }, [])

    useEffect(() => {
        if (notifications) {
            calculatePagination(notifications.length)
        }
    }, [notifications])


    const calculatePagination = (rowsCount) => {
        setRows(rowsCount)
        if (rowsCount > 100) {
            setTotalRows([10, 25, 75, 100, rowsCount]);
        }
        else if (rowsCount >= 50) {
            setTotalRows([10, 25, rowsCount]);
        }
        else if (rowsCount < 50 && rowsCount > 10) {
            setTotalRows([10, rowsCount]);
        }
        else if (rowsCount <= 10) {
            setTotalRows([rowsCount]);
        }
    }
    const handleRead = (note) => {
        setSelectednote(note)
        dispatch(getSingleEvent(note.taskId))
        event_id.current = note.taskId
        dispatch(readNotification(note?._id))
        handleShow()
    }
    const onShare = async () => {
        history.push(`/shareContent?eventId=${event?._id}`)
    }

    let platformExist = -1
    let a = true
    return (
        <>
            <div className='notifications-wrap'>
                <div className='all-notifications'>
                    {/* <p className='my-2 notifi-time'>Today</p> */}
                    <ul>{notifications ? notifications.length > 0 ? notifications.map((note) => {
                        return (<li className={note.isRead == false ? 'unread' : ""} onClick={() => { handleRead(note) }}>
                            {/* <p>{note.description}.</p> */}

                            {note?.points > 0 ?
                                <p>You got <strong>{note.points} Points</strong> for performing {ENV.rearrangeEventType(note.eventType)} task <strong>on {note?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(note?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(note?.platform)}</strong>.</p>
                                : note?.points === 0 ? <p>You performed {ENV.rearrangeEventType(note.eventType)} task <strong>on {note?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(note?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(note?.platform)}</strong>.</p>
                                    :
                                    <p>Your <strong> {note.points * -1} points </strong>deducted for performing {ENV.rearrangeEventType(note.eventType)} task < strong > on {note?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(note?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(note?.platform)}</strong>.</p>
                            }
                            <span>
                                {moment(note?.createdAt).format('MM/DD/YYYY hh:mm:ss a')}
                            </span>
                        </li>)
                    }) : <div className='not-found'><p>No notifications to show!</p></div> : ""}
                    </ul >
                </div >
            </div >
            <div className={`notifications-model ${show ? "show" : ""}`} onHide={handleClose}>
                <div className='notifications-model-content'>
                    <button className='btn close-model' onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <div className='row h-100'>
                        <div className='col-xl-6'>
                            <div className='left-content'>
                                <div className='text-center rewards-box'>
                                    <ul>
                                        <li>
                                            {parseInt(event?.points) > 0 ?
                                                <div className='avatar-img image-1'>
                                                    <img src={points} alt="points" className='img-fluid' />
                                                </div>
                                                : ''}
                                        </li>
                                        <li>
                                            {event?.avatar ? <div className='avatar-img image-2'>
                                                <img src={media_url + event?.avatar} alt="avatar" className='img-fluid' />
                                            </div> : ""}

                                        </li>
                                        <li>{event?.badge ? <div className='avatar-img image-3'>
                                            <img src={media_url + event?.badge} alt="badge" className='img-fluid' />
                                        </div> : ""}

                                        </li>
                                    </ul>
                                    {/* {/ <h3>WELCOME AVATAR</h3 > /} */}
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div className='right-content p-2'>
                                <span className='time'>
                                    {/* {moment((new Date()).setHours(0, 0, 0, 0)).diff(event?.createdAt, 'days') == 0 ?
                                        "Today" :
                                        moment(new Date()).diff(event?.createdAt, 'days') == 1 ?
                                            "1 day ago" :
                                                moment(new Date()).diff(event?.createdAt, 'days') + " days ago"} */}
                                    {moment(event?.createdAt).format('MM/DD/YYYY hh:mm:ss a')}
                                    .</span>
                                {event?.points > 0 ?
                                    <h2>You got <strong>{event?.points} Points</strong> for performing {ENV.rearrangeEventType(event?.eventType)} task <strong>on {event?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(event?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(event?.platform)}</strong>.</h2>
                                    : event?.points === 0 ?
                                        <h2>You performed {ENV.rearrangeEventType(event?.eventType)} task <strong>on {event?.platform.toLowerCase() == 'nft' ? ENV.rearrangeEventType(event?.eventType) + ' Marketplace' : ENV.rearrangeEventType(event?.eventType)}</strong>.</h2>
                                        :
                                        <h2>Your <strong>{event?.points * -1} points</strong> deducted for performing {ENV.rearrangeEventType(event?.eventType)} task <strong>on {event?.platform.toLowerCase() == 'nft' ? ENV.rearrangeEventType(event?.eventType) + ' Marketplace' : ENV.rearrangeEventType(event?.eventType)}</strong>.</h2>
                                }
                                {/* <h2>You got <strong>100 Points</strong> for creating your <strong>NFT on Marketplace</strong>.</h2> */}
                                <h4>DETAILS</h4>
                                <Table>
                                    <tr>
                                        <th>Platform:</th>
                                        <td><strong>{ENV.capitalizeFirstLetter(event?.platform)}</strong></td>
                                    </tr>
                                    <tr>
                                        <th>Event Type:</th>
                                        <td><strong>{ENV.rearrangeEventType(event?.eventType)}</strong></td>
                                    </tr>
                                    {(event?.badge || event?.avatar) ?
                                        <tr>
                                            <th>Reward(s):</th>
                                            <td>
                                                {event?.badge ?
                                                    <div>
                                                        {/* <strong>{event?.badge?.split('.')[0]}</strong><span>1x</span> */}
                                                        <strong>Badge: </strong><span>1x</span>
                                                    </div> : ""}
                                                {event?.avatar ?
                                                    <div>
                                                        {/* <strong>{event?.avatar?.split('.')[0]}</strong><span>1x</span> */}
                                                        <strong>Avatar: </strong><span>1x</span>
                                                    </div> : ""
                                                }

                                            </td>
                                        </tr> : ""}

                                </Table>
                                <div className='share-with'>
                                    <h4>Share With:</h4>
                                    <ul className='social-links d-flex flex-wrap pe-xxl-5 pe-lg-3 mb-xxl-4 mb-3'>
                                        <li className='d-flex align-items-center justify-content-center fa'><button onClick={onShare} ><FontAwesomeIcon icon={faFacebookF} /></button></li>
                                        {/* <a href='https://www.facebook.com/Myntist' target="_blank" ><li className='d-flex align-items-center justify-content-center fa'><FontAwesomeIcon icon={faFacebookF} /></li></a> */}
                                        {/* <a href='' target="_blank" ><li className='d-flex align-items-center justify-content-center me'><FontAwesomeIcon icon={faMedium} /></li></a> */}
                                        {/* <a href='' target="_blank" ><li className='d-flex align-items-center justify-content-center ln'><FontAwesomeIcon icon={faLinkedin} /></li></a> */}
                                        <li className='d-flex align-items-center justify-content-center in'><button onClick={onShare} ><FontAwesomeIcon icon={faInstagram} /></button></li>
                                        {/* <a href='https://www.instagram.com/myntist/' target="_blank" ><li className='d-flex align-items-center justify-content-center in'><FontAwesomeIcon icon={faInstagram} /></li></a> */}
                                        {/* <a href='https://www.youtube.com/channel/UCNmA1NF0EDzG_tRiRG5o8Uw' target="_blank" ><li className='d-flex align-items-center justify-content-center yt'><FontAwesomeIcon icon={faYoutube} /></li></a> */}
                                        <li className='d-flex align-items-center justify-content-center tw'><button onClick={onShare} ><FontAwesomeIcon icon={faTwitter} /></button></li>
                                        {/* <a href='https://twitter.com/myntist?s=20&t=e5BMBb0SqOVPtqmerNsW5A' target="_blank" ><li className='d-flex align-items-center justify-content-center tw'><FontAwesomeIcon icon={faTwitter} /></li></a> */}
                                        {/* <a href='' target="_blank" ><li className='d-flex align-items-center justify-content-center ds'><FontAwesomeIcon icon={faDiscord} /></li></a> */}
                                        {/* <a href='https://www.reddit.com/user/Myntist' target="_blank" ><li className='d-flex align-items-center justify-content-center re'><FontAwesomeIcon icon={faReddit} /></li></a> */}
                                        {/* <a href='https://www.tiktok.com/@myntist' target="_blank" ><li className='d-flex align-items-center justify-content-center me-2 gi'><FontAwesomeIcon icon={faTiktok} /></li></a> */}

                                        {/* <a href='' target="_blank" ><li className='d-flex align-items-center justify-content-center gi'><FontAwesomeIcon icon={faGithub} /></li></a> */}
                                        {/* <a href='' target="_blank" ><li className='d-flex align-items-center justify-content-center te'><FontAwesomeIcon icon={faTelegram} /></li></a> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}

export default NotificationsCopy