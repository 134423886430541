import LeftSidebar from "./sidebar/leftSidebar/leftSidebar"
import RightSidebar from "./sidebar/rightSidebar/rightSidebar"
import MainContent from "./mainContent/mainContent"

function Dashboard() {
    return (
        <>
            <div className="row">
                <div className="col-xl-9 /*col-lg-7*/">
                    <MainContent />
                </div>
                <div className="col-xl-3 /*col-lg-5*/">
                    <RightSidebar />
                </div>
            </div>
        </>

    );
}

export default Dashboard;

