import { GET_CONTEST_DETAILS } from "../actionTypes";

const initialState = {
    contest: []
}

const contestDetails = (state = initialState, action) => {

    switch (action.type) {
        case GET_CONTEST_DETAILS:

            return {
                ...state,
                contest: action.payload
            }
        default:
            return state;
    }
}

export default contestDetails;