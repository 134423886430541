import { useEffect } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { ENV } from "../../../config/config"
import { useAccount, useDisconnect, useConnect } from "wagmi";
import Logo from "../../../assets/images/logo.svg"
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { RegisterUser, postEvent } from "../../../redux/user/action"
import { clearCurrentUser } from "../../../redux/walletProviders/action"
import { notify } from "react-notify-toast";
const { socket } = ENV

function Header() {
    let history = useHistory()
    const dispatch = useDispatch();
    const location = useLocation();
    let WalletAddress = ENV.getUserKey('WalletAddress')
    let { code } = useParams();
    const { disconnect } = useDisconnect();
    const { address: userAddress, isConnected, isConnecting } = useAccount();
    // useEffect(() => {
    //     metamaskEventDetector();
    // }, [])
    useEffect(() => {
        socket.on("daliy claim", (data) => {
            let notData = "You claimed daily reward points"
            if (data.userId == WalletAddress) {

                // dispatch(getNotifications(WalletAddress))
                // dispatch(getUserStatus({ address: WalletAddress, platform: "Gamification" }))
                notify.show(notData, "success", 5000);
            }
        })
    }, [socket])

    return (
        <>
            <div className="header py-3">
                <Navbar expand="lg" className="custom-padding">
                    <Navbar.Brand >
                        <Link className='leaderboardlogo' to={`/dashboard?address=${WalletAddress}`}>
                            <div className="footer-logo me-2">
                                <img height={50} width={50} loading='lazy' src={Logo} alt="logo" className="img-fluid" />
                                {/* <img height={50} width={50} loading='lazy' src={`https://res.cloudinary.com/doqwmwmx0/image/upload/h_50,w_50/v1676641311/assets/logo_j9mxim.svg`} alt="logo" className="img-fluid" /> */}
                            </div>
                            <div className='footer-logo-text text-end'>
                                <h5>Myntist</h5>
                                <span>Gamification</span>
                            </div>
                        </Link>

                    </Navbar.Brand>

                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className='header-links'>
                        <Nav className="ms-auto">
                            <Link className="nav-link" to={`/leaderboard?route=Leaderboard`}>
                                Leaderboard
                            </Link>
                            <Link className="nav-link" to={`/dashboard?address=${WalletAddress}`}>
                                Dashboard
                            </Link>
                            {WalletAddress && <a className="nav-link" href={`${'https://hex-test.arhamsoft.org' + '/author/' + WalletAddress}`} target="_blank">
                                My NFT
                            </a>}

                            {WalletAddress && <a className="nav-link" href={`${'https://hex-test.arhamsoft.org' + '/author/' + WalletAddress}`} target="_blank">
                                My Collectibles
                            </a>}
                            <Link className="nav-link" to={`/profile`}>
                                Profile
                            </Link>
                            {WalletAddress && <Link className="nav-link main-btn-style" to="sell/avatars">
                                SELL MY AVATARS
                            </Link>}

                        </Nav>
                    </Navbar.Collapse> */}
                </Navbar>
            </div>
        </>
    );
}

export default Header;

