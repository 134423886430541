
import RightSidebar from "./rightSidebar/rightSidebar"
import LeftSidebar from "./leftSidebar/leftSidebar"
import MainContent from "./mainContent"
import Loader from "../loader/loader"
import Promotion from "../promotion/promotion"
import MyNFTs from "../myNFTs/myNFTs"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { getEnabledPromotion } from "../../redux/promotions/action";
import Popup from "../popup/popup"
import UserStatus from "../userStatus/status"
import Stats from "./stats/stats"
import BadgesEarned from "./badges/badgesEarned"
import Collections from "./collections/collections"
import About from "./about/about"
import Activities from "../activities/activities"
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { getQueryParam } from '../../config/helper';
import { useLocation, useHistory } from 'react-router-dom';

function Profile() {


    let promotions = useSelector(state => state?.promotions?.promotion);
    let dispatch = useDispatch();
    let userInfo = {}
    let userId = localStorage.getItem("userId")
    let userWallet = localStorage.getItem("WalletAddress")

    useEffect(() => {
        // if(userId){
        //     userInfo.userId = userId
        // }
        // else{
        //     userInfo.address = userWallet
        // }
        dispatch(getEnabledPromotion("profile"));
    }, [])
    return (
        <>
            <Popup data={promotions} />
            <div className="container-fluid ps-0">
                <div className="row mb-lg-4 mb-3">
                    <div className="col-xxl-3 col-xl-4 col-lg-5 left-sidebar">
                        <UserStatus />
                    </div>
                    <div className="col-xxl-9 col-xl-8 col-lg-7 left-sidebar">
                        <Stats />
                    </div>
                </div>
                <div className="row mb-lg-4 mb-3">
                    <div className="col-xxl-9 col-xl-8 col-lg-7 left-sidebar">
                        <MyNFTs />
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-lg-5 left-sidebar">
                        <BadgesEarned />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 left-sidebar">
                        <Collections />
                    </div>
                    <div className="col-xl-4 left-sidebar">
                        <About />
                    </div>
                    <div className="col-xl-4 left-sidebar">
                        <Activities />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-lg-6 col-xl-3 order-1 ">
                        <LeftSidebar />
                    </div>
                    <div className="col-lg-12 col-xl-6 order-3 order-xl-2">
                        <MainContent />
                    </div>
                    <div className="col-lg-6 col-xl-3 order-2 order-xl-3">
                        <RightSidebar />
                    </div>
                </div> */}
            </div>
            <Promotion type='My Profile' />
        </>
    );
}

export default Profile;

