import { WALLET_PROVIDER,CURRENT_USER, CLEAR_CURRENT_USER, WALLET_LOADER } from "../actionTypes";



const walletReducer = (state = {currentUser: null, provider: 0}, action) => {
    if(action.type === WALLET_PROVIDER){
        return {
            ...state,
            provider: action.payload
        }
    }else if(action.type === CURRENT_USER) {
        return {
            ...state,
            currentUser: action.payload
        }
    } else if (action.type === CLEAR_CURRENT_USER){
        return {
            ...state,
            currentUser : null
        }
    }else if (action.type === WALLET_LOADER){
        return {
            ...state,
            walletLoader : action.payload
        }
    }
    else{
        return state;
    }
    // if(action.type === WALLET_PROVIDER){
    //     return {
    //         ...state,
    //         walletType: action.payload
    //     }
    // }else {
    //     return state;
    // }
}

export default walletReducer;