import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTasks } from '../../../../../redux/tasks/action';
import { getProfileCompleteness } from "../../../../../redux/user/action";
import { Row, Col } from 'react-bootstrap';
import { ENV } from '../../../../../config/config';
import DataTable from 'react-data-table-component';
import SkeltonLoader from '../../../../../components/loader/skeltonLoader';
import { SkeletonLayout } from 'react-skeleton-loader-pulse';
import Lottie from 'react-lottie';
import ClaimedTick from '../../../../../assets/lottie/claimed.json';
import ClaimedStars from '../../../../../assets/lottie/claimed-stars.json';
import ReactTooltip from 'react-tooltip';

const AllRewards = () => {
    let profileCompleteness = useSelector(state => state.userData?.profCompleteness)
    const loaderActive = useSelector(state => state.userData.loaderActive)
    const tasks = useSelector(state => state?.tasks?.task?.task)
    const total = useSelector(state => state?.tasks?.task?.total)
    const dispatch = useDispatch()
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);
    const [filterLoader, setFilterLoader] = useState(false);
    const [tableLoader, setTableloader] = useState(false)
    const [maxRows, setRows] = useState([]);
    let address = localStorage.getItem("WalletAddress");


    useEffect(async () => {
        setTableloader(true)
        const query = { page: currentPage - 1, per_page: perPage }
        await dispatch(getTasks(query))
        setTableloader(false)
    }, [])
    useEffect(()=>{
        dispatch(getProfileCompleteness(address))
    },[])
    useEffect(() => {
        if (tasks) {

            calculatePagination(total)
        }
        ReactTooltip.rebuild();
    }, [tasks])

    // function loadMoreItems() {
    //     setNumberOfItems(numberOfItems + numberOfItems)
    // }

    const calculatePagination = (rowsCount) => {
        // setRows(rowsCount)
        // if (rowsCount > 100) {
        //     setTotalRows([10, 25, 75, 100, rowsCount]);
        // }
        // else if (rowsCount >= 50) {
        //     setTotalRows([10, 25, rowsCount]);
        // }
        // else if (rowsCount < 50 && rowsCount > 10) {
        //     setTotalRows([10, rowsCount]);
        // }
        // else if (rowsCount <= 10) {
        //     setTotalRows([rowsCount]);
        // }
        setTotalPages(Math.ceil(rowsCount / 10))
    }
    const handlePageChange = async page => {
        setFilterLoader(true);
        const query = { page: page - 1, per_page: perPage }
        await dispatch(getTasks(query))
        setFilterLoader(false);
    };
    useEffect(() => {
        handlePageChange(currentPage);
      }, [currentPage]);
    
      const handlePaginationClick = (page) => {
        setCurrentPage(page);
      };
    
      const handlePreviousClick = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const handleNextClick = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
        }
      };
      const renderPageNumbers = () => {
        if (totalPages <= 5) {
          return Array.from({ length: totalPages }, (_, index) => index + 1);
        }
    
        const pages = [];
        const firstPage = Math.max(1, currentPage - Math.floor(5 / 2));
        const lastPage = Math.min(totalPages, firstPage + 5 - 1);
    
        if (firstPage > 1) {
          pages.push(1);
          if (firstPage > 2) {
            pages.push('...');
          }
        }
    
        for (let page = firstPage; page <= lastPage; page++) {
          pages.push(page);
        }
    
        if (lastPage < totalPages) {
          if (lastPage < totalPages - 1) {
            pages.push('...');
          }
          pages.push(totalPages);
        }
    
        return pages;
      };
    const handlePerRowsChange = async (newPerPage, page) => {
        // handlePageChange(page)
        setFilterLoader(true);
        setPerPage(newPerPage);
        setCurrentPage(page);
        const query = { page: page - 1, per_page: newPerPage }
        await dispatch(getTasks(query))
        setFilterLoader(false);
    };

    const columns = [
        {
            name: "Task",
            selector: (row) => row?.name,
            wrap: false,
            // sortable:true
        },
        {
            name: "Reward",
            selector: (row) => row?.points,
            wrap: false,
        },
        {
            name: "Description",
            cell: (row) => <><span data-tip={row?.description ? row?.description : ""} dangerouslySetInnerHTML={{ __html: row?.description }}></span> <ReactTooltip html={true} /></>,
            wrap: false,
            // width: "20%"
            // sortable:true
        },

    ]

    const customStyles = {
        table: {
            style: {
                background: '#131316'
            },
        },
        noData: {
            style: {
                display: 'flex',
                justifyContent: 'start',
                color: "white",
                backgroundColor: '#1E1E24',
                padding: "5px 10px",
                minHeight: '65px',
            },
        },
        progress: {
            style: {
                backgroundColor: '#1E1E24',
            },
        },
        rows: {
            style: {
                minHeight: '65px', // override the row height
                color: '#FFFFFF',
                background: '#2b2b33',
                fontSize: '14px',
            },
            stripedStyle: {
                color: "#fff",
                backgroundColor: "#1E1E24"
            },
        },
        headCells: {
            style: {
                fontSize: "18px",
                color: '#FFFFFF',
                background: '#000000',
            },
        },
        pagination: {
            style: {
                color: "#FFFFFF",
                backgroundColor: "#000000",
                borderTopStyle: 'solid',
            },
            pageButtonsStyle: {
                color: "#FFFFFF",
                fill: "#FFFFFF",
                '&:disabled': {
                    cursor: 'unset',
                    color: "#EDEDED",
                    fill: "#EDEDED",
                },
            }
        }
    };

    const lottie1 = {
        loop: true,
        autoplay: true,
        animationData: ClaimedStars,
    };
    const lottie2 = {
        loop: true,
        autoplay: true,
        animationData: ClaimedTick,
    };

    return (
        <>
        <div className="cards-wrapper">
            {/* <div className="page-title">
                <h2>Daily Tasks & Rewards</h2>
            </div> */}
            <ul className="d-flex flex-wrap">
                {!filterLoader && (tasks && tasks.length > 0) ?
                    <>
                        {tasks && tasks.length > 0 ? tasks.filter(opt => opt.enabled == true).slice(0, tasks.length).map((task, key) => {
                            const isAchieved = profileCompleteness?.length>0? profileCompleteness?.includes(task._id):false;
                            const taskClassName = isAchieved ? "daily-task-wrapper text-center achieved" : "daily-task-wrapper text-center";
                            
                            return (
                                // <li key={key} className={key == 0 || key == 1 ? "mb-2 claimed" : "mb-2"}>
                                <li key={key} className={key == 0 || key == 1 ? "mb-2" : "mb-2"}>
                                    <div className={taskClassName}>
                                        {/* <p className='day'>Day 1</p>
                                        <span className='today'>Today</span> */}
                                        <h4>{ENV.capitalizeFirstLetter(task?.platform)}</h4>
                                        <span className='platform-name'>Platform</span>
                                        <div className='image'>
                                            {task.avatar && task.avatar.length > 0 ?
                                                <img src={ENV.media_url + task.avatar[0]?.avatar[0]?.image} className='img-fluid' /> :
                                                <img src='./images/daily-reward-gold.png' alt='' className='img-fluid' />
                                            }
                                            {/* <Lottie className="lottie" options={starOptions} /> */}
                                        </div>
                                        <span className='text'>{task.name}</span>
                                        <p className='points'>{task.points} points</p>
                                        <div className="lotties-wrap">
                                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                                </div>
                                    </div>
                                    {/* <div className="lotties-wrap">
                                        <span className="lotties-wrap claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                        <span className="lotties-wrap claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                    </div> */}
                                </li>)
                        }) : ""}</>
                    : <div className="d-flex justify-content-center not-found">
                        <div className="spinner-border" role="status"></div>
                    </div>}
                    
                {/* <Col xl={4} lg={6} className="mb-2">
                    <div className='daily-task-wrapper text-center'>
                        <p className='day'>Day 2</p>
                        <span className='today'>Tomorrow</span>
                        <div className='image'>
                            <img src='./images/daily-rewards-black.png' alt='' className='img-fluid' />
                        </div>
                        <span className='text'>Launch a contest & collect reward</span>
                        <p className='points'>150 Points</p>
                    </div>
                </Col> 
                <Col xl={4} lg={6} className="mb-2">
                    <div className='daily-task-wrapper text-center'>
                        <p className='day'>Day 3</p>
                        <span className='today'>--</span>
                        <div className='image'>
                            <img src='./images/daily-rewards-black.png' alt='' className='img-fluid' />
                        </div>
                        <span className='text'>Launch a contest & collect reward</span>
                        <p className='points'>250 Points</p>
                    </div>
                </Col> */}
            </ul>
            
            
            {/* <div className="points-list">
                <div className="notifications">
                    <div className="list p-0">
                        <div className="points-table table-responsive">
                            {tasks && total > 9 ?
                                <DataTable
                                    columns={columns}
                                    data={tasks}
                                    pagination
                                    paginationServer
                                    paginationRowsPerPageOptions={totalRows}
                                    paginationTotalRows={maxRows}
                                    paginationDefaultPage={currentPage}
                                    // highlightOnHover
                                    pointerOnHover
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    customStyles={customStyles}
                                    persistTableHead
                                    fixedHeader
                                    striped
                                    fixedHeaderScrollHeight="500px"
                                    progressPending={tableLoader || loaderActive || filterLoader}
                                    progressComponent={
                                        filterLoader || tableLoader ?
                                            <SkeletonLayout
                                                align="center"
                                                color="#000000"
                                                items={[{
                                                    height: 60,
                                                    marginBottom: '1px',
                                                    marginTop: 0,
                                                    width: '100%',
                                                    borderRadius: '5',
                                                }]
                                                }
                                            /> :
                                            <SkeltonLoader height={'60'} width={'100%'} radious={'5'} skeletonLength={tasks ? tasks.length : 7} />}
                                />
                                :
                                <DataTable
                                    columns={columns}
                                    data={tasks}
                                    // highlightOnHover
                                    pointerOnHover
                                    customStyles={customStyles}
                                    persistTableHead
                                    fixedHeader
                                    striped
                                    progressPending={tableLoader || loaderActive}
                                    progressComponent={<SkeltonLoader height={'60'} width={'100%'} radious={'5'} skeletonLength={tasks ? tasks.length : 7} />}
                                />}
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
        {!filterLoader && tasks && total > 10 ?
        <div className="tasks-pagination">
        <button onClick={handlePreviousClick} disabled={currentPage === 1}>
          &lt; 
        </button>
        {renderPageNumbers().map((page, index) => (
          <button
            key={index}
            onClick={() => handlePaginationClick(page)}
            className={page !== '...' && page === currentPage ? 'active' : page == '...'?'disabled':''}
          >
            {page}
          </button>
        ))}
         <button onClick={handleNextClick} disabled={currentPage === totalPages}>
          &gt;
        </button>
                </div>
:""}
                </>
    );
}
export default AllRewards;
