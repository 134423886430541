
import { Link, useParams } from 'react-router-dom'
import { ENV } from "../../../../config/config"
import { useState } from "react"
import ConnectWalletModal from "../../../../connectWallet/connectModel"
import { Row, Col } from 'react-bootstrap'
import Lottie from 'react-lottie';
import Stars from '../../../../assets/lottie/box.json';

function GamePurchases() {
    let address = ENV.getUserKey("WalletAddress")
    const [show, setShow] = useState("false")

    const starOptions = {
        loop: true,
        autoplay: true,
        animationData: Stars,
    };

    return (
        <>
            <div className="game-purchase">
                <div className="game-list">
                    <Row className='align-items-end'>
                        {/* <Col xxl={8}>
                            <div className='section-dark-bg'>
                                <Row>
                                    <div className="heading">
                                        <h4>Game Purchases</h4>
                                    </div>
                                </Row>
                                <Row>
                                    <Col lg={6} xl={4} className="mb-2">
                                        <div className='game-purchase-wrapper monetary-discounts-bg'>
                                            <div className='image'>
                                                <img src='./images/discount-price.svg' alt='' className='img-fluid' />
                                            </div>
                                            <h5>Set Up a Monetary Discounts</h5>
                                            <div className='d-flex flex-column justify-content-between'>
                                                <p>Allow your users to use their tokenized gift cards, or redeem some of their points.</p>
                                                <div className='text-end puchase-link-icon'>
                                                    <a href='#'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                                            <path id="Icon_awesome-external-link-alt" data-name="Icon awesome-external-link-alt" d="M20,.844v4.5a.833.833,0,0,1-1.423.6l-1.24-1.255L8.882,13.246a.826.826,0,0,1-1.179,0l-.786-.8a.851.851,0,0,1,0-1.193L15.373,2.7,14.134,1.44A.846.846,0,0,1,14.723,0h4.444A.839.839,0,0,1,20,.844ZM14.133,9.52l-.556.562a.849.849,0,0,0-.244.6V15.75H2.222V4.5h9.167a.828.828,0,0,0,.589-.247l.556-.563a.845.845,0,0,0-.589-1.44H1.667A1.677,1.677,0,0,0,0,3.938V16.313A1.677,1.677,0,0,0,1.667,18H13.889a1.677,1.677,0,0,0,1.667-1.687v-6.2A.832.832,0,0,0,14.133,9.52Z" fill="#fff" opacity="0.5" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} xl={4} className="mb-2">
                                        <div className='game-purchase-wrapper place-binding-bg'>
                                            <div className='image'>
                                                <img src='./images/place-bidding.svg' alt='' className='img-fluid' />
                                            </div>
                                            <h5>Place Bidding</h5>
                                            <div className='d-flex flex-column justify-content-between'>
                                                <p>Reward system dedicated for the seller for using the bidding feature with 20 points</p>
                                                <div className='text-end puchase-link-icon'>
                                                    <a href='#'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                                            <path id="Icon_awesome-external-link-alt" data-name="Icon awesome-external-link-alt" d="M20,.844v4.5a.833.833,0,0,1-1.423.6l-1.24-1.255L8.882,13.246a.826.826,0,0,1-1.179,0l-.786-.8a.851.851,0,0,1,0-1.193L15.373,2.7,14.134,1.44A.846.846,0,0,1,14.723,0h4.444A.839.839,0,0,1,20,.844ZM14.133,9.52l-.556.562a.849.849,0,0,0-.244.6V15.75H2.222V4.5h9.167a.828.828,0,0,0,.589-.247l.556-.563a.845.845,0,0,0-.589-1.44H1.667A1.677,1.677,0,0,0,0,3.938V16.313A1.677,1.677,0,0,0,1.667,18H13.889a1.677,1.677,0,0,0,1.667-1.687v-6.2A.832.832,0,0,0,14.133,9.52Z" fill="#fff" opacity="0.5" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} xl={4} className="mb-2">
                                        <div className='game-purchase-wrapper group-buying-bg'>
                                            <div className='image'>
                                                <img src='./images/groups-buying.svg' alt='' className='img-fluid' />
                                            </div>
                                            <h5>Group Buying</h5>
                                            <div className='d-flex flex-column justify-content-between'>
                                                <p>Set up a reward system for some group-buying tokenized gift-card</p>
                                                <div className='text-end puchase-link-icon'>
                                                    <a href='#'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                                            <path id="Icon_awesome-external-link-alt" data-name="Icon awesome-external-link-alt" d="M20,.844v4.5a.833.833,0,0,1-1.423.6l-1.24-1.255L8.882,13.246a.826.826,0,0,1-1.179,0l-.786-.8a.851.851,0,0,1,0-1.193L15.373,2.7,14.134,1.44A.846.846,0,0,1,14.723,0h4.444A.839.839,0,0,1,20,.844ZM14.133,9.52l-.556.562a.849.849,0,0,0-.244.6V15.75H2.222V4.5h9.167a.828.828,0,0,0,.589-.247l.556-.563a.845.845,0,0,0-.589-1.44H1.667A1.677,1.677,0,0,0,0,3.938V16.313A1.677,1.677,0,0,0,1.667,18H13.889a1.677,1.677,0,0,0,1.667-1.687v-6.2A.832.832,0,0,0,14.133,9.52Z" fill="#fff" opacity="0.5" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col> */}

                        <Col xxl={4} >
                            <ul>
                                <li className="treasury-box section-dark-bg">
                                    <div className="image">
                                        {/* <img src="/images/treasury-box.png" alt="" className="img-fluid" /> */}
                                        <Lottie className="lottie" options={starOptions} />
                                    </div>
                                    <p>Upload your content in <br />Treasure box</p>
                                    {(!address || address == "null") ?
                                        < a className="btn btn-dark" onClick={() => setShow(true)}>Connect</a>
                                        : <a href={process.env.REACT_APP_TREASUREBOX_URL} className="btn btn-dark" target="_blank">Upload</a>}
                                    {/* <Link to={`/format`} className="btn btn-dark" >Upload</Link> */}
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                {show === "true" || show === true ?
                    < ConnectWalletModal show={show} urlToNavigate="/format" />
                    : ""}
            </div>

        </>
    );
}

export default GamePurchases;