import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getQueryParam } from '../../config/helper'


function BreadCrumb() {
    const location = useLocation();
    let route = getQueryParam(location, 'route')
    const WalletAddress = localStorage.getItem("WalletAddress");

    return (
        <div className='page-header'>
            <h2>{route}</h2>
            <Breadcrumb>
                <Breadcrumb.Item href={`/dashboard?address=${WalletAddress}`}>Dashboard</Breadcrumb.Item>
                {console.log(route, "<<<<<<<<<<<<<<<<<")}
                <Breadcrumb.Item active>{route}</Breadcrumb.Item>
            </Breadcrumb>
        </div>
    )
}

export default BreadCrumb