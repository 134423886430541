
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getTasks } from '../../../../../redux/tasks/action';
import { getProfileCompleteness } from "../../../../../redux/user/action";
import React, { useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie';
import Stars from '../../../../../assets/lottie/stars';
import { ENV } from '../../../../../config/config';
import ClaimedTick from '../../../../../assets/lottie/claimed.json';
import ClaimedStars from '../../../../../assets/lottie/claimed-stars.json';
function DailyRewards() {
    const loader = useSelector(state => state.userData.loaderActive)
    let address = localStorage.getItem("WalletAddress");
    let profileCompleteness = useSelector(state => state.userData?.profCompleteness)
    const tasks = useSelector(state => state?.tasks?.task?.task)
    const dispatch = useDispatch()
    useEffect(() => {
        const query = { page: 0, per_page: 4 }
        dispatch(getTasks(query))
    }, [])
    useEffect(()=>{
        dispatch(getProfileCompleteness(address))
    },[])
    // let wallet = localStorage.getItem("address")
    const starOptions = {
        loop: true,
        autoplay: true,
        animationData: Stars,
    };
    const lottie1 = {
        loop: true,
        autoplay: true,
        animationData: ClaimedStars,
    };
    const lottie2 = {
        loop: true,
        autoplay: true,
        animationData: ClaimedTick,
    };
    return (
        <div className=" /*col-xxl-7*/ col-12">
            <div className="task-rewards daily-tasks-reward">

                {/* {tasks && tasks.length > 0 ?

                    <div className="list">
                        <ul>
                            <li className="head">
                                <p className="task">Task</p>
                                <p className="rewards">Reward</p>
                            </li>
                            {tasks && tasks.length > 0 ? tasks.slice(0, 4).map((task, key) => {
                                return (<li key={key}>

                                    <p className="task">{task.name}</p>
                                    <p className="rewards text-blue">{task.points} Points <Link to={"/reward/" + task._id} className="link fa fa-eye"></Link></p>

                                </li>)
                            }) : ""}

                        </ul>
                        {tasks && tasks.length > 0 ? <div className="view-all">
                            <Link to="/rewards-all" >View all tasks</Link>
                        </div> : ""}

                    </div> : <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                        </div>
                    </div>} */}

                <div className='section-dark-bg daily-rewards-bg'>
                    <div className="section heading">
                        <h4>Daily Tasks & Rewards</h4>
                        {tasks && tasks.length > 0 ? <Link to="/rewards-all?route=Rewards" className='view-all' >All tasks</Link> : ""}
                    </div>
                    <Row>
                        {tasks && tasks.length > 0 ?
                            <>
                                {tasks && tasks.length > 0 ? tasks.filter(opt => opt.enabled === true).slice(0, 4).map((task, key) => {
                                    const isAchieved = profileCompleteness?.length>0? profileCompleteness?.includes(task._id):false;
                                    const taskClassName = isAchieved ? "daily-task-wrapper text-center active achieved" : "daily-task-wrapper text-center active";
                                    
                                    return (
                                        <Col key={key} xxl={3} sm={6} className="mb-2">
                                            <div className={taskClassName}>
                                                {/* <p className='day'>Day 1</p>
                                                <span className='today'>Today</span> */}
                                                <h4>{ENV.capitalizeFirstLetter(task?.platform)}</h4>
                                                <span className='platform-name'>Platform</span>
                                                <div className='image'>
                                                    {task.avatar && task.avatar.length > 0 ?
                                                        <img src={ENV.media_url + task.avatar[0]?.avatar[0]?.image} className='img-fluid' /> :
                                                        <img src='./images/daily-reward-gold.png' alt='' className='img-fluid' />
                                                    }
                                                    {/* <Lottie className="lottie" options={starOptions} /> */}
                                                </div>
                                                <span className='text'>{task.name}</span>
                                                <p className='points'>{task.points} points</p>
                                                <div className="lotties-wrap">
                                                    <span className="claimed-lottie-1"><Lottie className="lottie" options={lottie1} /></span>
                                                    <span className="claimed-lottie-2"><Lottie className="lottie" options={lottie2} /></span>
                                                </div>
                                        </div>
                                    </Col>
                                );
                            }) : ""}     
                           </>
                            : 
                            <>
                                {loader ?
                                    <div className='not-found'>
                                        <div className="spinner-border" role="status"></div>
                                    </div> 
                                    :
                                    <div className='not-found'>
                                        <p>No Tasks & Rewards to show!</p>
                                    </div> 
                                    
                                }
                            </>
                            }
                        {/* <Col xl={4} lg={6} className="mb-2">
                            <div className='daily-task-wrapper text-center'>
                                <p className='day'>Day 2</p>
                                <span className='today'>Tomorrow</span>
                                <div className='image'>
                                    <img src='./images/daily-rewards-black.png' alt='' className='img-fluid' />
                                </div>
                                <span className='text'>Launch a contest & collect reward</span>
                                <p className='points'>150 Points</p>
                            </div>
                        </Col> 
                        <Col xl={4} lg={6} className="mb-2">
                            <div className='daily-task-wrapper text-center'>
                                <p className='day'>Day 3</p>
                                <span className='today'>--</span>
                                <div className='image'>
                                    <img src='./images/daily-rewards-black.png' alt='' className='img-fluid' />
                                </div>
                                <span className='text'>Launch a contest & collect reward</span>
                                <p className='points'>250 Points</p>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default DailyRewards;