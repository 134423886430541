
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getUserStatus, createNFT, editUserStatus, clearNftData, postEvent } from "../../redux/user/action"
import { Table, Button } from 'react-bootstrap';
import swal from 'sweetalert2';
import { useParams } from "react-router-dom";
import { SkeletonLayout } from 'react-skeleton-loader-pulse';
import CreateNftPopUp from './Popup';
import CongratesModal from '../successPopup/congratesModal';

function AvatarsEarned() {

    let dispatch = useDispatch()
    const { type } = useParams();
    const user = useSelector(state => state.userData?.profile)
    const nftCreated = useSelector(state => state.userData?.nftCreated)
    const nftData = useSelector((state => state.userData?.nftData));
    const [fileName, setFileName] = useState("")
    const [loader, setLoader] = useState(true)
    let address = localStorage.getItem("WalletAddress")
    const [showPopup, setShowPopup] = useState(false);
    const [nftPopup, setNftPopup] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [nftLink, setNftLink] = useState('');
    const loaderA = useSelector(state => state.userData.nftSoldLoader)
    const [submitLoader, setSubmitLoader] = useState(false);


    useEffect(async () => {
        // handleSell()
        setLoader(false)
        if (!user) {
            setLoader(true)
            await dispatch(getUserStatus({ address }))
            setLoader(false)
        }
    }, [address])

    useEffect(() => {
        if (user) {
            if (nftCreated === true) {
                if (type == 'avatars') {
                    console.log(user.avatars, 'avaaaaaaaaatar');
                    let avatars = user.avatars.filter(avatar => { return avatar.name?.split('.')[0] !== fileName?.name;});

                    let data = {
                        address: address,
                        formData: {
                            picture: user?.picture?.split('.')[0] == fileName?.name ? "" : user?.picture,
                            avatars: avatars,
                            address: address
                        }

                    }
                    dispatch(editUserStatus(data))
                }
                else if (type == 'badges') {
                    let badges = user.badges.filter(badge => { return badge.name != fileName.name });
                    let data = {
                        address: address,
                        formData: {
                            badges: badges,
                            address: address
                        }
                    }
                    dispatch(editUserStatus(data))
                }
            }
        }
    }, [nftCreated])
    useEffect(() => {
        if (user) {

            if (nftData?.success === true && loaderA === false && nftCreated) {
                setSubmitLoader(false);
                setNftLink(nftData?.nftUrl)
                setNftPopup(true)
                let eventData = {
                    eventName: "create nft",
                    userId: address
                }
                let eventRes = dispatch(postEvent(eventData))
            } else if (nftData?.success === false && loaderA === false) {
                setSubmitLoader(false);
                swal.fire({
                    icon: "error",
                    title: nftData?.message,
                    // html:  `View your <a class="swal-link" href="${nftData?.nftUrl}" target="blank" > NFT</a> `,
                    willClose: () => {

                    }
                })
                dispatch(clearNftData());
            }
        }
    }, [loaderA, nftCreated])

    const handelCreate = (file) => {
        setShowPopup(true);
        setFileName(file)
    }

    const handleSell = async (info) => {
        setSubmitLoader(true);
        var formData = new FormData();
        formData.append("remoteFileLink", `${process.env.REACT_APP_MEDIA_URL + fileName.image}`)
        formData.append("name", fileName.name);
        formData.append("userAddress", address)
        formData.append("gNFTType", 2)
        formData.append("chainName", info.chainName)
        formData.append("copies", info.copies)
        setShowPopup(false);
        setButtonLoader(true)
        let res = await dispatch(createNFT(formData))
        setButtonLoader(false)
    }

    const getName = (avatar) => {
        return avatar?.name?.split(".")[0];
    }
    return (
        <section className='avatars-badges pe-lg-3'>
            {nftPopup &&
                <CongratesModal nftLink={nftLink} setNftPopup={setNftPopup} show={true} />
            }
            {showPopup
                &&
                <CreateNftPopUp
                    setShowPopup={setShowPopup}
                    showPopup={showPopup}
                    handleSell={handleSell}
                    submitLoader={submitLoader}
                    buttonLoader={buttonLoader}
                />}
            {/* <div className="badges-earned">
                <div className="">
                    <div className="heading mb-0">
                        <h2>{type == 'avatars' ? 'Avatars Earned' : type == 'badges' ? "Badges Earned" : ''}</h2>
                    </div>
                </div>
            </div> */}
            <div className='cards-wrapper'>
                {type === 'avatars' && (user?.avatars?.length > 0) || type === 'badges' && (user?.badges?.length > 0) ?
                    <ul className="d-flex flex-wrap">
                        {type === "avatars" && user?.avatars?.length > 0 ?
                            user?.avatars?.map((avatar, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {avatar ?
                                            <li>
                                                <div className='cards-inner-wrapper'>
                                                    <div className='avatars-badges-img'>
                                                        <img src={`${process.env.REACT_APP_MEDIA_URL + avatar.name}`} className="img-fluid" alt="avatar" />
                                                    </div>
                                                    <strong>{getName(avatar)}</strong>
                                                    <div>
                                                        <Button variant="" className='cards-inner-button' onClick={() => { handelCreate({ name: getName(avatar), image: avatar.name }) }}>Create NFT</Button>
                                                    </div>
                                                </div>
                                            </li>
                                            : ""}
                                    </React.Fragment>
                                );
                            })
                            : type === 'badges' && user?.badges?.length > 0 ?
                                user?.badges?.map((badge, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {badge ?
                                                <li className='badges-wrap'>
                                                    <div className='cards-inner-wrapper'>
                                                        <div className='avatars-badges-img'>
                                                            <img src={`${process.env.REACT_APP_MEDIA_URL + badge.image}`} className="img-fluid avatars-badges-img" alt="badge" />
                                                        </div>
                                                        <div>
                                                            <strong>{badge.name}</strong>
                                                            <div>
                                                                <Button variant="" className='cards-inner-button' onClick={() => { handelCreate(badge) }}>Create NFT</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                : ""}
                                        </React.Fragment>
                                    )
                                })
                                : ''
                        }
                    </ul>
                    :
                    <div className='not-found'>
                        {type === 'avatars' && (user?.avatars?.length === 0 || user?.avatars?.length === undefined || user?.avatars?.length === null) ? <p>No Avatar earned yet</p> : type === 'badges' && (user?.badges?.length === 0 || user?.badges?.length === undefined || user?.badges?.length === null) ? <p>No Badge earned yet</p> : ''}
                    </div>
                }

            </div>
            {/* <div className='table-responsive'>
                {type == 'avatars' && (user?.avatars?.length > 0) || type == 'badges' && (user?.badges?.length > 0) ?
                    <Table avatars-badges-badges>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>{type == 'avatars' ? 'Avatar Name' : type == 'badges' ? "Badge Name" : ''}</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loader ? <SkeletonLayout
                                align="center"
                                color="#000000"
                                items={[{
                                    height: 60,
                                    marginBottom: '1px',
                                    marginTop: 0,
                                    width: '100%',
                                    borderRadius: '5',
                                },
                                {
                                    height: 60,
                                    marginBottom: '1px',
                                    marginTop: 0,
                                    width: '100%',
                                    borderRadius: '5',
                                },
                                {
                                    height: 60,
                                    marginBottom: '1px',
                                    marginTop: 0,
                                    width: '100%',
                                    borderRadius: '5',
                                },
                                {
                                    height: 60,
                                    marginBottom: '1px',
                                    marginTop: 0,
                                    width: '100%',
                                    borderRadius: '5',
                                },
                                {
                                    height: 60,
                                    marginBottom: '1px',
                                    marginTop: 0,
                                    width: '100%',
                                    borderRadius: '5',
                                },
                                {
                                    height: 60,
                                    marginBottom: '1px',
                                    marginTop: 0,
                                    width: '100%',
                                    borderRadius: '5',
                                },
                                {
                                    height: 60,
                                    marginBottom: '1px',
                                    marginTop: 0,
                                    width: '100%',
                                    borderRadius: '5',
                                }
                                ]
                                }
                            />
                                :
                                type == "avatars" && user?.avatars?.length > 0 ?

                                    user?.avatars?.map((avatar, index) => {
                                        return (<>
                                            {avatar ?
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td><img src={`${process.env.REACT_APP_MEDIA_URL + avatar.name}`} className="img-fluid avatars-badges-img" alt="" /></td>
                                                    <td>{getName(avatar)}</td>
                                                    <td>
                                                        <Button variant="" className='sell-avatar-btn' onClick={() => { handelCreate({ name: getName(avatar), image: avatar.name }) }}>Create NFT</Button>
                                                    </td>
                                                </tr>
                                                : ""}
                                        </>
                                        )
                                    })

                                    : type == 'badges' && user?.badges?.length > 0 ?
                                        user?.badges?.map((badge, index) => {
                                            return (<>
                                                {badge ? <tr>
                                                    <td>{index + 1}</td>
                                                    <td><img src={`${process.env.REACT_APP_MEDIA_URL + badge.image}`} className="img-fluid avatars-badges-img" alt="" /></td>
                                                    <td>{badge.name}</td>
                                                    <td>
                                                        <Button variant="" className='sell-avatar-btn' onClick={() => { handelCreate(badge) }}>Create</Button>
                                                    </td>
                                                </tr> : ""}</>
                                            )
                                        })
                                        : ''
                            }
                        </tbody>
                    </Table>
                    :
                    <div className='not-found'>
                        {type === 'avatars' && (user?.avatars?.length == 0 || user?.avatars?.length == undefined || user?.avatars?.length == null) ? <p>No Avatar earned yet</p> : type == 'badges' && (user?.badges?.length == 0 || user?.badges?.length == undefined || user?.badges?.length == null) ? <p>No Badge earned yet</p> : ''}
                    </div>
                }

            </div> */}
        </section >
    );
}

export default AvatarsEarned;