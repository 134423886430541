import React from "react";
import { Route } from 'react-router-dom'
import Login from "./components/Login/Login";

export const PrivateRoute = ({ component:Component,path, access, ...rest}) => {
    return(
        <Route
        {...rest}
        render={props =>
            access ? (<Component {...props} />) : <Login path={path} Children={Component} props={props} />
        } />
    )
}
