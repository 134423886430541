import React, { useState, useRef, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faShare } from '@fortawesome/free-solid-svg-icons';
import ShareSvg from "../../assets/images/share-svg.svg"
import avatar from "../../assets/images/avatar-dummy.png"
import points from "../../assets/images/points.png"
import badges from "../../assets/images/badges.png"
import { showNotePopup } from "../../redux/user/action"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { multipart } from "../../redux/treasureBox/action"
import { ENV } from "../../config/config"
import * as htmlToImage from 'html-to-image';
const REACT_APP_SERVER_URL = ENV.serverUrl;

function MyVerticallyCenteredModal(props) {
    const dispatch = useDispatch();
    const modalRef = useRef(null);
    const [image, setImage] = useState();
    const [shareLoader, setShareLoader] = useState(false);
    let history = useHistory()
    var formData = new FormData();
    const onShare = async () => {
        console.log(props.popupData?.event?._id)
        dispatch(showNotePopup(false))
        history.push(`/shareContent?eventId=${props.popupData?.event?._id}`)
    }

    return (
        <div>
            <Modal ref={modalRef} {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="success-popup" id={'success-popup'}>
                <Button className="close-btn" onClick={() => { dispatch(showNotePopup(false)) }}>
                    <FontAwesomeIcon icon={faClose} name="searchbtn" />
                </Button>
                <Modal.Body>
                    {/* <h2>You got <strong>{props.popupData?.event?.points} Points</strong> for performing {props.popupData?.event?.eventType} task <strong>on {props.popupData?.event?.platform} Marketplace.</h2> */}
                    {props?.popupData?.event?.points > 0 ?
                        <h2 className="text-center px-xl-5">You got <strong>{props.popupData?.event?.points} Points</strong> for performing {ENV.rearrangeEventType(props.popupData?.event?.eventType)} task <strong>on {props.popupData?.event?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(props.popupData?.event?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(props.popupData?.event?.platform)}</strong>.</h2>
                        : props.popupData?.event?.points == 0 ?
                            <h2 className="text-center px-xl-5">You performed {ENV.rearrangeEventType(props.popupData?.event?.eventType)} task <strong>on {props.popupData?.event?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(props.popupData?.event?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(props.popupData?.event?.platform)}</strong>.</h2> :
                            <h2 className="text-center px-xl-5">Your <strong>{props.popupData?.event?.points * -1} points</strong> deducted for performing {ENV.rearrangeEventType(props.popupData?.event?.eventType)} task <strong>on {props.popupData?.event?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(props.popupData?.event?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(props.popupData?.event?.platform)}</strong>. </h2>
                    }
                    <ul className="d-flex justify-content-center text-center align-items-center flex-wrap mb-lg-5 mb-3">
                        {props.popupData?.event?.avatar ?
                            <li className="welcome-avatar">
                                <div className="image">
                                    <img src={`${REACT_APP_SERVER_URL}/media/${props.popupData?.event?.avatar}`} alt="" className="img-fluid" />
                                </div>
                                <span>{props.popupData.event?.avatar.substring(0, props.popupData?.event?.avatar.indexOf('.')).replaceAll('-', ' ')}</span>
                            </li>
                            : ""}

                        {props.popupData?.event?.badge ? <li className="welcome-badges">
                            <div className="image">
                                <img src={`${REACT_APP_SERVER_URL}/media/${props.popupData.event?.badge}`} alt="" className="img-fluid" />
                            </div>
                            <span>{props.popupData.event?.badge.substring(0, props.popupData.event?.badge.indexOf('.')).replaceAll('-', ' ')}</span>
                        </li> : ""}
                        {/* {props.popupData?.event?.points > 0 ? */}
                        {props.popupData?.event?.points != 0 ?
                            <li className="welcome-points">
                                <div className="image">
                                    <img src={points} alt="" className="img-fluid" />
                                </div>
                                <span>{
                                    props.popupData?.event?.points > 0 ? `+${props.popupData?.event?.points}` : props.popupData?.event?.points} Points</span>
                            </li>
                            : ""}
                    </ul>
                    <div className="row align-items-center">
                        <div className="col-lg-9 mb-lg-0 mb-3">
                            <div className="points-detail-row d-sm-flex justify-content-between">
                                <div className="d-flex me-2 justify-content-center">
                                    <span className="me-2">Platform:</span>
                                    <strong className="text-capitalize">{props.popupData?.event?.platform == 'nft' ? props.popupData?.event?.platform + ' Marketplace' : props.popupData?.event?.platform}</strong>
                                </div>
                                <div className="d-flex ms-2 justify-content-center">
                                    <span className="me-2">Event Type:</span>
                                    <strong className="text-capitalize">{ENV.rearrangeEventType(props.popupData?.event?.eventType)}</strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <button className="btn-gradient-blue d-flex mx-auto" onClick={onShare}>
                                {/* <button className="btn-gradient-blue d-flex mx-auto"> */}
                                {/* {shareLoader?
                            <span class="spinner-border spinner-border-sm tags-spinner-border me-2" role="status" aria-hidden="true"></span>
                            :''   
                        } */}
                                <span className="pe-2"><FontAwesomeIcon icon={faShare} /></span>
                                <span>Share</span>
                            </button>
                        </div>



                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

function SuccessPop(props) {

    return (
        <>
            <MyVerticallyCenteredModal
                show={props?.popupData?.event && props?.popupData?.event?.eventType ? true : false}
                popupData={props.popupData}
                onHide={() => props.setModalShow(false)}
            />
        </>
    );
}

export default SuccessPop;