import React from 'react'
import Banner from "./banner/banner.js"
import EarningSteps from "./earningSteps/earningSteps.js"

function landingComponents() {
  return (
    <div>
        <Banner/>
        <EarningSteps/>
    </div>
  )
}

export default landingComponents