import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import BannerImg from "../../../../assets/images/banner-card.png"
import BannerImgLayout from "../../../../assets/images/banner-card-layout.svg"
import Lottie from 'react-lottie';
import LottieOne from '../../../../assets/lottie/banner-lottie-1.json';
import LottieTwo from '../../../../assets/lottie/banner-lottie-2.json';
import BannerImgOne from '../../../../assets/images/inner-banner-1.png';
import BannerImgTwo from '../../../../assets/images/inner-banner-2.png';
import BannerImgThree from '../../../../assets/images/inner-banner-3.png';
import BannerImgFour from '../../../../assets/images/inner-banner-4.png';

import "./banner.css"

function banner() {
    const firstLottie = {
        loop: true,
        autoplay: true,
        animationData: LottieOne,
    };
    const secondLottie = {
        loop: true,
        autoplay: true,
        animationData: LottieTwo,
    };
  return (
    <div className='landing-wrap landing-banner py-sm-5 py-3 mb-lg-5 mb-3'>
        <Container fluid>
            <Row className='align-items-center'>
                <Col lg={7}>
                    <div className='text-content'>
                        <h2 className='title-heading'>Get Rewarded, Earn Points, and Redeem Exciting Rewards!</h2>
                        <p>Experience the Next Generation of Fun and Rewards with MYNTIST Gamification - Earn Points and Get Rewarded for Your Entertainment Choices.</p>
                        <div className='d-flex'>
                        <Link to={`/login`} className='btn-style-2 me-xl-3 me-2'>SIGNUP</Link>
                        <Link to={`/login`} className='btn-style-2 login'>LOGIN</Link>
                        </div>
                    </div>
                </Col>
                <Col lg={5}>
                    <div className='banner-main-lottie'>
                        <div className='lottie-wrap position-relative'>
                            <div className='banner-lottie-1'>
                                <Lottie options={firstLottie} />
                            </div>
                            <div className='banner-lottie-2'>
                                <Lottie options={secondLottie} />
                            </div>
                            <div className='banner-image-wrapper'>
                                <div className='banner-image-wrap'>
                                    <div className='banner-image'>
                                        <img src={BannerImg} alt="" className='img-fluid'/>
                                    </div>
                                    <div className='banner-image-layout'>
                                        <img src={BannerImgLayout} alt="" className='img-fluid'/>
                                    </div>
                                    <div className='banner-inner-img banner-inner-img-1'>
                                        <img src={BannerImgOne} alt="" className='img-fluid'/>
                                    </div>
                                    <div className='banner-inner-img banner-inner-img-2'>
                                        <img src={BannerImgTwo} alt="" className='img-fluid'/>
                                    </div>
                                    <div className='banner-inner-img banner-inner-img-3'>
                                        <img src={BannerImgThree} alt="" className='img-fluid'/>
                                    </div>
                                    <div className='banner-inner-img banner-inner-img-4'>
                                        <img src={BannerImgFour} alt="" className='img-fluid'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='banner-img'>
                        <img src={BannerImg} alt="redeem" className='img-fluid'/>
                    </div> */}
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default banner