
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState  } from "react"
import { ENV } from "../../config/config"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import GamificationLoader from '../gamificationLoader/GamificationLoader';
import { getUserReqeusts } from '../../redux/requests/action';

function AllActivities() {
    const [activeButton, setActiveButton] = useState(null);
    const requests = useSelector(state => state.requests.requests)

    let loaderA = useSelector(state => state.userData.loaderActive)
    const dispatch = useDispatch();
    let walletAddress = ENV.getUserKey("WalletAddress")

    const handleClick = (buttonName) => {
        setActiveButton(buttonName);
        let status = ""
        if(buttonName === "Approved"){
            status = "true"
        }else if(buttonName === "Rejected"){
            status = "false"
        }else if (buttonName === "Pending"){
            status = "pending"            
        }
        dispatch(getUserReqeusts(walletAddress, status))
    };

    useEffect(async () => {
        if (walletAddress) {
            dispatch(getUserReqeusts(walletAddress, ""))
        }
    }, [])
    const showRequests = () => {
        if(requests){
            if(requests.length > 0){
                return requests.map((request, key) => {
                    let {points, icon, iconClass} = ""
                    if(request.approved === "true"){
                       points = <strong className='points achieved-points'>+{request.task.points}</strong>
                       icon = <FontAwesomeIcon icon={faCheck} />
                       iconClass = "achieved-icon"
                    }else if(request.approved === ""){
                        icon = <FontAwesomeIcon icon={faClockRotateLeft} />
                        iconClass = "pending-icon"
                    }else{
                        icon = <FontAwesomeIcon icon={faXmark} />
                        iconClass = "rejected-icon"
                    }
                    return (
                        <li className="" key={key} >
                            <div className='px-3 py-1 d-flex'>
                                <span className={`icon ${iconClass}`}>{icon}</span>
                                <p dangerouslySetInnerHTML={{ __html: request.contestData?.[0]?.description }} ></p>
                                {points}
                            </div>
                        </li>
                    )
                })
            }else{
                return "No requests found!"
            }
        }else{
            return ""
        }
    }
    return (
        <div className="main-dashboard">
            {loaderA ? <GamificationLoader /> : null}
            <div className="row">
                <div className="all-activities">
                    <div className='btn-group'>
                        <buttonGroup aria-label="Basic example">
                            <button className={activeButton === 'All' ? 'active' : ''} onClick={() => handleClick('All')}>All</button>
                            <button className={activeButton === 'Approved' ? 'active' : ''} onClick={() => handleClick('Approved')}>Approved</button>
                            <button className={activeButton === 'Rejected' ? 'active mx-1' : ' mx-1'} onClick={() => handleClick('Rejected')}>Rejected</button>
                            <button className={activeButton === 'Pending' ? 'active' : ''} onClick={() => handleClick('Pending')}>Pending</button>
                        </buttonGroup>
                    </div>
                    <ul>
                        {showRequests()}
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default AllActivities;