import React from "react";
import Header from '../components/includes/header/header';

class Layout2 extends React.Component {
    render() {
        const { children } = this.props
        return (
            <>
                <Header />
                <div className="main-content-area pt-5">
                    {children}
                </div>
            </>
        )
    }
}
export default Layout2;