import { GET_BADGES, SET_LOADER, POST_BADGES, GET_SINGLE_BADGE } from "../actionTypes";
import { apiHelper } from '../apiHelper';
import { ENV } from "./../../config/config";
let token = localStorage.getItem("token")
let encToken = ENV.encryptToken(token)
export const getBadges = () => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        token = localStorage.getItem("token")
        encToken = ENV.encryptToken(token)
        const res = await apiHelper("get", `/v1/badge`, "", encToken);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            if (res?.data) {
                let { data } = res;
                dispatch({
                    type: GET_BADGES,
                    payload: data
                })
            }
        }
    } catch (error) {
        console.log(error.message);
    }
}
export const getBadgeById = (badgeId) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        token = localStorage.getItem("token")
        encToken = ENV.encryptToken(token)
        // const res = await apiHelper("get", `/v1/badge`, "", encToken);
        let res = await apiHelper("get", `/v1/badge/${badgeId}`, "", encToken);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            if (res?.data) {
                let { data } = res;
                dispatch({
                    type: GET_SINGLE_BADGE,
                    payload: data
                })
            }
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const postBadges = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });

        const res = await apiHelper("post", `/v1/badge`, data);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            if (res?.data) {
                let { data } = res;

                dispatch({
                    type: POST_BADGES,
                    payload: data
                })
            }
        }
    } catch (error) {
        console.log(error.message);
    }
}