import React from "react";
import LeftSidebar from "../components/dashboard/sidebar/leftSidebar/leftSidebar"
import BreadCrumb from "../components/breadCrumb/breadCrumb";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from "react-redux";
import { ENV } from "../config/config"

const Layout1 = (props) => {
    // render() {
    const location = useLocation();
    let walletAddress = useSelector(state => state?.walletProvider?.currentUser) || ENV.getUserKey("WalletAddress")

    const { children } = props;
    return (
        <div className="main-dashboard container-fluid">
            <div className="row ">
                {/* {!walletAddress || walletAddress == "null" ? "" : <LeftSidebar />} */}
                <LeftSidebar />
                <div className="main-content-area">
                    {location.pathname.includes("ashboard") ? "" : <BreadCrumb />}
                    {children}
                </div>

            </div>
        </div>
    )
    // }

}
export default Layout1;