import {
    SHOW_ERR_POPUP, SHOW_NOTIFICATION_POPUP, SET_UNREAD_NOTIFICATIONS, POST_READ, SET_NFTLOADER, NFT_SOLD_LOADER, NFT_CREATED, SET_UNSEENEVENTS, SET_EVENT, SET_NOTIFICATIONS, SET_REFERRALS, UPDATE_GIFTCARD, SINGLE_GIFTCARD, POINTS_DATA, EDIT_STATUS, GET_GIFTCARD, PROF_COMPLETENESS, GET_REWARDS_HISTORY,
    POST_GIFTCARD, GET_STATUS, GET_LEADERBOARD, GET_NFTS, GET_ACTIVITIES, ALL_ACTIVITIES, GET_OFFERS, POST_EVENT, NFT_URL,
    GET_COLLECTIONS, GET_COLLECTIBLES, GET_ABOUT, UPDATE_GAMEPROFILE, GET_USERNFTS, UPDATE_PROFILE, REGISTER, DELETE_GIFTCARD, SET_LOADER, SET_EVENT_HISTORY, GET_STATS, UPDATE_USER
} from "../actionTypes";
import { toast } from "react-toastify";
import { ENV } from "./../../config/config";
import { apiHelper, apiHelperLive } from "../apiHelper";
import offers from "../../jsons/recentOffers.json"
// import { getActions } from "../../../../admin/src/apiHandling/apiCaller";
import swal from 'sweetalert2';
let token = localStorage.getItem("token")
let addressForLiveApi = "0x5241b6b1082080C7f103d3C5CA5EAe55eCEA3293"
let myDummyAddress = "0x0297209262AA172478b4B5A54b46A7d1cC77a80B"
let encToken = ENV.encryptToken(token)

///////////////////// user actions///////////////////

export const getDailyClaimRewards = (address) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/users/history/${address}`, "", encToken)
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_REWARDS_HISTORY,
                    payload: data.events
                })
            }
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}

export const getUserStatus = (data) => async (dispatch) => {
    try {
        // dispatch({
        //     type: SET_LOADER,
        //     payload: true
        // })
        let res
        if (data.token) {

            res = await apiHelper("get", `/v1/users?address=${data.address}&&userId=${data.userId}`, data.token)
        }
        else {
            res = await apiHelper("get", `/v1/users?address=${data.address}&&userId=${data.userId}`, "")

        }
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                localStorage.setItem("token", data?.data?.accessToken)
                dispatch({
                    type: GET_STATUS,
                    payload: data.data
                })

            }
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const getCurrentUser = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })

        let res = await apiHelper("get", `/v1/users/current`, data, encToken)
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_STATUS,
                    payload: data
                })
            }
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}

export const editUserStatus = (data) => async (dispatch) => {
    try {
        dispatch({
            type: NFT_SOLD_LOADER,
            payload: true
        })
        // dispatch({
        //     type: NFT_CREATED,
        //     payload: false
        // })
        data.formData.platform = "Gamification"
        let enc = ENV.encryptPayload(data.formData)
        let data2 = { enc: enc }
        let res = await apiHelper("put", `/v1/users?address=${data.address}&&userId=${data.userId}`, data2, encToken)
        // let res = await apiHelper("post", `/v1/users/${addressForLiveApi}`, data.formData)
        if (res) {
            dispatch({
                type: NFT_SOLD_LOADER,
                payload: false
            })
            if (res.data) {

                let { data } = res
                dispatch({
                    type: EDIT_STATUS,
                    payload: data.data
                })
                dispatch({
                    type: NFT_CREATED,
                    payload: false
                })
                // /toast.success("Profile updated successfully!")
                return res
            }
        }
    } catch (error) {
        console.log(error)
        toast.error('Profile is not updated!')
    }
}

export const postShared = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("put", `/v1/treasurebox/share/${data.postId}`, data)

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

            if (res?.data) {
                // let { data } = res
                // dispatch({
                //     type: SET_ALL_USERS,
                //     payload: data.leaderBoard
                // })
            }
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const postFollowersEvent = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("post", `/v1/treasurebox/social/points-update`, data)

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

            if (res?.data) {
            }
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const setSoldLoader = (data) => async (dispatch) => {
    try {
        dispatch({
            type: NFT_SOLD_LOADER,
            payload: "something"
        })
        dispatch({
            type: NFT_CREATED,
            payload: false
        })
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const editUserStatusCopy = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let enc = ENV.encryptPayload(data.formData)
        data.enc = enc
        let res = await apiHelper("post", `/v1/users/${data.userId}`, data)
        // let res = await apiHelper("post", `/v1/users/${addressForLiveApi}`, data.formData)
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

            if (res.data) {
                let { data } = res

                if (data.code === 404) {
                    let walletAddress = ENV.getUserKey("WalletAddress")
                    dispatch(RegisterUser({
                        address: walletAddress,
                        name: "",
                        image: ""
                    }))
                }
                dispatch({
                    type: EDIT_STATUS,
                    payload: data.data
                })
            }
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}

export const getPointHistory = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let enc = ENV.encryptPayload(data)

        let data2 = { enc: enc }

        let res = await apiHelper("post", `/v1/users/event/history`, data2, encToken);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: SET_EVENT_HISTORY,
                    payload: data
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const getStats = (data) => async (dispatch) => {
    try {
        // dispatch({
        //     type: SET_LOADER,
        //     payload: true
        // })
        let res = await apiHelper("get", `/v1/users/mapStats/${data.type}?address=${data.address}`, "", encToken)
        if (res) {
            // dispatch({
            //     type: SET_LOADER,
            //     payload: false
            // })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_STATS,
                    payload: data
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const getProfileCompleteness = (walletAddress) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/users/profile-completenes/${walletAddress}`, "", encToken)
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: PROF_COMPLETENESS,
                    payload: data.data
                })
            }
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const RegisterUser = (user, history) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })

        user.platform = "Gamification"
        let enc = ENV.encryptPayload(user)
        let data = {
            enc: enc
        }

        let res = await apiHelper("post", `/v1/users?address=${user.address}`, data)
        if (res) {

            dispatch({
                type: SET_LOADER,
                payload: false
            })
            localStorage.setItem('WalletAddress', user.address)
            localStorage.setItem("userId", user.userId)
            if (res?.data) {

                let { data } = res
                let tok = data.data.accessToken
                if (tok === undefined) {
                    const options = {
                        // onClose: () => window.location.reload()
                        // onClose: () => history.push('/connect')
                    }
                    localStorage.removeItem('WalletAddress')
                    localStorage.removeItem("userId")
                    localStorage.removeItem("token")
                    toast.error("No access Token, Please try again.", options)
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 5000)
                    history.push('/login')
                }
                localStorage.setItem("token", tok)

                dispatch({
                    type: REGISTER,
                    payload: data.data
                })
                return data
            }
        }
    } catch (error) {
        console.log(error)
        toast.error("Some thing went wrong. please try later...")
    }
}
export const getLeaderboard = (query, id) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: query.loader
        })
        let res;
        if (id === "" || id === 'undefined') {
            res = await apiHelper("get", `/v1/users/ranking?filter=${query.filter}`, "", encToken)
        } else {
            res = await apiHelper("get", `/v1/users/ranking?filter=${query.filter}&contestId=${id}`, "", encToken)
        }
        if (res) {

            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_LEADERBOARD,
                    payload: data.leaderBoard
                });
            }
            dispatch({
                type: SET_LOADER,
                payload: false
            })
        }

    } catch (error) {
        console.log(error);
        // toast.error(error.response.message);
    }
}

//////////////// NFT actions ///////////////

export const getNfts = (address) => async (dispatch) => {
    try {
        let res = await apiHelperLive("get", `/nfts/recent/${address}`, "")
        // let res = await apiHelperLive("get", `/nfts/recent/0x5241b6b1082080C7f103d3C5CA5EAe55eCEA3294`, "")
        if (res) {
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_NFTS,
                    payload: data
                });

            }
            dispatch({
                type: SET_NFTLOADER
            })
        }
    } catch (error) {
        console.log('IN ERROR SECTION ============>', error);
        // toast.error(error.response.message);
    }
}

export const updateGamificationProfile = (Data) => async (dispatch) => {

    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let enc = ENV.encryptPayload(Data)
        let data = {
            enc: enc
        }
        let res = await apiHelper("put", `/v1/users/${Data.address}`, data, encToken)
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            if (res?.data) {
                let { data } = res;
                dispatch({
                    type: UPDATE_GAMEPROFILE,
                    payload: data
                });
            }
        }
    } catch (error) {
        console.log(error);

    }
}


export const updateProfile = (Data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })

        let res = await apiHelperLive("put", `/users/update`, Data)
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            if (res?.data) {
                let { data } = res;
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: data
                });
            }
        }
    } catch (error) {
        console.log(error);

    }
}

export const getUserNfts = (address) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelperLive("get", `/users/${address}`, "")
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                // console.log(res.data.user.createdNFTs,"heellllllllllllllloooooooooo")
                let { data } = res
                dispatch({
                    type: GET_USERNFTS,
                    payload: data
                });
            }
        }
    } catch (error) {
        console.log(error);
        // toast.error(error.response.message);
    }
}


//////////// gift card actions //////////////

export const getGiftCard = () => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        token = localStorage.getItem("token")
        encToken = ENV.encryptToken(token)
        let res = await apiHelper("get", `/v1/coupon`, "", encToken)
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {

                dispatch({
                    type: GET_GIFTCARD,
                    payload: res.data
                });
            }
        }

    } catch (error) {
        console.log(error.message);
    }

}
export const updateGiftCard = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("put", `/v1/card/${data.id}`, data);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {

                dispatch({
                    type: UPDATE_GIFTCARD,
                    payload: res.data
                });
            }
        }

    } catch (error) {
        console.log(error.message);
    }
}
export const deleteGiftCard = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("delete", `/v1/card/${id}`, "");
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {

                dispatch({
                    type: DELETE_GIFTCARD,
                    payload: res.data
                });
            }
        }

    } catch (error) {
        console.log(error.message);
    }

}
export const getGiftCardbyID = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/card/${id}`, "", encToken);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {

                dispatch({
                    type: SINGLE_GIFTCARD,
                    payload: res.data
                });
            }
        }


    } catch (error) {
        console.log(error.message);
    }

}
export const postGiftCard = (giftCard) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("post", `/v1/card`, giftCard);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                dispatch({
                    type: POST_GIFTCARD,
                    payload: res.data
                });
            }
        }

    } catch (error) {
        console.log(error.message);
    }
}
////////////////// activities actions ///////////////////////

export const getActivities = (userData) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelperLive("get", `/activity/recent/${userData.walletAddress}`, "")
        // let res = await apiHelperLive("get", `/activity/recent/${addressForLiveApi}`, "")
        dispatch({
            type: SET_LOADER,
            payload: false
        })
        if (res) {
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: ALL_ACTIVITIES,
                    payload: data.activities
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}

///////////////////Recent offers module actions//////////////////////

export const getRecentOffers = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        // let res = await apiHelper("get", `/v1/offers/${data.address}`, "")

        // if (res?.data) {
        //     let { data } = res
        dispatch({
            type: GET_OFFERS,
            payload: offers
        })
        // }
        dispatch({
            type: SET_LOADER,
            payload: false
        })
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
/////////////// collections actions ////////////////////////

export const getCollections = (address) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelperLive("get", `/collection/recent/${address}`, "")
        // let res = await apiHelperLive("get", `/collection/recent/${addressForLiveApi}`, "")
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_COLLECTIONS,
                    payload: data
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
////////////// collectibles module actions //////////////////////

export const getCollectibles = (address) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelperLive("get", `/nfts/collectibles/${address}`, "")
        // let res = await apiHelperLive("get", `/nfts/collectibles/${addressForLiveApi}`, "")
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_COLLECTIBLES,
                    payload: data
                })
            }
        }


    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}


export const createNFT = (data) => async (dispatch) => {
    try {

        dispatch({
            type: NFT_SOLD_LOADER,
            payload: true
        })
        let res = await apiHelperLive("post", `/nfts/create`, data)
        if (res) {
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: NFT_URL,
                    payload: data
                })
                // toast.success(data.message)
                dispatch({
                    type: NFT_CREATED,
                    payload: true
                })
                dispatch({
                    type: NFT_SOLD_LOADER,
                    payload: false
                })
            }
            return res
        }

    } catch (error) {
        console.log(error)
        dispatch({
            type: NFT_URL,
            payload: { success: false, message: "Something went wrong." }
        })
        // toast.success(data.message)
        dispatch({
            type: NFT_SOLD_LOADER,
            payload: false
        })
        // toast.error(error.response.message)
    }
}



// Clear NFT Data
export const clearNftData = () => async (dispatch) => {
    try {
        dispatch({
            type: NFT_URL,
            payload: {}
        })

    } catch (err) {
        console.log(err)
    }
}


/////////////////points module actions ////////////////////
export const getUserPoints = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/users/points/pointsHistory?address=${data.address}&&userId=${data.userId}`, "", encToken)
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: POINTS_DATA,
                    payload: data.points
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const setLoader = (address) => async (dispatch) => {
    dispatch({
        type: SET_LOADER,
        payload: true
    })


}

export const claimDailyPoints = (data) => async (dispatch) => {
    try {
        let icon = "warning"
        dispatch({
            type: SET_LOADER,
            payload: true
        })

        let enc = ENV.encryptPayload(data)

        let data2 = { enc: enc }

        let res = await apiHelper("put", `/v1/users/claim/${data.userId}`, data2, encToken);
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                dispatch({
                    type: UPDATE_USER,
                    payload: res.data.user
                });
                if (res?.data.message) {
                    if (res?.data.code === 200) {
                        icon = "success"
                        dispatch({
                            type: SHOW_NOTIFICATION_POPUP,
                            payload: true
                        })
                    } else {
                        icon = "warning"
                        dispatch({
                            type: SHOW_ERR_POPUP,
                            payload: true
                        })
                        // swal.fire({
                        //     title: `${res?.data.message}`,
                        //     text: "",
                        //     icon: `${icon}`,
                        //     // showCancelButton: true,
                        //     confirmButtonColor: '#3085d6',
                        //     cancelButtonColor: '#d33',
                        //     // confirmButtonText: 'Yes, delete it!'
                        // })
                    }

                }
            }
        }

    } catch (error) {

        // toast.error(error.response.message)
    }
}
export const getReferrals = (userId) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/users/referrals/${userId}`, "", encToken)

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: SET_REFERRALS,
                    payload: data.data
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}



export const getNotifications = (address) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/notifications/list-by-id/${address}`, "", "")
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            // dispatch({
            //     type: POST_DELETED,
            //     payload: false
            // })
            dispatch({
                type: POST_READ,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: SET_NOTIFICATIONS,
                    payload: data.notes
                })
                dispatch({
                    type: SET_UNREAD_NOTIFICATIONS,
                    payload: data?.unseenNotifCount[0]?.count
                })
            }
        }

    } catch (error) {
        console.log(error)
    }

}
export const getEvents = (address) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/users/event/${address}`, "", encToken)

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_ACTIVITIES,
                    payload: data
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const readNotification = (postId) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("post", `/v1/notifications/${postId}`, "", "")

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

            if (res?.data) {
                // let { data } = res
                dispatch({
                    type: POST_READ,
                    payload: true
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const getSingleEvent = (eventId) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/users/singleEvent/${eventId}`, "", encToken)

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: SET_EVENT,
                    payload: data.event
                })
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const updateSeenEvent = (eventId) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })


        let res = await apiHelper("put", `/v1/users/singleEvent/${eventId}`, "", encToken)

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })

        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const getUnseenEvents = (address) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let res = await apiHelper("get", `/v1/users/events/unseen/${address}`, "", encToken)

        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (res?.data) {
                let { data } = res

                dispatch({
                    type: SET_UNSEENEVENTS,
                    payload: data.data
                })
            }

        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}
export const postEvent = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });

        const res = await apiHelper("post", `/v1/users/event`, data);
        if (res?.data) {
            dispatch({
                type: POST_EVENT,
                payload: res.data
            });
            dispatch({
                type: SET_LOADER,
                payload: false
            });
        }
        else {
            console.log("error in response")
        }

    } catch (error) {

        console.log(error.message)
    }
}

export const showNotePopup = (show) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_NOTIFICATION_POPUP,
            payload: show
        })
        // dispatch({
        //     type: SHOW_ERR_POPUP,
        //     payload: show
        // })
    } catch (error) {
        console.log(error.message)
    }
}

export const showErrorPopup = (show) => async (dispatch) => {
    try {

        dispatch({
            type: SHOW_ERR_POPUP,
            payload: show
        })
    } catch (error) {
        console.log(error.message)
    }
}


