import { ENV } from "../../../config/config"
import { useDispatch, useSelector } from "react-redux";
import { getUserStatus } from "../../../redux/user/action"
import { getBadges, getBadgeById } from '../../../redux/badges/action'
import Loader from "../../loader/loader"
import { useEffect, useState } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import SocialShare from "../../socialShare/socialShare";
// import { useParams } from 'react-router-dom'
// import { Button } from "bootstrap";
// import { Modal } from 'react-bootstrap';

function Badges() {
    const badgesEarned = useSelector(state => state.userData?.profile?.badges)
    const loaderB = useSelector(state => state.userData.loaderActive)
    const badges = useSelector(state => state.badgesData.badges)
    const loaderA = useSelector(state => state.badgesData.loaderActive);
    const dispatch = useDispatch();
    const [editShow, seteditShow] = useState(false);
    const [myBadges, setBadges] = useState([])
    const [singlebadge, setSingleBadges] = useState()
    let address = localStorage.getItem("WalletAddress");

    // let WalletAddress = ENV.getUserKey('WalletAddress')
    // useEffect(async () => {
    //     if (!badgesEarned) {
    //         await dispatch(getUserStatus({ address }))

    //     } else {
    //         setBadges(badgesLog())
    //     }
    // }, [badgesEarned, badges])
    // useEffect(()=>{
    //     setBadges(badgesLog())
    // },[myBadges])
    // useEffect(async () => {
    //     if(!badgesEarned){
    //     await dispatch(getBadges())
    //     }
    // }, [badgesEarned,myBadges])
    useEffect(async () => {
        if (!badgesEarned || badgesEarned == undefined) {
            await dispatch(getUserStatus({ address }))
        }
    }, [badgesEarned, badges])
    useEffect(async () => {
        if (badges.length === 0) {
            await dispatch(getBadges())
        }
    }, [badges])
    useEffect(() => {
        if (badges && badgesEarned) {
            setBadges(badgesLog())
        }
    }, [badges, badgesEarned])

    const handleEditClose = () => {
        seteditShow(false);
    }

    const badgesLog = () => {
        let toatalBadges = badges && badges
        let index;
        badgesEarned && badgesEarned.forEach(element => {
            index = badges && badges.findIndex(x => x.name === element.name)
            if (index >= 0) {
                toatalBadges[index].status = true;
            }
        });
        return toatalBadges
    }
    const handleEditShow = async (id) => {
        let badge = myBadges.filter(singleBadge => singleBadge._id === id)
        setSingleBadges(badge[0])
        seteditShow(true);
    }

    return (
        <>
            {loaderA && loaderB ? <Loader /> : null}
            <div className="page-title">
                <h2>Badges</h2>
            </div>
            <div className="badges">
                <Container fluid>
                    <Row>
                        {myBadges && myBadges.length > 0 ? myBadges && myBadges.map((badge, key) => {
                            console.log(badge)
                            return (
                                <>
                                    <Col sm="6" xl="4" xxl="3" className="mb-3" key={key}>
                                        <div onClick={() => handleEditShow(badge?._id)} className={`item ${badge.name} ${badge.status ? 'active badges-description' : 'badges-description'} `}>
                                            <div className="image-bg">
                                                <div className="image">
                                                    <img src={ENV.media_url + badge.picture} alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <h5>{badge.name}</h5>
                                            <p>{badge.description}</p>
                                            {/* {badge.status?<SocialShare imageUrl={ENV.media_url + badge.picture} postCaption={badge.name+'<br>'+badge.description} media_type={'image'}/>:''} */}
                                        </div>
                                    </Col>
                                </>
                            )
                        }) : "No badge to show"}
                    </Row>
                </Container>
            </div>

            <Modal className="promotion-modal d-flex modal-animation special-notes-modal" show={editShow} onHide={handleEditClose} animation={true} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="text-center" closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="h-100 align-items-center">
                        <Col md={6} className="d-flex align-items-center justify-content-center">
                            <div className="badges-image">
                                <img src={ENV.media_url + singlebadge?.picture} alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="content">
                                <h2>How To Achieve {singlebadge?.name}</h2>
                                <div>
                                    <>
                                        {/* <p>{singlebadge?.name}</p> */}
                                        <p>{singlebadge?.pointsRequired}</p>
                                        <p>{singlebadge?.description}</p>
                                        <div className="social-sharing">
                                           <SocialShare imageUrl={ENV.media_url + singlebadge?.picture} postCaption={singlebadge?.name} media_type={'image'} taskType={'image'} />
                                        </div>
                                    </>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Badges;