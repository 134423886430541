import Activities from '../../../activities/activities'
import Badges from '../../../profile/badges/badgesEarned'
import { Link } from 'react-router-dom'
import Status from "../../../userStatus/status"

function RightSidebar() {
    return (
        <div className="right-sidebar">
            <Status />
            <Badges />
            {/* <div className="daily-rewards">
                <div className="section-dark-bg">
                    <div className="heading">
                        <h4>Daily Rewards</h4>
                        <Link to="#" className="view-all">VIEW ALL</Link>
                    </div>
                    <ul>
                        <li className="claim">
                            <span>Diamond Reward </span>
                            <Link to="#" className="">Claim Now</Link>
                        </li>
                        <li className="claimed">
                            <span>Ranked Up in Leaderboard</span>
                            <Link to="#" className="">Claimed</Link>
                        </li>
                        <li className="locked">
                            <span>Upgrade your level </span>
                            <Link to="#" className="">Locked</Link>
                        </li>

                    </ul>
                </div>
            </div> */}
            <Activities />
        </div>
    );
}

export default RightSidebar;