import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import { getQueryParam } from '../../config/helper';

const Index = (props) => {
    const location = useLocation();
    let address = getQueryParam(location, 'address')
    if (!address) {
        address = localStorage.getItem("WalletAddress")
    }
    // let postType = getQueryParam(location, 'postType')
    let oauth_token = getQueryParam(location, 'oauth_token')
    let oauth_verifier = getQueryParam(location, 'oauth_verifier')

    useEffect(async () => {
        setTimeout(async () => {
            window.location.href = process.env.REACT_APP_SERVER_URL + '/badges/' + address +'?oauth_token=' + oauth_token + '&&oauth_verifier=' + oauth_verifier
        }, 4000)
    }, [])
    return (
        <>
            <Container fluid>
                <div className='heading-img-sec d-flex justify-content-center align-items-center connect-twitter-model'>
                    <div className='success-box'>
                        <h3 className='mb-3'>You have successfully connected your Twitter account to Myntist</h3>
                        <h3 className='mb-4'>Redirecting to the post</h3>
                        <a class="main-btn-style" href={process.env.REACT_APP_SERVER_URL + '/badges/' + address + '?oauth_token=' + oauth_token + '&&oauth_verifier=' + oauth_verifier + '&&route=Badges'} >
                            {/* <div><Lottie options={ViewLottie} /></div> */}
                            <span>Click Here To Open Post</span>
                        </a>
                    </div>
                </div>
            </Container >
        </>
    )
}

export default Index