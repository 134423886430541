import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory, Link, useParams, useLocation } from "react-router-dom";
import { getUserStatus, getUserPoints, getUserNfts, editUserStatus } from "../../redux/user/action";
import { ENV } from "../../config/config";
import ProgressBar from "../progress/progress";
import EditClickModal from "./EditClickModal";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import PlaceholderImg from "../../assets/images/placeholder-copy.png"

let base = ENV.media_url

function UserStatus(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    let location = useLocation();
    let searchParams = new URLSearchParams(location.search)
    let code = searchParams.get('code')
    let userId = localStorage.getItem("userId")
    // let address = searchParams.get('address')
    const user = useSelector((state) => state.userData?.profile);
    const success = useSelector((state) => state.userData.success);
    const points = useSelector((state) => state.userData.points);
    const userNfts = useSelector((state) => state.userData.nfts);
    const loader = useSelector((state) => state.userData.loaderActive);
    const address = ENV.getUserKey("WalletAddress");
    let value = 0;
    let userPoints = 0;
    if (user) {
        let pointsData = user?.pointsData ? user?.pointsData : 0;
        for (let index = 0; index < pointsData.length; index++) {
            userPoints = pointsData[index].points + userPoints;
        }
    }


    const [copied, setCopied] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    useEffect(() => {
        dispatch(getUserStatus({ address }));


        if (address) {
            setUserInfo({
                address: address
            })
        } else {

            if (userId) {
                setUserInfo({
                    address: userId
                })
            }
        }
    }, [])

    useEffect(() => {
        if (copied) {
            toast.success("copied to clipboard ")
            setCopied(false)
        }
    }, [copied])


    useEffect(() => {
        if (userNfts != undefined || user == undefined) {
            userNFTAdded();
        }


    }, [userNfts, user])

    const userNFTAdded = () => {
        let formData = {}
        if (userNfts !== undefined) {
            formData.nfts = userNfts?.user?.createdNFTs
        }
        if (address) {
            formData.address = address
        }
        let data = {
            address: address,
            formData: formData
        }
        if (!user) {
            dispatch(editUserStatus(data))
        }
    }
    useEffect(() => {
        if (Object.keys(userInfo).length > 0) {
            dispatch(getUserPoints(userInfo));
        }
    }, [userInfo]);

    points &&
        points.forEach((point) => {
            value = value + point.points;
        });
    function nth(n) { return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th" }

    return (
        <>
            <div className="user-info">
                <div className="section-dark-bg user-about-bg">
                    <div className="user-about">
                        <div className="image d-edit-image custom-avatar-img">
                            {user ?
                                <img src={user?.picture ? base + user?.picture : PlaceholderImg} alt="User" className="img-fluid " />
                                : <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                                    <div className="spinner-border" role="status">
                                    </div>
                                </div>
                            }
                            <div className="overlay">
                                <div className="hove-edit">
                                    <EditClickModal />
                                </div>
                            </div>
                        </div>
                        <div className="about-details">
                            <h5>
                                {user?.name ? user?.name : "No Name"}
                            </h5>
                            <p>User ID: {user?.address?.length > 0 ?
                                user?.address?.slice(user?.address.length - 4, user?.address.length).toUpperCase() :
                                user?.userId?.slice(user?.userId.length - 4, user?.userId.length).toUpperCase()
                            }</p>
                        </div>
                    </div>
                    <Link to={`/progress/${address}?route=Progress`}>
                        <div className="mini-progress-stats">
                        <ProgressBar />
                        </div>
                    </Link>
                    <div className="user-stats">
                        <ul>
                            <li>
                                <span>My Score</span>
                                < h4 > {
                                    loader ?
                                        <div class='spinner-border' ></div> : userPoints}
                                </h4>
                            </li>
                            <li>
                                <span>My Rank</span>
                                <h4>{loader ?
                                    <div class='spinner-border'></div> : user?.rank ? <>{user?.rank} <sup>{nth(user?.rank)}</sup> </> : 0}
                                </h4>
                            </li>
                            <li>
                                <span>Total NFTs</span>
                                <h4>{loader ?
                                    < div class='spinner-border'></div> : user?.nftsCount ? user?.nftsCount > 0 ? user?.nftsCount : 0 : 0
                                }
                                
                                </h4>
                            </li>
                            <li>
                                <span>Total Assets</span>
                                <h4>{loader ?
                                    <div class='spinner-border'></div> :
                                    (user?.nftsCount > 0 ? user?.nftsCount : 0) + (user?.badges?.length > 0 ? user?.badges?.length : 0) + (user?.avatars?.length > 0 ? user?.avatars?.length : 0)}
                                </h4>
                            </li>
                        </ul>

                    </div>
                    <Link to={`/settings?route=Settings`}>
                        <div className="user-edit">
                            <FontAwesomeIcon icon={faPencil} name="searchbtn" />
                        </div>
                    </Link>
                </div>
            </div >
        </>
    );
}

export default UserStatus;