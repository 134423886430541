import { GET_PROGRESS_LEVELS, GET_TASKS, GET_LEVELS, GET_PLATFORM, SET_LOADER, GET_SINGLE_TASKS } from "../actionTypes";
import { apiHelper } from "../apiHelper";
import { ENV } from "./../../config/config";
let token = localStorage.getItem("token")
let encToken = ENV.encryptToken(token)
let dummyAddress = "0x0297209262AA172478b4B5A54b46A7d1cC77a80B"
export const getTasks = (limit) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        encToken = ENV.encryptToken(localStorage.getItem("token"))

        let res = await apiHelper("get", `/v1/task?limit=${limit.per_page}&offset=${limit.page}`, "", encToken)
        if (res?.data) {
            let { data } = res
            dispatch({
                type: GET_TASKS,
                payload: data
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            });
        }
    } catch (error) {
        console.log(error)
    }
}
export const getSingleTask = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        let res = await apiHelper("get", `/v1/task/${id}`, "", encToken)
        if (res?.data) {
            let { data } = res
            dispatch({
                type: GET_SINGLE_TASKS,
                payload: data[0]
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            });
        }
    } catch (error) {
        console.log(error)
    }
}


export const getLevels = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        let res = await apiHelper("get", `/v1/level/tasksCriteria`, "", encToken)

        if (res?.data) {
            let { data } = res
            dispatch({
                type: GET_LEVELS,
                payload: data
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            });
        }
    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}

export const getPlatforms = (id) => async (dispatch) => {
    try {
        // let res = await apiHelper("get", `/v1/users/${address}`, "")
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        let res = await apiHelper("get", `/v1/platform`, "", encToken)
        if (res) {
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_PLATFORM,
                    payload: data
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                });
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}

export const getProgressLevels = () => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        let res = await apiHelper("get", `/v1/level/progress`, "", encToken);
        if (res) {
            if (res?.data) {
                let { data } = res
                dispatch({
                    type: GET_PROGRESS_LEVELS,
                    payload: data
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                });
            }
        }

    } catch (error) {
        console.log(error)
        // toast.error(error.response.message)
    }
}