import { SET_ACTIVITY_POPUP, ACKNOWLEDGE_ACTIVITY, VERIFY_ACTIVITY, GET_POPUPS, SET_LOADER, POST_BADGES, GET_SINGLE_BADGE } from "../actionTypes";

const initialState = {
    loaderActive: false
}
const popupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POPUPS:
            return {
                ...state,
                popups: action.payload
            }
        case VERIFY_ACTIVITY:
            return {
                ...state,
                verifyActivity: action.payload
            }
        case SET_ACTIVITY_POPUP:
            return {
                ...state,
                popupToShow: action.payload
            }
        case ACKNOWLEDGE_ACTIVITY:
            return {
                ...state,
                acknowledgeActivity: action.payload
            }
        case GET_SINGLE_BADGE:
            return {
                ...state,
                badges: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload
            }
        case POST_BADGES:
            return {
                ...state,
                badges: action.payload
            }
        default:
            return state;
    }
}
export default popupReducer;