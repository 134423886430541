import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";
import { getEnabledPromotion } from "../../redux/promotions/action"
import FaceAvater from "../../assets/images/face-avater.png"

function Promotion(props) {
    // const promotion = useSelector(state => state.promotions.promotion);
    // const dispatch = useDispatch();
    // const [show, setShow] = useState(true);
    // const handleClose = () => {
    //     setShow(false);
    // }

// useEffect(() => {
    //     dispatch(getEnabledPromotion(props.type));
    // }, [])

    return (
        <>
            {/* {promotion?.length > 0 &&
            <Modal className="promotion-modal" show={show} onHide={handleClose} animation={true}  aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className="text-center" closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} className="d-flex align-items-center">
                            <div className="image">
                                <img src={FaceAvater} alt="" className="img-fluid" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="content">
                                <h2>{promotion?.length > 0 ? promotion[0].name : 'Promotion'}</h2>
                                {promotion?.length > 0 ? <span className='content-scroll' dangerouslySetInnerHTML={{ __html: promotion[0].description }} ></span> :
                                    <> <p>TPT Stakes average 40% interest a year.</p>
                                        <ul className="">
                                            <li>The first <Link to="/" >Blockchain</Link> Certificate of Deposit</li>
                                            <li>High interest, no minimum & decentralized design </li>
                                            <li>Over 200,000 wallets own TPT so far </li>
                                            <li>Richard Heart is making a new ETH fork with lower fees!</li>
                                        </ul></>
                                }
                                <div className="action-btn">
                                    <button className="btn btn-primary">Claim Now</button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>

            </Modal>
            } */}
        </>
    );
}

export default Promotion;