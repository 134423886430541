import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postChat, getChat } from '../../../../redux/chatbox/action';
import { getLeaderboard } from '../../../../redux/user/action';
import React from 'react';
import './chatBox.css';
import Pusher from 'pusher-js';
import useSound from 'use-sound';
import send from './audio/send.mp3';
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faMessage } from '@fortawesome/free-solid-svg-icons';
const moment = require('moment');
// Pusher.logToConsole = true;

function GroupChat() {
    let dispatch = useDispatch();
    let pusher = [];
    let channel = [];
    const [play] = useSound(send, { volume: 0.15 });
    const chats = useSelector(state => state?.chat?.chat);
    const chatLoader = useSelector(state => state?.chat?.chatLoader);
    let walletAddress = localStorage.getItem('WalletAddress');
    const [message, setMessage] = useState('');
    const [numberOfItems, setNumberOfItems] = useState(3);
    const [isActive, setIsActive] = useState(false);
    const [messageCount, setmessageCount] = useState(0);
    const [isJoin, setIsJoin] = useState(false);
    const [loader, setLoader] = useState(false);
    const [messageLoader, setMessageLoader] = useState(true);
    const [isInputActive, setInput] = useState(false);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    let [slice, setSlice] = useState([]);
    const [chatsRevres, setChatsRev] = useState([])
    const getuser = useSelector(state => state.userData?.profile);
    let users = useSelector(state => state.userData.user);
    let logedInuser = getuser?.name;
    let userwalletId = [];
    let chat = {
        owner: walletAddress,
    }

    function loadMoreItems() {
        if (numberOfItems >= chats?.data?.length) {
            setHasMoreItems(false)
        }
        setNumberOfItems(numberOfItems + 10)
    }

    useEffect(() => {
        let msgChunk = chatsRevres?.slice(0, numberOfItems)
        setSlice(msgChunk)
    }, [numberOfItems])
    useEffect(() => {
        let data = chats.data;
        let reverseChats = data?.reverse()
        let msgChunk = reverseChats?.slice(0, numberOfItems)
        setSlice(msgChunk)
        setChatsRev(reverseChats)
    }, [chats])

    const handleMessageSend = (e) => {
        e.preventDefault()
        let createdAt = new Date()
        createdAt = createdAt.toLocaleTimeString()
        const payload = {
            username: logedInuser ? logedInuser : walletAddress.slice(walletAddress.length - 4, walletAddress.length),
            message: message,
            createdAt: createdAt,
            eventId: "message",
            from: walletAddress
        };
        dispatch(postChat(payload));
        setMessageLoader(true)
        const messageClass = walletAddress !== chat.owner ? 'reply new-box' : 'user new-box-user'
        const loadingClass = messageLoader ? "spinner-border loadingMessage" : ""
        var ele = document.getElementsByClassName('chat-messages')
        ele[0].insertAdjacentHTML("afterbegin",
            `<li class="clearfix message ${messageClass}">
                <div class="sender">${logedInuser ? logedInuser : walletAddress.slice(walletAddress.length - 4, walletAddress.length)}</div>
                <div class="message">${message}</div>
                <div class="date">${createdAt}</div>
                <div class="${loadingClass}"></div>
            </li>`)
        ele[0].scrollTop = ele[0].scrollHeight;

        setMessage('')
        play();
    }
    useEffect(() => {
        if (chats) {
            const query = { from: '', to: '', loader: false }
            dispatch(getLeaderboard(query))
        }
    }, [chats])

    useEffect(() => {
        dispatch(getChat())
    }, [])

    useEffect(() => {

        if (users?.length > 0) {
            users?.forEach(currentUser => {
                userwalletId.push(currentUser._id)
            })
        }
    }, [users])

    useEffect(() => {

        if (pusher.length == 0) {
            pusher = new Pusher('27cbce362df659b8a71c', {
                cluster: 'ap2',
            });
            channel = pusher.subscribe("chat");
        }

        pusher.connection.bind("state_change", function (states) {
            if (states.current == 'connected') {
                let boxes = Array.prototype.slice.call(document.querySelectorAll('.loadingMessage'));
                boxes = boxes?.reverse()
                boxes?.forEach(box => {

                    let parent = box.parentNode
                    let childs = parent.childNodes
                    let sendBy = childs[1].innerHTML
                    let messageText = childs[3].innerHTML
                    let createdTime = childs[5].innerHTML

                    const reconnectPayload = {
                        username: sendBy,
                        message: messageText,
                        createdAt: createdTime,
                        eventId: "message",
                        from: walletAddress
                    };
                    dispatch(postChat(reconnectPayload));
                    box.remove();
                });
            }

        });

        channel.bind('message', function (data) {

            const messageClass = data.from !== chat.owner ? 'reply new-box' : 'user new-box-user'
            if (data.from !== chat.owner && data.eventId == 'message') {
                var ele = document.getElementsByClassName('chat-messages')
                ele[0].insertAdjacentHTML("afterbegin",
                    `<li class="clearfix message ${messageClass}">
                <div class="sender">${data.username}</div>
                <div class="message">${data.message}</div>
                <div class="date">${data.createdAt}</div>
            </li>`)
                ele[0].scrollTop = ele[0].scrollHeight;
                if (isActive == false) {
                    setmessageCount(count => count + 1);
                }
            }
            else {
                let boxes = Array.prototype.slice.call(document.querySelectorAll('.loadingMessage'));
                boxes[0]?.remove();
            }
            let boxes = Array.prototype.slice.call(document.querySelectorAll('.loadingMessage'));
            boxes = boxes?.reverse()
            boxes?.forEach(box => {

                let parent = box.parentNode
                let childs = parent.childNodes
                let sendBy = childs[1].innerHTML
                let messageText = childs[3].innerHTML
                let createdTime = childs[5].innerHTML

                const reconnectPayload = {
                    username: sendBy,
                    message: messageText,
                    createdAt: createdTime,
                    eventId: "message",
                    from: walletAddress
                };
                dispatch(postChat(reconnectPayload));
                box.remove();
            });
        });
        return () => {
            if (channel)
                channel.unbind()  //whenever the component removes it will executes
        }
    }, [])

    const ShowChatInitiationDisplay = () => {
        setIsJoin(current => !current);
        setLoader(current => !current);
        setTimeout(function () {
            setLoader(current => !current);
            setInput(current => !current);
        }, 2000)
    }

    const ToggleChatWindow = () => {
        setIsActive(current => !current);
        setmessageCount(0)
    }

    return (
        <>
            <div className={isActive ? 'opened chatbubble' : 'chatbubble'}>

                <div className="unexpanded" onClick={() => !isActive && ToggleChatWindow()} >
                    {messageCount === 0 ? null : <span className={isActive ? 'button__badge_empty' : 'button__badge'}>{messageCount}</span>}
                    <div className="title" onClick={() => isActive && ToggleChatWindow()} >{isActive ?
                        <FontAwesomeIcon icon={faClose} />
                        :
                        <FontAwesomeIcon icon={faMessage} />
                    }
                    </div>
                </div>
                <div className="expanded chat-window">
                    <div className={isJoin ? 'join-screen container' : 'join-screen active container'} onClick={ShowChatInitiationDisplay}>
                        <form id="joinScreenForm">
                            <button type="button" className="btn btn-block btn-primary">Join Chat</button>
                        </form>
                    </div>
                    <div className={isInputActive ? 'chats active' : 'chats'}>
                        <div className={loader ? 'loader-wrapper active' : 'loader-wrapper'}>
                            <div className="loader">
                                <span>!!</span><span>!!</span>
                            </div>
                        </div>
                        <ul id="scrollableDiv" className={isInputActive ? 'chat-messages clearfix active' : 'chat-messages clearfix'}>
                            <InfiniteScroll
                                dataLength={slice?.length == undefined ? 10 : slice?.length}
                                next={loadMoreItems}
                                style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                                inverse={true}
                                hasMore={hasMoreItems}
                                scrollableTarget="scrollableDiv"
                                endMessage={
                                    <b></b>
                                }
                            >
                                {slice ? slice.map((msg) => {
                                    let index = "";
                                    if (userwalletId?.includes(msg.username)) {
                                        index = userwalletId?.indexOf(msg.username)
                                    }
                                    return (
                                        <li className={msg.from == chat.owner ? 'clearfix message user' : 'clearfix message reply'}>
                                            <div class="sender">{index ? users[index]?.name : msg?.username}</div>
                                            <div class="message">{msg.message}</div>
                                            <div class="date">{moment(msg.createdAt).format('h:mm:ss A')}</div>
                                        </li>
                                    )
                                    index = "";
                                }) : null}
                            </InfiniteScroll>
                        </ul>
                        <div className={isInputActive ? 'input active' : 'input'}>
                            <form className="form-inline" id="messageOthers">
                                <div className="form-group">
                                    <input type="text" autocomplete="off" className="form-control" value={message} id="newMessage" placeholder="Enter Message" onChange={e => {
                                        if (e.target.value && e.target.value.trim() !== "") {
                                            setMessage(e.target.value)
                                        }
                                        else {
                                            setMessage("")
                                        }
                                    }
                                    } />
                                </div>
                                <button type="submit" className="btn btn-primary" onClick={handleMessageSend} disabled={message ? false : true}>Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupChat