import { toast } from "react-toastify";
import { SET_LOADER, GET_REQUESTS  } from "../actionTypes";
import { apiHelper } from '../apiHelper';

export const getUserReqeusts = (address, status) => async (dispatch) => {
    try{
        dispatch({
            type: SET_LOADER,
            payload: true
        });
        const res = await apiHelper("get", `/v1/request/list/${address}?status=${status}`, "","");
        if (res) {
            dispatch({
                type: SET_LOADER,
                payload: false
            });
            if (res?.data) {
                let { data } = res;

                dispatch({
                    type: GET_REQUESTS,
                    payload: data
                })
            }
        }
    }catch(error){
        toast.error(error.message);
    }
}