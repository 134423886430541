export const REGISTER_USER = 'REGISTER_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const GET_LEADERBOARD = 'GET_LEADERBOARD'
export const GET_NFTS = "GET_NFTS"
export const GET_USERNFTS = "GET_USERNFTS"
export const UPDATE_USER = "UPDATE_USER"
export const SET_REFERRALS = "SET_REFERRALS"
export const SET_EVENT = "SET_EVENT"
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
export const SET_UNSEENEVENTS = "SET_UNSEENEVENTS"
export const POST_READ = 'POST_READ'
export const SET_UNREAD_NOTIFICATIONS = 'SET_UNREAD_NOTIFICATIONS'
export const SHOW_NOTIFICATION_POPUP = 'SHOW_NOTIFICATION_POPUP'
export const SHOW_ERR_POPUP = 'SHOW_ERR_POPUP'


export const GET_ACTIVITIES = "GET_ACTIVITIES"
export const ALL_ACTIVITIES = "ALL_ACTIVITIES"
export const GET_OFFERS = "GET_OFFERS"
export const GET_COLLECTIONS = "GET_COLLECTIONS"
export const GET_COLLECTIBLES = "GET_COLLECTIBLES"
export const GET_ABOUT = "GET_ABOUT"
export const REGISTER = "REGISTER"

export const GET_STATUS = 'GET_STATUS'
export const EDIT_STATUS = 'EDIT_STATUS';
export const SET_EVENT_HISTORY = 'SET_EVENT_HISTORY';

export const POINTS_DATA = 'POINTS_DATA';

// GIFT CARD TYPE
export const GET_GIFTCARD = 'GET_GIFTCARD';
export const POST_GIFTCARD = 'POST_GIFTCARD';
export const SINGLE_GIFTCARD = 'SINGLE_GIFTCARD';
export const UPDATE_GIFTCARD = 'UPDATE_GIFTCARD';
export const DELETE_GIFTCARD = 'DELETE_GIFTCARD';

// LOADER types
export const SET_LOADER = 'SET_LOADER';

// notification types

export const GET_NOTES = 'GET_NOTES'


//TASK AND REWARDS

export const GET_TASKS = 'GET_TASKS'
export const GET_SINGLE_TASKS = 'GET_SINGLE_TASKS'
export const GET_REWARDS_HISTORY = 'GET_REWARDS_HISTORY'
export const GET_PROGRESS_LEVELS = 'GET_PROGRESS_LEVELS'




// BADGES

export const GET_BADGES = 'GET_BADGES'
export const POST_BADGES = 'POST_BADGES'
export const GET_SINGLE_BADGE = 'GET_SINGLE_BADGE'

// contest videos 

export const POST_VIDEOS = "POST_VIDEOS"

// levels
export const GET_LEVELS = 'GET_LEVELS'


export const GET_STATS = 'GET_STATS'
export const PROF_COMPLETENESS = 'PROF_COMPLETENESS'


// get contest list

export const GET_CONTEST = 'GET_CONTEST'
export const GET_CONTEST_DETAILS = 'GET_CONTEST_DETAILS'

// treasure box 

export const POST_CONTENT = 'POST_CONTENT'
export const POST_QUIZ = 'POST_QUIZ'
export const GET_CATAGORIES = 'GET_CATAGORIES'
export const GET_TAGS = 'GET_TAGS'
export const RECENT_IMG_UPLOAD = 'RECENT_IMG_UPLOAD'
export const SET_POST = 'SET_POST'



export const GET_PLATFORM = 'GET_PLATFORM'




// update profile image

export const UPDATE_PROFILE = 'UPDATE_PROFILE'

// update gamification profile

export const UPDATE_GAMEPROFILE = 'UPDATE_GAMEPROFILE'
export const POST_UPDATED = 'POST_UPDATED'



// events creating of task (rough work)

export const POST_EVENT = 'POST_EVENT'

export const GET_ENABLED_PROMOTION = 'GET_ENABLED_PROMOTION'
export const GET_PROMOTION = 'GET_PROMOTION'


export const GET_TREASUREBOXES = 'GET_TREASUREBOXES'
export const GET_TREASUREBOXES_COUNT = 'GET_TREASUREBOXES_COUNT'

export const UPDATE_TREASUREBOXVIEWS = 'UPDATE_TREASUREBOXVIEWS'
export const ANSWERS_UPDATED = 'ANSWERS_UPDATED'
export const SET_COMMENTS = 'SET_COMMENTS'
export const SET_TRENDING_POSTS = 'SET_TRENDING_POSTS'
export const CLEAR_TREASUREBOX_DATA = 'CLEAR_TREASUREBOX_DATA'

/// chatBox ///
export const POST_CHAT = 'POST_CHAT'
export const UPLOAD_VIDEO = 'UPLOAD_VIDEO'
export const GET_CHAT = 'GET_CHAT'
export const GET_SINGLE_CHAT = 'GET_SINGLE_CHAT'
export const REMOVE_SINGLE_CHAT = 'REMOVE_SINGLE_CHAT'
export const CHAT_LOADER = 'CHAT_LOADER'
export const GET_CHAT_NOTIFICATION = 'GET_CHAT_NOTIFICATION'
export const READ_NOTIFICATION = 'READ_NOTIFICATION'
export const INITIAL_CHAT = 'INITIAL_CHAT'
export const SET_NFTLOADER = 'SET_NFTLOADER'
export const NFT_CREATED = 'NFT_CREATED'
export const NFT_SOLD_LOADER = 'NFT_SOLD_LOADER'
export const NFT_URL = 'NFT_URL'

// WalletProvider
export const WALLET_PROVIDER = "WALLET_PROVIDER"
export const CURRENT_USER = "CURRENT_USER"
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER"
export const WALLET_LOADER = "WALLET_LOADER"


export const GET_POPUPS = "GET_POPUPS"
export const VERIFY_ACTIVITY = "VERIFY_ACTIVITY"
export const ACKNOWLEDGE_ACTIVITY = "ACKNOWLEDGE_ACTIVITY"
export const SET_ACTIVITY_POPUP = "SET_ACTIVITY_POPUP"

// Requests
export const GET_REQUESTS = "GET_REQUESTS"

