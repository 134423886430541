// import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { SkeletonLayout } from "react-skeleton-loader-pulse";
function SkeltonLoader(props) {
    const [skelton, setskelton] = useState([]);
    useEffect(() => {
        if (props.skeletonLength > skelton.length) {
            let newskeletons = props.skeletonLength - skelton.length;
            for (let i = 1; i <= newskeletons; i++) {
                skelton.push({
                    height: props.height,
                    marginBottom: '1px',
                    marginTop: 0,
                    width: props.width,
                    borderRadius: props.radious,
                })
            }
        }
        else if (props.skeletonLength < skelton.length) {
            let newskeletons = skelton.length - props.skeletonLength;
            for (let i = 1; i <= newskeletons; i++) {
                skelton.pop({
                    height: props.height,
                    marginBottom: '1px',
                    marginTop: 0,
                    width: props.width,
                    borderRadius: props.radious,
                })
            }
        }
        else {
            if (skelton.length == 0) {
                skelton.push({
                    height: props.height,
                    marginBottom: '1px',
                    marginTop: 0,
                    width: props.width,
                    borderRadius: props.radious,
                })
            }
        }
    }, [props.skeletonLength])
    return (
        <React.Fragment>
            {/* {loaderActive ? */}
            <SkeletonLayout
                align="center"
                color="#000000"
                items={skelton}
            />
        </React.Fragment>
    );
};

export default SkeltonLoader;