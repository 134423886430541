import { GET_PROGRESS_LEVELS, GET_TASKS, GET_LEVELS, GET_SINGLE_TASKS, GET_PLATFORM, SET_LOADER } from '../actionTypes';

const initialState = {
    // singleTask: {}
    loaderActive: true,
}

const getTaskAndRewards = (state = initialState, action) => {
    switch (action.type) {

        case GET_TASKS:
            return {
                ...state,
                task: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loaderActive: action.payload
            }
        case GET_LEVELS:
            return {
                ...state,
                levels: action.payload
            }
        case GET_SINGLE_TASKS:
            return {
                ...state,
                singleTask: action.payload
            }
        case GET_PLATFORM:
            return {
                ...state,
                platforms: action.payload
            }
        case GET_PROGRESS_LEVELS:
            return {
                ...state,
                progressLevels: action.payload
            }

        default:
            return state
    }
}

export default getTaskAndRewards;