const metamaskEventDetector = async () => {
    // if (window.ethereum) {
    //     // window.ethereum.on('chainChanged', () => {
    //     //     window.location.reload();
    //     // })
    //     window.ethereum.on('accountsChanged', (accounts) => {
    //         localStorage.removeItem('WalletAddress');
    //         props.history.push("/")
    //     })
    //     window.ethereum.on('disconnect', () => {
    //         // this.setState({ metamaskHasDisconnected: true })
    //     })
    // }
}

const getAccount = async () => await window.ethereum.request({ method: 'eth_accounts' })[0] || false;
const getQueryParam = (location,name) => {
    let searchParams = new URLSearchParams(location.search)
    let queryParam = searchParams.get(name)
    return queryParam
}

const countDownRenderer =  ({ days, hours, minutes, seconds }) => {
    return (
        <div className="countdown-container d-flex" style={{ justifyContent: "space-between", width: "100%", textAlign: "center" }}>
            <div className="countdown-wrapper">
                <div>Days</div>
                <div>{days}</div>
            </div>
            <div className="countdown-wrapper">
                <div>Hours</div>
                <div>{hours}</div>
            </div>
            <div className="countdown-wrapper">
                <div>Minutes</div>
                <div>{minutes}</div>
            </div>
            <div className="countdown-wrapper">
                <div>Seconds</div>
                <div>{seconds}</div>
            </div>
        </div>
    )
}

const countDownDailyReward =  ({hours, minutes, seconds }) => {
    return (
        <div className="daily-countdown countdown-container d-flex" style={{ justifyContent: "space-between", width: "100%", textAlign: "center" }}>
            {/* <div className="countdown-wrapper">
                <div>Days</div>
                <div>{days}</div>
            </div> */}
            <div className="countdown-wrapper">
                <div className="time-num">{hours<10?"0"+hours:hours}</div>
                <div className="daily">H</div>
            </div>
            <div className="mx-2">
                :
            </div>
            <div className="countdown-wrapper">
                <div>{minutes<10?"0"+minutes:minutes}</div>
                <div className="daily">M</div>
            </div>
            <div className="mx-2">
                :
            </div>
            <div className="countdown-wrapper">
                <div>{seconds<10?'0'+seconds:seconds}</div>
                
                <div className="daily">S</div>
            </div>
        </div>
    )
}
const countDownMonthlyReward =  ({ days, hours}) => {
    return (
        <div className="monthly-countdown countdown-container d-flex" style={{ justifyContent: "space-between", width: "100%", textAlign: "center" }}>
            <div className="countdown-wrapper">
                <div className="time-num">{days}</div>
                <div className="daily">Days</div>
            </div>
            <div>
                :
            </div>
            <div className="countdown-wrapper">
                <div className="time-num">{hours<10?"0"+hours:hours}</div>
                <div className="daily">HRS</div>
            </div>
        </div>
    )
}

export { metamaskEventDetector, getAccount, getQueryParam,  countDownRenderer, countDownDailyReward, countDownMonthlyReward}

