import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import SunRays from '../../assets/lottie/rays.json';
import Congrats from '../../assets/lottie/congrats.json';
import { useDispatch, useSelector } from "react-redux";
import { clearNftData } from "../../redux/user/action"
function CongratesModal(props) {
    let dispatch = useDispatch()
    // const [show, setShow] = useState(true)

    const handleClose = () => {
        dispatch(clearNftData())
        props.setNftPopup(false)
    }
    const congrats = {
        loop: true,
        autoplay: true,
        animationData: Congrats,
    };
    const sunrays = {
        loop: true,
        autoplay: true,
        animationData: SunRays,
    };
    return (
        <>
            <Modal conHide={handleClose} show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="activity-popup">
                {/* <Button className="close-btn" onClick={handleClose}>
                    <FontAwesomeIcon icon={faClose} name="searchbtn" />
                </Button> */}
                <div className='popup-lotties position-relative'>
                    <div className="rewards-anime">
                        <Lottie className="lottie" options={congrats} />
                    </div>
                    <div className="lottie lottie-1">
                        <Lottie className="lottie" options={sunrays} />
                    </div>
                </div>
                <div className='popup-content'>
                    <h2>Congratulations</h2>
                    <p>Nft Created Successfully!</p>
                    <a href="" target='_blank' className='popup-btn mb-3' onClick={handleClose}>
                        <span>View NFT</span>
                    </a>
                    <Button className='popup-btn-close' onClick={handleClose}>Skip</Button>
                </div>
            </Modal>
        </>
        // <>
        //     <Modal onHide={handleClose} show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="success-popup congrats-modal">
        //         <Button className="close-btn" onClick={handleClose}>
        //             <FontAwesomeIcon icon={faClose} name="searchbtn" />
        //         </Button>
        //         <Modal.Body>
        //             <h2></h2>
        //             <p>Nft Created Successfully!</p>
        //             <a href={props?.nftLink} target='_blank' class="btn-gradient-blue" onClick={() => handleClose()}>View Nft</a>
        //         </Modal.Body>
        //     </Modal>
        // </>
    )
}

export default CongratesModal