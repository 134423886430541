import React, { useState, useRef, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import avatar from "../../assets/images/avatar-dummy.png"
import points from "../../assets/images/points.png"
import badges from "../../assets/images/badges.png"
import { showNotePopup, getSingleEvent } from "../../redux/user/action"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import SocialShare from "../socialShare/socialShare";
import { multipart } from "../../redux/treasureBox/action"
import { ENV } from "../../config/config"
import * as htmlToImage from 'html-to-image';
import { getQueryParam } from '../../config/helper'
import GamificationLoader from "../../components/gamificationLoader/GamificationLoader"
import { SkeletonLayout } from 'react-skeleton-loader-pulse';
import Lottie from 'react-lottie';
import SunRays from '../../assets/lottie/rays.json';
import LogoPopup from '../../assets/images/logo-popup.png';
import PopupImage from "../../assets/images/coming-soon-popup.png"

const REACT_APP_SERVER_URL = ENV.serverUrl;

function MyVerticallyCenteredModal(props) {
    const dispatch = useDispatch();
    const modalRef = useRef(null);
    const [image, setImage] = useState();
    const [shareLoader, setShareLoader] = useState(true);
    const [eventLoader, setEventLoader] = useState(true);
    let history = useHistory()
    let location = useLocation();
    let eventId = getQueryParam(location, 'eventId')
    let comingSoon = getQueryParam(location, 'comingSoon')
    let event = useSelector(state => state?.userData?.singleEvent)
    var formData = new FormData();
    useEffect(() => {
        if(eventId && eventId!==undefined){
            dispatch(getSingleEvent(eventId));
        }
    }, [eventId])
    useEffect(() => {
        setEventLoader(true)
        if (event) {
            onCapture()
        }
        setEventLoader(false)
    }, [event])
    useEffect(()=>{
        if(comingSoon){
        setEventLoader(true)
        if (comingSoon) {
            setTimeout(() => {
                onCapture()
            }, 2000);
        }
        setEventLoader(false)
        }
    },[comingSoon])

    const onCapture = async () => {
        setShareLoader(true)
        let node = document.getElementsByClassName('sharing-popup-capture');
        node = node[0];
        const { width, height} = node.getBoundingClientRect();
        await htmlToImage.toPng(node, comingSoon?{ height:height*1.7,width,scrollX: 0, scrollY: 0}:{ width,height,scrollX: 0, scrollY: 0, backgroundColor: '#000' })
            .then(async function (dataUrl) {
                formData.append("file", dataUrl)
                formData.append("type", 'base64')
                let resImg = await dispatch(multipart(formData))
                setImage(resImg?.data?.ressss?.secure_url?.split("upload/")[0] + `upload/b_black,c_pad,h_500,w_800/` + resImg?.data?.ressss?.secure_url?.split("upload/")[1])
            });
        setShareLoader(false)
        dispatch(showNotePopup(false))

    }
    const goBack = () => {
        history.goBack();
    }
    const sunrays = {
        loop: true,
        autoplay: true,
        animationData: SunRays,
    };
    return (
        <div className="custom-padding mb-3">
            {shareLoader || eventLoader ? <GamificationLoader /> : null}
            <div className="social-share" id={'success-popup'}>
                {!comingSoon && eventId && event?
                    <div className={'sharing-popup sharing-popup-capture'}>
                    {event?.points > 0 ?
                        <h2 className="text-center px-xl-5 mb-xl-4 mb-3">You got <strong>{event?.points} Points</strong> for performing {ENV.rearrangeEventType(event?.eventType)} task <strong>on  {event?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(event?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(event?.platform)}</strong>.</h2>
                        : event?.points === 0 ?
                            <h2 className="text-center px-xl-5 mb-xl-4 mb-3">You performed {ENV.rearrangeEventType(event?.eventType)} task <strong>on {event?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(event?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(event?.platform)}</strong>.</h2> :
                            <h2 className="text-center px-xl-5 mb-xl-4 mb-3">Your <strong>{event?.points * -1} points</strong> deducted for performing {ENV.rearrangeEventType(event?.eventType)} task <strong>on {event?.platform.toLowerCase() == 'nft' ? ENV.capitalizeFirstLetter(event?.platform) + ' Marketplace' : ENV.capitalizeFirstLetter(event?.platform)}</strong>. </h2>
                    }
                    <ul className="d-flex justify-content-center text-center align-items-center pb-5">
                        {eventLoader ?
                            <SkeletonLayout
                                align="center"
                                color="#000000"
                                items={[{
                                    height: 310,
                                    marginBottom: '1px',
                                    marginTop: 0,
                                    width: '100%',
                                    borderRadius: '5',
                                }]
                                }
                            /> :
                            <>
                                {event?.avatar ?
                                    <li className="welcome-avatar">
                                        <div className="image">
                                            <img src={`${REACT_APP_SERVER_URL}/media/${event?.avatar}`} alt="" className="img-fluid" />
                                        </div>
                                        <span>{event?.avatar.substring(0, event?.avatar.indexOf('.')).replaceAll('-', ' ')}</span>
                                    </li>
                                    : ""}

                                {event?.badge ? <li className="welcome-badges">
                                    <div className="image">
                                        <img src={`${REACT_APP_SERVER_URL}/media/${event?.badge}`} alt="" className="img-fluid" />
                                    </div>
                                    <span>{event?.badge.substring(0, event?.badge.indexOf('.')).replaceAll('-', ' ')}</span>
                                </li> : ""}
                                {/* {event?.points > 0 ?
                                    <li className="welcome-points">
                                        <div className="image">
                                            <img src={points} alt="" className="img-fluid" />
                                        </div>
                                        <span>+{event?.points} Points</span>
                                    </li>
                                    : ''} */}

                                {event?.points != 0 ?
                                    <li className="welcome-points">
                                        <div className="image">
                                            <img src={points} alt="" className="img-fluid" />
                                        </div>
                                        <span>{
                                            event?.points > 0 ? `+${event?.points}` : event?.points} Points</span>
                                    </li>
                                    : ""}
                            </>
                        }
                    </ul>
                    <div className="points-detail-row d-sm-flex justify-content-between">
                        <div className="d-flex me-2 justify-content-center flex-wrap">
                            <span className="me-2">Platform:</span>
                            <strong>{event?.platform == 'nft' ? event?.platform + ' Marketplace' : event?.platform} </strong>
                        </div>
                        <div className="d-flex ms-2 justify-content-center flex-wrap">
                            <span className="me-2">Event Type:</span>
                            <strong>{ENV.rearrangeEventType(event?.eventType)}</strong>
                        </div>
                    </div>
                </div>:comingSoon ? 
                <div className="sharing-popup-capture" >
                    <div className="activity-popup activity-popup-page">
                    <div className='popup-lotties position-relative'>
                        <div className="popup-logo">
                            <img src={LogoPopup} alt="logo" className="img-fluid"/>
                        </div>
                        <div className="lottie lottie-1">
                            <Lottie className="lottie" options={sunrays} />
                        </div>
                    </div>
                    <div className='popup-content'>
                        <h2>Coming Soon</h2>
                        <p>Prepare to be captivated by an unforgettable journey coming soon.</p>
                    </div>
                </div>
                </div>
                
                :
                ""
                }
                <div className="social-sharing mt-xl-5 mt-4 mb-3 justify-content-center">
                    <SocialShare imageUrl={image} postCaption={'Gamification'} media_type={'image'} />
                </div>
                <div className="text-center">
                    {location.pathname == '/shareContent' ?
                        <button className='main-btn-style' onClick={() => { goBack() }}>
                            Go Back
                        </button> : ''
                    }
                </div>
                
            </div>
        </div>
    );
}

function SuccessPop(props) {


    return (
        <>
            <MyVerticallyCenteredModal
                show={true}
                onHide={() => props.setModalShow(false)}
            />
        </>
    );
}

export default SuccessPop;