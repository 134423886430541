import { apiHelper, apiHelperLive } from "../apiHelper";
import { INITIAL_CHAT, READ_NOTIFICATION, GET_CHAT_NOTIFICATION, CHAT_LOADER, POST_CHAT, GET_CHAT, GET_SINGLE_CHAT, REMOVE_SINGLE_CHAT } from "../actionTypes";

import { ENV } from "./../../config/config";

let token = localStorage.getItem("token")
let addressForLiveApi = "0x5241b6b1082080C7f103d3C5CA5EAe55eCEA3293"
let myDummyAddress = "0x0297209262AA172478b4B5A54b46A7d1cC77a80B"
let encToken = ENV.encryptToken(token)

export const postChat = (chat) => async (dispatch) => {
    try {
        let enc = ENV.encryptPayload(chat)

        let data = { enc: enc }
        let res = await apiHelper("post", `/v1/chat/message`, data, encToken);
        if (res?.data) {
            dispatch({
                type: POST_CHAT,
                payload: res.data
            });
        }

    } catch (error) {
        console.log(error.message);
    }
}


export const getChat = () => async (dispatch) => {
    try {
        dispatch({
            type: CHAT_LOADER,
            payload: true
        });
        token = ENV.encryptToken(localStorage.getItem("token"))

        let res = await apiHelper("get", `/v1/chat`, "", encToken);
        if (res?.data) {
            dispatch({
                type: CHAT_LOADER,
                payload: false
            });
            dispatch({
                type: GET_CHAT,
                payload: res.data
            });
        }

    } catch (error) {
        console.log(error.message);
    }
}

export const singleChat = (userData) => async (dispatch) => {
    try {
        let enc = ENV.encryptPayload(userData)

        let data = { enc: enc }
        let res = await apiHelper("post", `/v1/chat/event/messages`, data, encToken);
        if (res?.data) {
            dispatch({
                type: GET_SINGLE_CHAT,
                payload: res.data
            });
        }

    } catch (error) {
        dispatch({
            type: REMOVE_SINGLE_CHAT,
            payload: []
        });
    }
}

export const initialChat = () => async (dispatch) => {

    try {
        dispatch({
            type: INITIAL_CHAT,
            payload: false,
        });

    } catch (error) {
        console.log(error.message);
    }
}

export const getMessageNotification = (userData) => async (dispatch) => {
    try {
        let enc = ENV.encryptPayload(userData)

        let data = { enc: enc }
        let res = await apiHelper("post", `/v1/chat/notifications`, data, encToken);
        if (res?.data) {
            dispatch({
                type: GET_CHAT_NOTIFICATION,
                payload: res.data
            });
        }

    } catch (error) {
        console.log(error.message);
    }
}

export const readNotification = (user_id) => async (dispatch) => {
    try {
        let enc = ENV.encryptPayload(user_id)

        let data = { enc: enc }
        let res = await apiHelper("post", `/v1/chat/readNotification`, data, encToken);
        if (res?.data) {
            dispatch({
                type: READ_NOTIFICATION,
                payload: res.data
            });
        }

    } catch (error) {
        console.log(error.message);
    }
}


