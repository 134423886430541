import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/combineReducers'
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// import { BrowserRouter } from 'react-router-dom';


// window.APIURL = "http://localhost:3000/";
// window.APPURL = "http://localhost:3001/";



ReactDOM.render(
  <Provider store={store}>
    <ToastContainer limit={1} />
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </Provider>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
